// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // domain: "http://localhost:3000",
  // apiURL: "http://localhost:3000/api",
  // domain: "http://139.59.59.5:3000",
  // apiURL: "http://139.59.59.5:3000/api",
  domain: "https://construction.heliware.co.in:3000",
  apiURL: "https://construction.heliware.co.in:3000/api",
  // apiURL: "http://localhost:8000/api",
  procApiUrl: "http://167.71.234.152",
  // aiApiUrlOld: "http://localhost:8000/api",
  aiApiUrlOld: "http://127.0.0.1:8000/api",
  navApiUrl: "https://nav.heliware.co.in",
  
  aiApiUrl: "http://127.0.0.1:8000/api",
  // isochrone_key: 'gftryetrfvdecgtr',
  // procApiUrl: "http://localhost:5000",

  bulkUrl: "http://127.0.0.1:8000/api",
  logoURL: "http://localhost:4200/assets/logo.png",
  appDomain: "https://construction.heliware.co.in",
  pvWattAPI_key: "UclBrhIYWGzaboLDnjorg8IwKCmaAFcYzKf2yltj",
  heligeo_key: "jhyterfvdrwesuyt"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
