import { Injectable } from '@angular/core';
import { Socket } from 'ngx-socket-io';
import { environment } from 'src/environments/environment';

@Injectable()
export class SocketChat extends Socket {

    constructor() {
        super({ url: environment.domain+'/dashboard/notification', options: {} });
    }
}
