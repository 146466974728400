import { Injectable } from '@angular/core';
import { modelConfig } from '../models/model-config.interface';
import { Subject } from 'rxjs';
import { ServerService } from '../server.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../auth/auth.service';
import { SocketModelConfig } from '../socket-services/socket-model-config';

@Injectable({
  providedIn: 'root'
})
export class ModelConfigService {
  modelConfigProjects: Array<{id: string, item: string, siteid: string, creatorid: string}>;
  modelConfigProjectsChanged: Subject<Array<{id: string, item: string, siteid: string, creatorid: string}>> = new Subject();
  modelConfigProjectUpdated: Subject<modelConfig> = new Subject();
  pseudoModelConfigProjectUpdated: Subject<modelConfig> = new Subject();

  constructor(private serverService: ServerService, private snackbar: MatSnackBar, private server: ServerService, private auth: AuthService, private socket: SocketModelConfig) { }

  getModelConfigProject(id: string){
    let modelConfigProject = this.modelConfigProjects.find((val)=>{
      return val.id === id
    })
    return modelConfigProject
  }

  populateModelConfigProjects(siteid: string){
    this.serverService.getModelConfigProjects(siteid).subscribe(data=>{
      this.modelConfigProjects = data
      this.modelConfigProjectsChanged.next(this.modelConfigProjects)
    },err=>{
    })
  }

  createModelConfigProject(modelConfig: modelConfig){
    this.serverService.createModelConfigProject(modelConfig).subscribe(res=>{
      modelConfig.id = res.id
      this.modelConfigProjects.push(modelConfig)
      this.modelConfigProjectsChanged.next(this.modelConfigProjects)
    },err=>{
    })
  }


  fetchModelConfigProject(id){
    this.serverService.fetchModelConfigProject(id).subscribe(res=>{
      this.modelConfigProjectUpdated.next(res.project);
    },err=>{
      this.snackbar.open('Cannot fetch', 'OK',{
        duration: 3000
      })
    })
  }

  pseudofetchModelConfigProject(id){
    this.serverService.fetchModelConfigProject(id).subscribe(res=>{
      this.pseudoModelConfigProjectUpdated.next(res.project);
    },err=>{
      this.snackbar.open('Cannot fetch', 'OK',{
        duration: 3000
      })
    })
  }

  shareProject(data: {id: string, userid: string, accessControl: 'canEdit'| 'canView', message: string}){
    this.serverService.shareModelConfigProject(data).subscribe(res=>{
      this.snackbar.open('Successfully Shared', 'ok', {
        duration: 3000
      });
      this.pseudofetchModelConfigProject(data.id);
    },error=>{
      this.snackbar.open('Something went wrong', 'ok', {
        duration: 3000
      });
    })
  }

  unShareProject(data: {id: string, userid: string, accessControl: 'canEdit'| 'canView'}){
    this.serverService.unShareModelConfigProject(data).subscribe(res=>{
      this.snackbar.open('Successfully UnShared', 'ok', {
        duration: 3000
      });
      this.pseudofetchModelConfigProject(data.id);
    },error=>{
      this.snackbar.open('Something went wrong', 'ok', {
        duration: 3000
      });
    })
  }

  uploadTexture(formData: FormData){
    return this.serverService.uploadTexture(formData)
  }

  uploadIMG(data: FormData){
    return this.server.uploadIMG(data)
  }

  deleteTexture(key: string, id: string){
    return this.serverService.deleteTexture(id, key);
  }

  joinRoom(id: string){
    this.socket.emit('join', {
      token: this.auth.getToken(),
      id
    });
  }

  addTexture(texture: {index: number, name?: string, type: 'image'|'color', texture: string}){
    this.socket.emit('add texture', texture);
  }

  listenAddtexture(){
    return this.socket.fromEvent<{index: number, name?: string, type: 'image'|'color', texture: string}>('add texture');
  }
}
