// import {  OnInit } from '@angular/core';
import { Component, Inject } from '@angular/core';
// import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog} from '@angular/material/dialog';
import { MatSnackBar } from "@angular/material/snack-bar";
import { SitesService } from '../sites/sites.service';

@Component({
  selector: 'app-confirm-dialog',
  template: `
    <h2 mat-dialog-title>Confirm</h2>
    <mat-dialog-content>{{ data.message }}</mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button (click)="onCancelClick()">Cancel</button>
      <button mat-button (click)="onOkClick()">OK</button>
    </mat-dialog-actions>
  `,
  
})
export class ConfirmDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, public dialog: MatDialog,  private snackBar: MatSnackBar, private sitesService: SitesService
    
  ) {}

  onCancelClick(): void {
    this.dialogRef.close('cancel');
    this.snackBar.open('Cancel Successfully!', 'OK', {
      // duration: 3000,
      verticalPosition: 'top',
      horizontalPosition: 'center',
    });
  }
  onOkClick(): void {
    this.dialogRef.close('ok');
    this.snackBar.open('User Delete Successfully!', 'OK', {
      duration: 3000, 
      verticalPosition: 'top',
      horizontalPosition: 'center',
    });
    

  }
}

