<div class="top">
  <mat-toolbar style="display: flex; background-color: #03a9f4; justify-content: space-between; position: fixed; z-index: 10000;">
    <a href="https://heliware.co.in" style="margin-top: 8px;">
      <img src="assets/heliImg/final big transparent.png" style="width: 240px;" alt="Heliware">
    </a>
    <div class="links" style="cursor: pointer;">
    <a style="color: white;" href="https://heliware.co.in">Home</a>
    <a style="color: white;" href="#">Report</a>
    <a style="color: white;" href="https://heliware.co.in/app">Login</a>
    </div>
  </mat-toolbar>
  <div id="gmap" #gmap style="width: 100%;height: 100%; top: 63px; position: absolute;"></div>
</div>
<mat-spinner *ngIf="fetching" style="width: 70%;height:70%;z-index: 10000;position: absolute;top: 40%;left: 45%"></mat-spinner>

<mat-sidenav-container class="example-container">
  <mat-sidenav #sidenav [mode]="(isHandset$ | async) ? 'over' : 'side'" position="end">

    <br>
    <p style="margin-left: 10px;font-weight: 700;">Note</p>
    <p style="margin-left: 10px;">{{reportData.note}}</p>
    <hr>
        <div style="width: 300px;" *ngFor="let item of reportData.analysisLayers;let k=index">

          <div style="display: flex;">
            <div style="margin-left: 20px;width: 200px;">
              <p ><strong>{{ item.name }}</strong> </p>
              <!-- <p *ngIf="createMapMode == 'Multiple Variant'"><strong>{{ legendAttribute1 }} - {{legendAttribute2 }}</strong> </p> -->
            </div>
            <!-- <div style="width: 80px;margin-top: 10px;">
              <mat-checkbox (change)="DisplayData($event)"  value="item">Data</mat-checkbox> 

            </div> -->
          </div>


          <!-- <div style="margin-left: 20px;margin-top: 15px;margin-bottom: 15px;">          
            <div style="display: flex;">
              <div style="background-color: #2c7fb8;width: 35px;height: 35px;" ></div><span style="padding-left: 15px;font-size: 12px;margin-top: 8px;">{{item.max}}   -  {{item.max-(item.max-item.min)/5}}</span>
            </div>
            <div style="display: flex;">
              <div style="background-color: #636363;width: 35px;height: 35px;" class="Square"></div><span style="padding-left: 15px;font-size: 12px;margin-top: 8px;">{{item.max-(item.max-item.min)/5}}   -   {{item.max-2*(item.max-item.min)/5}}</span>
            </div>         
            <div style="display: flex;">
              <div style="background-color:#2ca25f;width: 35px;height: 35px;" class="Square"></div><span style="padding-left: 15px;font-size: 12px;margin-top: 8px;">{{item.max-2*(item.max-item.min)/5}}   -  {{item.max-3*(item.max-item.min)/5}}</span>
            </div>
            <div style="display: flex;">
              <div style="background-color: #dd1c77;width: 35px;height: 35px;" class="Square"></div><span style="padding-left: 15px;font-size: 12px;margin-top: 8px;">{{item.max-3*(item.max-item.min)/5}}  -  {{item.max-4*(item.max-item.min)/5}}</span>
            </div>
            <div style="display: flex;">
              <div style="background-color: #edf8b1;width: 35px;height: 35px;" class="Square"></div><span style="padding-left: 15px;font-size: 12px;margin-top: 8px;">{{item.max-4*(item.max-item.min)/5}}   -   {{item.min}}</span>
            </div>
          </div> -->






          <!-- <div #piechart id="pie" style="width: 250px;height: 200px;margin-left: 20px;"></div> -->
          <br>
          <div style="margin-left: 20px;">
            <p><strong>Total</strong> {{item.data?.length}} {{k}} </p>

            <p ><strong>Maximun {{ item.column }}</strong> {{item.max}} </p>
            <!-- <p *ngIf="createMapMode == 'Multiple Variant'"><strong>Maximun {{ legendAttribute1 }}</strong> {{item.max1}} </p>
            <p *ngIf="createMapMode == 'Multiple Variant'"><strong>Maximun {{ legendAttribute2}}</strong> {{item.max2}} </p> -->
  
            <p><strong>Minimun {{ item.column }}</strong> {{item.min}} </p>
            <!-- <p *ngIf="createMapMode == 'Multiple Variant'"><strong>Minimun {{ legendAttribute1 }}</strong> {{item.min1}} </p>
            <p *ngIf="createMapMode == 'Multiple Variant'"><strong>Minimun {{ legendAttribute2 }}</strong> {{item.min2}} </p> -->
  
            <!-- <div *ngIf="critriaValuePredicted">
              <p *ngFor="let criteria of criteriaData;let i = index"><strong>{{ criteria.name }}</strong> {{ critriaValuePredicted[i] }} </p>
            </div> -->
          </div>

          <plotly-plot id="chart" [data]="[pieChartdata[k]]" [layout]='{width: 250, margin: {"t": 0, "b": 0, "l": 0, "r": 0}, showlegend: false}'></plotly-plot>



          <mat-list *ngIf="item.chartData && item.datatype == 'number'" role="list">
            <!-- <div style="display: flex;"> -->

              <div *ngFor="let label of item.chartData.ranges; let i = index;let even = even" style="width:100%;display: flex;">
                <div *ngIf="item.chartData.ranges[i/2]?.toString()  && even" style="width: 10% !important;height: 18px;margin:2px 5px;margin-left: 20px;" [ngStyle]="{'background-color': getLegendColor(item.legendCount)[item.legendColorIndex?item.legendColorIndex:0][i/2]}" >
                </div>
                <ng-container *ngIf="item.chartData.ranges[i]?.toString() && item.chartData.ranges[i+1]?.toString() && even">               

                  <input style="width: 30%;margin-right: 3px;" [(ngModel)]="item.chartData.ranges[i]" [disabled]="item.mode !== 'Enter Manually'">
                -
                  <input style="width: 30%;margin-left: 3px;" [(ngModel)]="item.chartData.ranges[i+1]" [disabled]="item.mode !== 'Enter Manually'" >
                <!-- {{}}-{{item.chartData.ranges[i+1]}} -->

              </ng-container>
              <span *ngIf="item.chartData.ranges[i/2]?.toString()  && even" style="font-size:12px ;margin-left: 5px;">
                {{item.chartData.values[i/2]}}
              </span>



              </div>

          </mat-list>


          <mat-list *ngIf="item.textValues && item.datatype == 'string'" role="list">
            <div style="width:90%;display: flex;" *ngFor="let value of item.textValues;let i = index"><div style="width: 20% !important;height: 18px;margin:0px 5%;" [ngStyle]="{'background-color': legendColorString[item.legendColorIndex?layer.legendColorIndex:0][i]}"></div>{{ value }}</div>
          </mat-list>

        </div>

        <!-- <div id="chart" style="width: 250px;height:350px;margin-left: 20px;"></div> -->
        <div *ngIf="poiChartData.length > 0">
          <plotly-plot *ngFor="let poiChart of poiChartData" id="chart" [data]="poiChart.data" [layout]="poiChart.layout"></plotly-plot>
        </div>

    <div class="rightside-nav-right">
      <button mat-icon-button color="primary" (click)="sidenav.toggle()"><mat-icon style="font-size: 60px;">arrow_right</mat-icon></button>
    </div>
  </mat-sidenav>
</mat-sidenav-container>

<div class="rightside-nav-left">
  <button mat-icon-button color="primary" (click)="sidenav.toggle()"><mat-icon style="font-size: 60px;">arrow_left</mat-icon></button>
</div>

<!-- <div *ngIf="isDisplayData" style="display: flex;position: fixed;bottom: 0px;right: 310px;z-index: 10000;width:80%;height: 200px;background-color: white;overflow-y: scroll;">
  <div style="width: 60%;">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container *ngFor="let item of keys;let i = index" matColumnDef="{{item}}">
        <th mat-header-cell style="font-weight: 500;color:#000000;text-align: center" *matHeaderCellDef> {{item.toUpperCase()}} </th>
        <td mat-cell style="text-align: center;font-size: 10px;" *matCellDef="let element"> {{element[keys[i]]}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <div #reportGraph style="width: 35%;height: 200px;margin-left: 20px;"></div>

</div> -->

<div id="tooltip" #tooltip style="position: absolute; z-index: 1; pointer-events: none;"></div>


