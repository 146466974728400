import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import * as mapboxgl from "mapbox-gl";
import { Observable, Subscription } from "rxjs";
import { CreateMapService } from "../create-map.service";
import { map } from "rxjs/operators";
import { BulkData } from "src/app/models/aimap.interface";
import { AiMapService } from "../map-ai-service";
import { MapBoxAiService } from "../mapbox-ai.service";
import { MapboxLayer } from "@deck.gl/mapbox";
import { GeoJsonLayer } from "@deck.gl/layers";
import Axios from "axios";
import { legendColor } from "../icons";
import { GraphService } from "../nav/services/graph.service";
import * as Tiny from "tinygradient";


@Component({
  selector: "app-public-result",
  templateUrl: "./public-result.component.html",
  styleUrls: ["./public-result.component.scss"],
})
export class PublicResultComponent implements OnInit {
  @ViewChild("gmap", { static: true }) mapElement: ElementRef; // For Placing map in HTML of component
  router: any;
  map: mapboxgl.Map;
  id: any;
  @ViewChild("piechart", { static: true }) pieChart: ElementRef;
  @ViewChild("tooltip", { static: true }) tooltip: ElementRef<HTMLDivElement>;
  @ViewChild("reportGraph") reportGraph: ElementRef;
  isDisplayData: boolean = false;
  // legendAttribute: string;
  // legendAttribute1: string;
  // legendAttribute2: string;
  // isochroneLayer: L.LayerGroup;
  fetching: boolean = false;

  // legendMax: number;
  // legendMin: number;
  // legendMin1: number;
  // legendMin2: number;
  // legendMax1: number;
  // legendMax2: number;

  areaDataRecivedSub: Subscription;
  recivedIsochroneDataSub: Subscription;

  dataSource;
  keys;
  displayedColumns;

  reportData: any = [];
  createMapMode: "Single Variant" | "Multiple Variant" | "AI Smart";

  singleVarientKeySelected: string;
  multiVarientKeySelected: Array<string> = [];
  bulkData: BulkData;

  critriaValuePredicted = [];
  pieChartdata;
  poiChartData = [];

  constructor(
    private route: ActivatedRoute,
    private createMapService: CreateMapService,
    private breakpointObserver: BreakpointObserver,
    private mapService: AiMapService,
    private mapboxAiService: MapBoxAiService,
    private graphService: GraphService
  ) {}

  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));
  toggleButtonVisible: boolean = true;

  criteriaData: Array<{
    name: string;
    year: string;
    value: string;
    area: string;
  }> = [];

  legendColor = legendColor;
  // legendColorString = legendColorString;

  ngOnInit() {
    this.route.paramMap.subscribe((paramMap) => {
      if (paramMap.has("id")) {
        this.id = paramMap.get("id");
      }
    });

    this.createMapService.getReportPublic(this.id);
    this.mapboxAiService.diplayPoiChart.subscribe((d) => {
      this.poiChartData.push(d);
    });

    this.mapboxAiService.diplayPoiChart.unsubscribe();

    this.createMapService.reportRecived.subscribe((data) => {
      console.log(data);
      this.reportData = data;
      this.preparePieChartdata(data);

      // this.mapService.getProjectReport(data.projectid);
      // this.mapService.projectdataChanged.subscribe((pdata) => {
      // this.mapboxAiService.init(pdata, this.mapElement);
      this.map = new mapboxgl.Map({
        container: this.mapElement.nativeElement,
        // style: "mapbox://styles/mapbox/streets-v11",
        style: "mapbox://styles/mapbox/dark-v10",
        zoom: 14,
        maxZoom: 19,
        center: [data.center.lng, data.center.lat],
        pitch: 45,
        // antialias: true,
      });

      this.map.on("styleimagemissing", (ev) => {});
      this.createMapService.reportPopupDivInit(this.tooltip);
      this.createMapService.map = this.map;
      this.mapboxAiService.map = this.map;


      this.map.on("style.load", async () => {

        setTimeout(() => {
          if (data.analysisLayers) {
            data.analysisLayers.map(async (d, i) => {
              let tbData = await this.mapService.getGeoJsonFromPostgres(d.table)
              setTimeout(() => {
                if (d.datatype == "string") {
                  this.createMapService.applyStyleString(
                    d,
                    tbData,
                    document.getElementById("tooltip")
                  );
                } else {
                  this.createMapService.applyStyle(
                    d,
                    tbData,
                    document.getElementById("tooltip")
                  );
                }

                if (d.labelColumn)
                  this.mapboxAiService.AddLabeltoGeojson(
                    tbData,
                    i,
                    d.labelColumn
                  );
              }, 1000);
            });
          }
          if (data.isochrones) {
            data.isochrones.map((d, i) => {
              console.log(d);
              this.mapboxAiService.addIsochroneLayer(d.geojson, i);
            });
          }
          if (data.pois) {
            data.pois.map((e, i) => {
              this.displayPoiData(e, i);
            });
          }
          if (data.routes) {
            data.routes.map((e, i) => {
              this.displayRoutesData(e, i);
            });
          }
          if (data.scripts) {
            data.scripts.map((e, i) => {
              this.displayScriptsData(e, i);
            });
          }
          if (data.geoprocessings) {
            data.geoprocessings.map((e, i) => {
              this.displaygeoproData(e, i);
            });
          }
        }, 1000);
      });

      // });
    });

    // this.areaDataRecivedSub = this.createMapService.areaDataRecived.subscribe(
    //   (data) => {
    //     this.critriaValuePredicted = [];
    //     this.criteriaData.map((cr) => {
    //       let p;
    //       switch (cr.name) {
    //         case "income":
    //           p = +cr.value + "Rs/Person";
    //           break;
    //         case "education":
    //           p = +cr.value + "%";
    //           break;
    //         case "jobs":
    //           p = +cr.value + "%";
    //           break;
    //         case "bussiness":
    //           p = +cr.value + "%";
    //           break;
    //         case "population":
    //           p = 0;
    //           for (let i = 0; i < data.length; i++) {
    //             p = p + (+cr.value / +cr.area) * data[i] * 100000;
    //           }
    //           break;
    //         case "poverty":
    //           p = +cr.value + "%";
    //           break;
    //         case "Marital Status":
    //           p = +cr.value + "%";
    //           break;
    //         case "House holds":
    //           p = +cr.value + "%";
    //           break;
    //         default:
    //       }
    //       this.critriaValuePredicted.push(p);
    //     });
    //   }
    // );
  }

  ngAfterViewInit(): void {
    this.createMapService.reportPopupDivInit(this.tooltip.nativeElement as any);
  }

  displayPoiData(e, i) {
    let d = {
      college: [],
      cafe: [],
      atm: [],
      bank: [],
      bar: [],
      school: [],
      bus_station: [],
      taxi: [],
      fuel: [],
      pharmacy: [],
      hospital: [],
      clinic: [],
      childcare: [],
      police: [],
      parking: [],
    };

    console.log(e);

    e.geojson.features.map((f) => {
      d[f.properties.amenity].push(f);
    });

    this.mapboxAiService.overpassData = d;
    this.mapboxAiService.chartDisplay();
    this.mapboxAiService.addPoiLayer(e.geojson, i);
  }

  async displayRoutesData(e, i) {
    e["route"] = e.data ? e.data : await this.mapService.getRouteDataDirect(e);
    console.log(e);
    // this.mapboxAiService.displayRoute(e,false,i)
    let layer = new MapboxLayer({
      id: "route" + i,
      filter: ["==", "extrude", "true"],
      type: GeoJsonLayer,
      data: e["route"],
      opacity: 0.7,
      stroked: true,
      lineWidthUnits: "pixels",
      // pointRadiusUnits: 'meter' ,
      filled: true,
      extruded: true,
      wireframe: true,
      getLineColor: [150, 150, 9],
      getLineWidth: 5,
      // pickable: true,
      // onHover: info => setTooltip2(info.object, info.x, info.y)
    });

    this.map.addLayer(layer);
    // let bb:any =  bbox({type: "FeatureCollection",features:[{type:'Feature',geometry:geometry,properties:{}}]});
    // this.map.fitBounds(bb);
  }

  async displayScriptsData(e, i) {
    let form = new FormData();

    form.append("type", e.type);
    form.append("query", e.query);
    form.append("id", this.reportData.projectid);
    const res = await this.mapService.findQueryInstant(form);
    e = { ...e, data: res };
    this.mapboxAiService.displayScriptData(
      e,
      document.getElementById("tooltip"),
      i,
      false
    );
  }

  async displaygeoproData(e, i) {
    let g = await this.mapService.getprocessingData(e.savedIds[0]);
    this.mapboxAiService.displayProcesseddata({ ...e, data: g }, i, true);
  }

  preparePieChartdata(f) {
    this.pieChartdata = [];
    f.analysisLayers.map((l) => {
      this.graphService.generateColor(+l.legendCount);
      if (l.datatype == "number") {
        this.graphService.prepareGeojsonPieChart([],l);
      } else {
        this.graphService.prepareGeojsonPieChartString(l, l);
      }
      //   let plotdata = {
      //     values: [0, 0, 0, 0, 0],
      //     labels: [
      //       +l.filter.max +
      //         " - " +
      //         (+l.filter.max - (+l.filter.max - +l.filter.min) / 5),
      //       +l.filter.max -
      //         (+l.filter.max - +l.filter.min) / 5 +
      //         " - " +
      //         (+l.filter.max - (2 * (+l.filter.max - +l.filter.min)) / 5),
      //       +l.filter.max -
      //         (2 * (+l.filter.max - +l.filter.min)) / 5 +
      //         " - " +
      //         (+l.filter.max - (3 * (+l.filter.max - +l.filter.min)) / 5),
      //       +l.filter.max -
      //         (3 * (+l.filter.max - +l.filter.min)) / 5 +
      //         " - " +
      //         (+l.filter.max - (4 * (+l.filter.max - +l.filter.min)) / 5),
      //       +l.filter.max -
      //         (4 * (+l.filter.max - +l.filter.min)) / 5 +
      //         " - " +
      //         +l.filter.min,
      //     ],
      //     type: "pie",
      //     marker: {
      //       colors: [
      //         "rgb(44, 127, 184)",
      //         "rgb(99, 99, 99)",
      //         "rgb(44, 162, 95)",
      //         "rgb(221, 28, 119)",
      //         "rgb(237, 248, 177)",
      //       ],
      //     },
      //   };

      //   if(l.type == 'Bulk'){
      //   l.data.map((d) => {
      //     if (
      //       d[l.column] <= +l.filter.max &&
      //       d[l.column] >= +l.filter.max - (+l.filter.max - +l.filter.min) / 5
      //     ) {
      //       plotdata.values[0] += 1;
      //     } else if (
      //       d[l.column] <= +l.filter.max - (+l.filter.max - +l.filter.min) / 5 &&
      //       d[l.column] >=
      //         +l.filter.max - (2 * (+l.filter.max - +l.filter.min)) / 5
      //     ) {
      //       plotdata.values[1] += 1;
      //     } else if (
      //       d[l.column] <=
      //         +l.filter.max - (2 * (+l.filter.max - +l.filter.min)) / 5 &&
      //       d[l.column] >=
      //         +l.filter.max - (3 * (+l.filter.max - +l.filter.min)) / 5
      //     ) {
      //       plotdata.values[2] += 1;
      //     } else if (
      //       d[l.column] <=
      //         +l.filter.max - (3 * (+l.filter.max - +l.filter.min)) / 5 &&
      //       d[l.column] >=
      //         +l.filter.max - (4 * (+l.filter.max - +l.filter.min)) / 5
      //     ) {
      //       plotdata.values[3] += 1;
      //     } else if (
      //       d[l.column] <=
      //         +l.filter.max - (4 * (+l.filter.max - +l.filter.min)) / 5 &&
      //       d[l.column] >= +l.filter.min
      //     ) {
      //       plotdata.values[4] += 1;
      //     }
      //   });
      // }else{

      // }
      this.pieChartdata.push(this.graphService.currentPlot);
      console.log(this.pieChartdata)
    });
  }

  getLegendColor(i) {
    // console.log(i);
    let color = [];
    legendColor.map((arr) => {
      const tc = new Tiny(arr).rgb(i).map((c) => c.toHexString());
      color.push(tc);
    });
    return color;
  }

  ngOnDestroy(): void {}
}
