import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-building-info',
  templateUrl: './building-info.component.html',
  styleUrls: ['./building-info.component.scss']
})
export class BuildingInfoComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<BuildingInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      name: string,
      description: string,
      image?: any,
      type: string,
      height?: number 
    }, private snackBar: MatSnackBar) { }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onImage(event){
    const file = (event.target as HTMLInputElement).files[0]
    if((file as File).type == 'image/png'||(file as File).type == 'image/jpg'||(file as File).type == 'image/jpeg'){
      this.data.image = file
    }else{
      this.snackBar.open('Only png and jpeg file formats are accepted', 'ok');
    }
  }

}
