import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { infoWindow } from 'src/app/models/info-window.interface';
import { projectData } from 'src/app/models/projectData.interface';


@Component({
  selector: 'app-info-window',
  templateUrl: './info-window-gs.component.html',
  styleUrls: ['./info-window-gs.component.scss']
})
export class InfoWindowGSComponent {
  projectData : projectData;


  constructor(
    public dialogRef: MatDialogRef<InfoWindowGSComponent>,
    @Inject(MAT_DIALOG_DATA) public data: infoWindow) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  onImage(event){
    const file = (event.target as HTMLInputElement).files[0]
    this.data.image = file
  }
}
