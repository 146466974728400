import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.scss']
})
export class TagComponent implements OnInit {
  tagForm: FormGroup
  canSubmit: boolean= false
  //colorClasses: Array<string> = ["Red","Pink","Purple","DeepPurple","Indigo","Blue","LightBlue","Cyan","Teal","Green","LightGreen","Lime","Yellow","Amber","Orange","DeepOrange","Brown","Grey","BlueGrey","DeepOrangeN"]
  constructor(
    public dialogRef: MatDialogRef<TagComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {}

    ngOnInit() {
      this.tagForm = new FormGroup({
        // 'colorclass':new FormControl(null, {validators: Validators.required}),
        'name':new FormControl(null, {validators: [Validators.required, Validators.maxLength(7)] }),
        'content': new FormControl(null, {validators: [Validators.required, Validators.maxLength(150)] })
      })
      this.tagForm.valueChanges.subscribe((val)=>{
        this.data = {
          ...this.data,
          content: val.content,
          // colorClass: val.colorclass,
          name: val.name
        }
      })
    }

    onCancel(){
      this.dialogRef.close()
    }

}
