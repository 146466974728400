import { Injectable } from "@angular/core";
import { OpenStreetMapProvider } from "leaflet-geosearch";

@Injectable({
  providedIn: "root",
})
export class PlaceSearchService {
  constructor() {}

  // results: {
  //   bounds: [];
  //   label: string;
  //   raw: Object;
  // }[];

  results: any;

  provider = new OpenStreetMapProvider();
  async routeStart(value) {
    this.results = await this.provider.search({ query: value });
    return this.results;
  }
}
