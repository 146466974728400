import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadingStrategy, PreloadAllModules } from '@angular/router';
import { AuthGaurd, AuthChildGaurd, AuthModuleGaurd } from './auth/auth.gaurd';
import { PublicResultComponent } from './heli-ai/public-result/public-result.component';
import { PointCloudComponent } from './gis/point-cloud/point-cloud.component';
import { AdminHomeComponent } from './gis/admin-home/admin-home.component';
import { ContractManagementComponent } from './main-nav/contract-management/contract-management.component';
import { NewAdminDashboardComponent } from './gis/new-admin-dashboard/new-admin-dashboard.component';

// import { AdminComponent } from './map-ai/admin/admin.component';
// import { DashboardComponent } from './map-ai/dashboard/dashboard.component';
// const routes: Routes = [
//   {path: '', component: HomeComponent},
//   {path: 'createaccount', component: SignupComponent, canActivate:[AuthGaurd]},
//   {path: 'resetpassword', component: ResetPasswordComponent},
//   {path: 'rp', component: EnteremailComponent},
//   {path: 'privacypolicy', component: PolicyComponent },
//   {path: 'dashboard', component: MainNavComponent, canActivate:[AuthGaurd], children: [
//     {path: 'region', component: RegionComponent, canActivateChild: [AuthChildGaurd]},
//     {path: 'region/:regionid', component: RegionSitesComponent, canActivateChild: [AuthChildGaurd]},
//     {path: 'sites', component: SitesComponent, canActivateChild: [AuthChildGaurd]},
//     {path: 'sites/:siteid', component: ItemsComponent, canActivateChild: [AuthChildGaurd]},
//     {path: 'shared', component: SharedItemsComponent, canActivateChild: [AuthChildGaurd]},
//     {path: 'demo', component: DemoComponent, canActivateChild: [AuthChildGaurd]}
//   ]},
//   {path: 'gis/:id', component: GisNavComponent, canActivate: [AuthGaurd]},
//   {path: 'dsm/:id', component: DsmNavComponent, canActivate: [AuthGaurd]},
//   {path: 'dsm/vr/:id', component: WebvrComponent,canActivate: [AuthGaurd]},
//   {path: 'gis', redirectTo: 'dashboard/sites'},
//   {path: 'dsm', redirectTo: 'dashboard/sites'},
//   {path: 'pan/:id', component: PanoramaComponent,canActivate: [AuthGaurd]},
//   {path: 'psmNav', component: PsmNavComponent,canActivate: [AuthGaurd]},
//   {path: 'indoor/:id', component: PanoromaWalkthroughComponent, canActivate:[AuthGaurd]},
//   {path: '**', redirectTo: ''}
// ];

const routes: Routes = [
  { path: '', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
  { path: 'dashboard', loadChildren: () => import('./main-nav/main-nav.module').then(m => m.MainNavModule), canActivate:[AuthGaurd], canLoad: [AuthModuleGaurd]},
  { path: 'gis', loadChildren: () => import('./gis/gis.module').then(m => m.GisModule), canActivate:[AuthGaurd], canLoad: [AuthModuleGaurd]},
  { path: 'dsm', loadChildren: () => import('./dsm/dsm.module').then(m => m.DsmModule), canActivate:[AuthGaurd], canLoad: [AuthModuleGaurd]},
  { path: 'pan', loadChildren: () => import('./panorama/panorama.module').then(m => m.PanoramaModule), canActivate:[AuthGaurd], canLoad: [AuthModuleGaurd]},
  { path: 'indoor', loadChildren: () => import('./panorama-walk/panorama-walk.module').then(m => m.PanoramaWalkModule), canActivate:[AuthGaurd], canLoad: [AuthModuleGaurd]},
  { path: 'psmNav', loadChildren: () => import('./psm/psm.module').then(m => m.PsmModule), canActivate:[AuthGaurd], canLoad: [AuthModuleGaurd]},
  { path: 'model-config', loadChildren: () => import('./model-configurator/model-configurator.module').then(m => m.ModelConfiguratorModule), canActivate:[AuthGaurd], canLoad: [AuthModuleGaurd]},
  { path: 'map-ai', loadChildren: () => import('./heli-ai/heli-ai.module').then(m => m.HeliAiModule), canActivate:[AuthGaurd], canLoad: [AuthModuleGaurd]},
  { path: 'model-map', loadChildren: () => import('./model-on-map/model-on-map.module').then(m => m.ModelOnMapModule), canActivate:[AuthGaurd], canLoad: [AuthModuleGaurd]},
  { path: 'public/:id', component: PublicResultComponent ,canActivate: []},
  { path: 'admin', loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule), canActivate:[AuthGaurd], canLoad: [AuthModuleGaurd]},
  { path: '3d', component: PointCloudComponent},
  // { path: 'contract-management', component: ContractManagementComponent },
  { path: 'new-dashboard', component: NewAdminDashboardComponent, canActivate:[AuthGaurd], canLoad: [AuthModuleGaurd] },
  
  // { path: 'admin-home', component: AdminHomeComponent, canActivate:[AuthGaurd], canLoad: [AuthModuleGaurd]},
  // { path: 'admin/dashboard', component: DashboardComponent, canActivate:[]},
  { path: '**', redirectTo: ''}
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy', useHash:true})],
  exports: [RouterModule],
  providers: [AuthGaurd, AuthChildGaurd, AuthModuleGaurd]
})
export class AppRoutingModule { }
