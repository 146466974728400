import { ElementRef, Injectable } from "@angular/core";
import * as mapboxgl from "mapbox-gl";
import * as toGeoJson from "@mapbox/togeojson";
import * as uuid from "uuid";
(mapboxgl as any).accessToken =
  // "pk.eyJ1IjoicmFqYW4wMjciLCJhIjoiY2s2b25oYTg1MDJtazNsbXZ4OTFqcnN2cSJ9.MGaLTVPvgOjQazj7ZTX1nQ";
  "pk.eyJ1IjoicHJhamFwYXRpbGFsaXQiLCJhIjoiY2wydTJ0MzhmMDA2dzNlbWpqcTR2MDBveCJ9.MAvF76k-FE_j3XBgnTUQ7g";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { from, Subject, Subscription } from "rxjs";
import Axios from "axios";
import { MapService, MODE } from "../gis/map.service";
import { ServerService } from "../server.service";
import { addlayersinfo } from "../models/add-layers.interface";
import { LayerTree } from "../models/layer-tree.interface";
import { featureIcons, geoIcon, matIcons } from "./icons";
import { AuthService } from "../auth/auth.service";
import { infoWindow } from "../models/info-window.interface";
import { MatSnackBar } from "@angular/material/snack-bar";
import { MatDialog } from "@angular/material/dialog";
import {
  area,
  centroid,
  distance,
  bbox,
  Feature,
  length,
  point,
  polygon,
  multiPolygon,
  inside,
  featureCollection,
  GeoJSONObject,
  feature,
  lineString,
  center,
} from "@turf/turf";
import { User } from "../models/users.model";
import { HttpClient } from "@angular/common/http";
// import * as PlotlyJS from 'plotly.js/dist/plotly.js';
const PlotlyJS = (window as any).PlotlyJS;
import { PlotlyModule } from "angular-plotly.js";
PlotlyModule.plotlyjs = PlotlyJS;
import { InfoWindowGSComponent } from "../model-on-map/info-window-gs/info-window-gs.component";
import { aiprojectData } from "../models/aimap.interface";
import { AiMapService } from "./map-ai-service";
import {
  ScatterplotLayer,
  GeoJsonLayer,
  ArcLayer,
  TextLayer,
  IconLayer,
} from "@deck.gl/layers";
import { PoiService } from "./poi.service";
import { CreateMapService } from "./create-map.service";
import { MapboxLayer } from "@deck.gl/mapbox";
import { SocketAiMap } from "../socket-services/socket-aimap";
import * as ColorConverter from "color-convert";
import { addSublayersinfo } from "../models/add-subLayers.interface";
import { HexagonLayer } from "@deck.gl/aggregation-layers";
import { DeleteDataScienceComponent } from "./nav/delete-data-science/delete-data-science.component";
import * as normalize from "normalize-value";

export interface dataGeoJSONMBx {
  type: "FeatureCollection";
  features: Array<{
    type: "Feature";
    geometry: GeoJSON.Geometry;
    id?: string;
    properties: {
      layer: string;
      sublayer?: string;
      colorClass?: string;
      name: string;
      description?: string;
      category: string;
      area?: number;
      length?: number;
      perimeter?: number;
      center?: Array<number>;
      image?: string;
      id?: string;
      icon?: string;
      url?: string;
      stroke?: string;
      styleUrl?: string;
      styleHash?: string;
      styleMapHash?: {
        normal: string;
        highlight: string;
      };
      iconUrl?: string;
      "stroke-opacity"?: string;
      "stroke-width"?: string;
      fill?: string;
      "fill-opacity"?: string;
    };
  }>;
}

export interface StyleLayer {
  id: string;
  source: string;
  "source-layer"?: string;
  type:
    | "fill"
    | "line"
    | "symbol"
    | "circle"
    | "heatmap"
    | "fill-extrusion"
    | "raster"
    | "hillshade"
    | "background"
    | "sky";
  paint?: {
    [x: string]: any;
  };
  filter: string[];
}

@Injectable({ providedIn: "root" })
export class MapBoxAiService {
  // Project Data
  projectData: aiprojectData;
  //   modelType: 'GLTF' | 'OBJ' = 'OBJ';
  user: User;
  owner: boolean = false;
  canEdit: boolean = false;

  chart: ElementRef<HTMLDivElement>;
  searchOnMapDataSub: Subscription;

  // Map
  map: mapboxgl.Map;
  draw: MapboxDraw;
  uiIntercations: string[] = [
    "scrollZoom",
    "boxZoom",
    "dragRotate",
    "dragPan",
    "keyboard",
    "doubleClickZoom",
    "touchZoomRotate",
  ];
  isochroneModeSub: Subscription;
  isochroneMode: boolean = false;
  analysisMode: boolean = false;

  recivedIsochroneDataSub: Subscription;
  analysisModeSub: Subscription;

  overpassData: {
    college: Array<{}>;
    cafe: Array<{}>;
    atm: Array<{}>;
    bank: Array<{}>;
    bar: Array<{}>;
    school: Array<{}>;
    bus_station: Array<{}>;
    taxi: Array<{}>;
    fuel: Array<{}>;
    pharmacy: Array<{}>;
    hospital: Array<{}>;
    clinic: Array<{}>;
    childcare: Array<{}>;
    police: Array<{}>;
    parking: Array<{}>;
  };
  zoomSearchFetureSub: Subscription;

  geoIconsUrl = geoIcon;

  // Model Data
  sceneLayer: mapboxgl.CustomLayerInterface;
  dataSources: dataGeoJSONMBx = {
    type: "FeatureCollection",
    features: [],
  };
  layerSources: { [ln: string]: dataGeoJSONMBx } = {};

  toggleScaleUI: Subject<{ layerName: string; show: boolean }> = new Subject();
  isochronedataPreapred: Subject<{
    type: "FeatureCollection";
    features: Array<any>;
  }> = new Subject();
  poiDataPrepared: Subject<{
    type: "FeatureCollection";
    features: Array<any>;
  }> = new Subject();
  activeGrid = [];
  mainDownSampleURL: any;
  mainDownSampleData: any;
  heights = [];

  // 2D GeoSpatial Layers
  currentSelectedLayer: addlayersinfo;

  gotProject: Subject<{ layerTree: Array<LayerTree>; id: string }> =
    new Subject();
  diplayPoiChart: Subject<any> = new Subject();
  // toggleHeatMap: Subject<boolean> = new Subject();
  // toggleHeatMap

  modeUpdated: Subject<
    | "deleteMode"
    | "dataMode"
    | "bulkDataMode"
    | "elevationMode"
    | "listenMode"
    | "kmlMode"
    | "shpMode"
  > = new Subject();
  mode: MODE = "dataMode";

  // layer tree
  layerTree: Array<LayerTree> = [];

  // DOM
  container: ElementRef<HTMLDivElement>;
  mapStyles: ElementRef<HTMLDivElement>;
  loaded: Subject<number> = new Subject();
  isLoaded: boolean = false;

  toolTip: any;

  // Subscription
  listenAddFeatureSub: Subscription;
  listenDeleteFeatureSub: Subscription;
  listenAddMLayerSub: Subscription;
  listenDeleteMLayerSub: Subscription;
  listenAddALayerSub: Subscription;
  listenDeleteALayerSub: Subscription;

  constructor(
    private AiMapService: AiMapService,
    private PoiService: PoiService,
    private server: ServerService,
    private mapService: MapService,
    private socket: SocketAiMap,
    private auth: AuthService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    public http: HttpClient,
    private CreateMapService: CreateMapService
  ) {}

  getMap() {
    return this.map;
  }

  setTooltip(object, x, y, info?: any) {
    if (object && object.properties) {
      this.toolTip.innerHTML = "";
      let keys = Object.keys(object.properties);
      keys.map((e) => {
        if (e !== "fillColor")
          this.toolTip.innerHTML += `${e} :  ${object.properties[e]} <br>`;
      });
      this.toolTip.style.display = "block";
      this.toolTip.style.left = x + "px";
      this.toolTip.style.top = y + "px";
      this.toolTip.style.padding = "15px";
      this.toolTip.style.fontSize = "11px";
      this.toolTip.style.backgroundColor = "#eeeeee";
      this.toolTip.style.border = "1px solid #000";
    } else {
      this.toolTip.style.display = "none";
    }
  }

  // ************************************************** INITAILISE SERVICE *****************************************************************************
  // ************************************************** INITAILISE SERVICE *****************************************************************************
  // ************************************************** INITAILISE SERVICE *****************************************************************************

  init(
    ai: aiprojectData,
    container: ElementRef<HTMLDivElement>,
    tooltipContainer?: any
  ) {
    this.toolTip = tooltipContainer;
    this.container = container;
    this.user = this.auth.getUser();

    this.projectData = ai;
    console.log(this.projectData);

    if (ai.data) this.dataSources = ai.data;
    else this.dataSources = { type: "FeatureCollection", features: [] };

    setTimeout(() => {
      this.createBaseMap();
      this.map.on("style.load", () => {
        if (
          this.projectData.data &&
          Object.keys(this.projectData.data).length > 0
        )
          this.dataSources = this.projectData.data;

        this.updateLayerTree(this.dataSources);
        console.log(this.layerTree);

        setTimeout(() => {
          this.layerTree.forEach((layer) => {
            this.setLayer({
              colorClass: layer.colorClass,
              icon: layer.icon,
              id: layer.id,
              name: layer.name,
            });
            layer.children.forEach((s) => {
              if (s.type == "list") {
                this.setSubLayer(s);
              }
            });
          });
        }, 100);
        //new changes of layer
        setTimeout(() => {
          this.dataLayerVisibility(false);
        }, 810);

        this.listenAddFeatureSub = this.listenAddFeature().subscribe(
          (feat: any) => {
            const layerName = feat.properties.layer;
            this.dataSources.features.push(feat as any);
            (this.map.getSource(layerName) as any).setData(this.dataSources);
            this.updateLayerTree(this.dataSources);
          }
        );

        this.listenDeleteFeatureSub = this.listenDeleteFeature().subscribe(
          (feat: any) => {
            const featIndex = this.dataSources.features.findIndex(
              (f) => f.id == (feat as any).id
            );
            const layerName = feat.properties.layer;
            const sources = this.map.getSource(layerName) as any;
            this.dataSources.features.splice(featIndex, 1);
            sources.setData(this.dataSources);
            this.updateLayerTree(this.dataSources);
          }
        );

        this.zoomSearchFetureSub = this.AiMapService.zoomFeature.subscribe(
          (data: any) => {
            let bb: any = bbox(data);
            this.map.fitBounds(bb);
          }
        );

        this.listenAddMLayerSub = this.listenAddMLayer().subscribe(
          (features) => {
            const layerName = features[0].properties.layer;
            this.dataSources.features.push(...features);
            (this.map.getSource(layerName) as any).setData(this.dataSources);
            this.updateLayerTree(this.dataSources);
          }
        );

        this.listenDeleteMLayerSub = this.listenDeleteMLayer().subscribe(
          (layerName) => {
            this.dataSources.features = this.dataSources.features.filter(
              (feat) => feat.properties.layer != layerName
            );
            (this.map.getSource(layerName) as any).setData(this.dataSources);
            this.updateLayerTree(this.dataSources);
          }
        );

        this.map.on("styleimagemissing", (ev) => {});
      });
    }, 8);
  }

  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // *******************************************************************************************************************************

  // ****************************************** MAP LOAD FEATURE*************************************************************************************
  // ****************************************** MAP LOAD FEATURE*************************************************************************************
  // ****************************************** MAP LOAD FEATURE*************************************************************************************

  createBaseMap() {
    this.map = new mapboxgl.Map({
      container: this.container.nativeElement,
      // style: "mapbox://styles/mapbox/streets-v11",
      style: "mapbox://styles/mapbox/streets-v11",
      zoom: 14,
      maxZoom: 19,
      center: [
        this.projectData.maps.metaData.lng,
        this.projectData.maps.metaData.lat,
      ],
      pitch: 45,
      // antialias: true,
    });

    this.draw = new MapboxDraw({
      displayControlsDefault: true,
      controls: {
        trash: false,
        combine_features: false,
        uncombine_features: false,
      },
    });
    this.map.addControl(this.draw, "top-left");

    var scale = new mapboxgl.ScaleControl({
      maxWidth: 120,
      unit: "imperial",
    });
    this.map.addControl(scale, "bottom-right");
    scale.setUnit("metric");

    this.map.on("styledata", () => {
      let switchLayer = (layer) => {
        console.log(layer);

        var layerId = layer.target.id;
        if (this.map)
          this.map.setStyle("mapbox://styles/mapbox/" + layerId, {
            diff: false,
          });
      };

      let mapStyles = document.getElementsByClassName("mapstyle");
      for (var i = 0; i < mapStyles.length; i++) {
        mapStyles[i].addEventListener("click", switchLayer);
      }
    });

    this.map.on("draw.create", (ev) => {
      console.log(ev);

      this.addDrawnFeature(ev.features);
    });
    this.map.on("draw.update", (ev) => {});
    this.map.on("draw.delete", (ev) => {});
    this.joinRoom(this.projectData.id);

    this.isochrone();
    this.poi();
    this.CreateMapService.onInit(this.map);
    this.searchOnMap();
  }

  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // *******************************************************************************************************************************

  // *********************************************  GEOPROCESSING FEATURE  **********************************************************************************
  // *********************************************  GEOPROCESSING FEATURE  **********************************************************************************
  // *********************************************  GEOPROCESSING FEATURE  **********************************************************************************

  removeprocessingLayer(i) {
    if (this.map.getLayer("processing-layer-" + i))
      this.map.removeLayer("processing-layer-" + i);
  }
  removeDataScience(i) {
    if (this.map.getLayer("layer-" + i)) this.map.removeLayer("layer-" + i);
  }
  async displayDataScience(d, i) {
    if (d.mode == "Data Details" || d.type == "Data Details") {
      let k = d.data;
      // this.snackbar.open(`Key ${Object.keys(d.data)} Values ${Object.values(d.data)}`, 'Close', {
      //     horizontalPosition: 'center',
      //     verticalPosition: 'bottom',
      //   panelClass: ['red-snackbar'],
      //   });
      this.dialog
        .open(DeleteDataScienceComponent, {
          width: "600px",
          // height: 'auto',
          data: k,
        })
        .afterClosed()
        .subscribe((data) => {});

      this.AiMapService.isSpinner.next(false);
    } else {
      const result = await Axios.get(d.data);
      let data = result.data;
      let layer = new MapboxLayer({
        id: "layer-" + i,
        type: GeoJsonLayer,
        data: data,
        opacity: 0.8,
        stroked: true,
        autoHighlight: true,
        highlightColor: [100, 128, 255],
        filled: true,
        extruded: true,
        getLineColor: (d) => [250, 55, 250],
        // lineWidthUnits: 'pixels',
        // pointRadiusUnits: 'pixels' ,
        // lineWidthScale: 20,
        // lineWidthMinPixels: 20,
        getRadius: 10,
        // pointRadiusMinPixels:10,
        getLineWidth: 10,
        pickable: true,
        onHover: (info) => this.setTooltip(info.object, info.x, info.y),
      });
      let bbb: any = bbox(data);
      this.map.addLayer(layer);
      this.map.fitBounds(bbb);
      this.AiMapService.isSpinner.next(false);
    }
  }

  displayProcesseddata(data, i, isfromDB?: boolean) {
    let gData, distancedata = { type: "FeatureCollection", features: [] };

    if (isfromDB) {
      gData = { type: "FeatureCollection", features: data.data.processedData };

      if (data.type == "Points Inside Polygon") {
        let text = "";
        data.data.processedData.map((g, i) => {text = text + "Polygon-" +(i + 1) +' <span style="color:grey;font-weight:600;marginleft:5px"> (' + g.pointCollection.features.length +")</span><br>";
          distancedata.features = [ ...distancedata.features, ...g.pointCollection.features];
        });
        let info = document.getElementById("pointPolygon");
        info.style.visibility = "visible";
        info.innerHTML = text;
      } else if (data.type == "Pair LatLng") {
        gData = data.data.processedData;
      }
    } else {
      gData = { type: "FeatureCollection", features: data.data.features };
      if (data.type == "Points Inside Polygon") {
        let text = "";
        data.data.map((g) => {
          text = text + "Polygon-" + (i + 1) +' <span style="color:grey;font-weight:600;marginleft:5px"> (' + g.pointCollection.features.length +")</span><br>";
          distancedata.features = [...distancedata.features, ...g.pointCollection.features,
          ];
        });
        let info = document.getElementById("pointPolygon");
        info.style.visibility = "visible";
        info.innerHTML = text;
      }
    }

    // let fillColor;
    // let c = style.style.fillColor.split('(')[1].split(')')[0].split(',');
    // c.map((e, i) => {
    //   c[i] = +e;
    //   if(i==3){
    //     c[i] = Math.round(e*255);
    //   }
    // })
    // fillColor = c;

    // let strokeColor;
    // let s = style.style.fillColor.split('(')[1].split(')')[0].split(',');
    // s.map((e, i) => {
    //   s[i] = +e;
    //   if(i==3){
    //     s[i] = Math.round(e*255);
    //   }
    // })
    // strokeColor = c;

    // if (data.type == "Pair LatLng") {
    //   let arcLayer = new MapboxLayer({
    //     id: "processing-layer-" + i,
    //     filter: ["==", "extrude", "true"],
    //     type: ArcLayer,
    //     data: gData,
    //     getWidth: 5,
    //     getSourcePosition: (d) => [d.longitude_1, d.latitude_1],
    //     getTargetPosition: (d) => [d.longitude_2, d.latitude_2],
    //     getSourceColor: (d) =>
    //       data.fillColorSource
    //         ? this.rgbToArrayListColorConverter(data.fillColorSource)
    //         : [250, 140, 0],
    //     getTargetColor: (d) =>
    //       data.fillColorDestination
    //         ? this.rgbToArrayListColorConverter(data.fillColorDestination)
    //         : [0, 140, 250],
    //   });

    //   this.map.addLayer(arcLayer);
    // } else {
      let getFillColor = (d) => {
        if (d.properties) {
          if (d.properties.type == "source") {
            if (data.fillColorSource) {return this.rgbToArrayListColorConverter(data.fillColorSource);} 
            else { return [0, 250, 154];}
          } else if (d.properties.type == "destination") {
            if (data.fillColorDestination) {return this.rgbToArrayListColorConverter(data.fillColorDestination); } 
            else { return [0, 250, 154]; }
          } else {
            if (data.fillColor) {return this.rgbToArrayListColorConverter(data.fillColor); } 
            else {return [0, 250, 154];}
          }
        } else {
          if (data.fillColor) { return this.rgbToArrayListColorConverter(data.fillColor); } 
          else { return [0, 250, 154];}
        }
      };

      const layer = new MapboxLayer({
        id: "processing-layer-" + i,
        type: GeoJsonLayer,
        data: distancedata.features.length > 0 ? distancedata : gData,
        opacity: 0.5,
        stroked: true,
        filled: true,
        extruded: true,
        // wireframe: true,
        lineWidthUnits: "pixels",
        pointRadiusUnits: "pixels",
        // lineWidthScale: 20,
        // lineWidthMinPixels: 20,

        getElevation: (d) =>
          !d.properties
            ? 20
            : d.properties.height
            ? d.properties.height
            : data.type == "Points Inside Polygon"
            ? 20
            : d.properties.type == "source"
            ? 0
            : d.properties.type == "destination"
            ? 0
            : 20,
        getFillColor: (d: any) => getFillColor(d),
        getLineColor: (d: { strokeColor: any }) =>
          d.strokeColor
            ? this.rgbToArrayListColorConverter(d.strokeColor)
            : [30, 144, 255],
        getRadius: 10,
        pointRadiusMinPixels: 10,
        getLineWidth: 3,
        pickable: true,
        onHover: (info) => this.setTooltip(info.object, info.x, info.y),
      });
      let bb: any = bbox(
        distancedata?.features?.length > 0 ? distancedata : gData
      );

      if (this.map.getLayer("processing-layer-" + i)) {
        this.map.removeLayer("processing-layer-" + i);
      }
      this.map.addLayer(layer);
      this.map.fitBounds(bb);
    // }
  }

  rgbToArrayListColorConverter(color) {
    let s = color.split("(")[1].split(")")[0].split(",");
    s.map((e, i) => {
      s[i] = +e;
      if (i == 3) {
        s[i] = Math.round(e * 255);
      }
    });
    return s;
  }

  displayProcesseddata3D(data, i, isPair?: boolean, gdata?: any) {
    let arcLayer;
    if (isPair) {
      arcLayer = new MapboxLayer({
        id: "processing-layer-" + i,
        filter: ["==", "extrude", "true"],
        type: ArcLayer,
        data: data,
        getWidth: 5,
        getSourcePosition: (d) => [d.longitude_1, d.latitude_1],
        getTargetPosition: (d) => [d.longitude_2, d.latitude_2],
        getSourceColor: (d) => [250, 140, 0],
        getTargetColor: (d) => [0, 140, 250],
      });
    } else {
      arcLayer = new MapboxLayer({
        id: "processing-layer-" + i,
        filter: ["==", "extrude", "true"],
        type: ArcLayer,
        data: data,
        getWidth: 5,
        getSourcePosition: (d) => d.from.coordinates,
        getTargetPosition: (d) => d.to.coordinates,
        getSourceColor: (d) =>
          gdata.fillColorSource
            ? this.rgbToArrayListColorConverter(gdata.fillColorSource)
            : [250, 140, 0],
        getTargetColor: (d) =>
          gdata.fillColorDestination
            ? this.rgbToArrayListColorConverter(gdata.fillColorDestination)
            : [0, 140, 250],
      });
    }

    this.map.addLayer(arcLayer);
  }

  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // *******************************************************************************************************************************

  // *********************************************** ROUTING FEATURES********************************************************************************
  // *********************************************** ROUTING FEATURES********************************************************************************
  // *********************************************** ROUTING FEATURES********************************************************************************

  removeRouteLayer(i?: number, isMain?: boolean) {
    if (this.map.getLayer("route-layer-" + i + "-drive"))
      this.map.removeLayer("route-layer-" + i + "-drive");
    if (this.map.getLayer("route-layer-" + i + "-bike"))
      this.map.removeLayer("route-layer-" + i + "-bike");
    if (this.map.getLayer("route-layer-" + i + "-walk"))
      this.map.removeLayer("route-layer-" + i + "-walk");
    if (isMain)
      if (this.map.getLayer("route-layer-" + i))
        this.map.removeLayer("route-layer-" + i);
  }

  displayRoute(d, isAll, i) {
    let mode;
    if (isAll) {
      mode = "route-layer-" + i + "-" + d.data["transport-mode"];
      if (this.map.getLayer(mode)) this.map.removeLayer(mode);
    } else {
      mode = "route-layer-" + i;
      if (this.map.getLayer("route-layer-" + i + "-drive"))
        this.map.removeLayer("route-layer-" + i + "-drive");
      if (this.map.getLayer("route-layer-" + i + "-bike"))
        this.map.removeLayer("route-layer-" + i + "-bike");
      if (this.map.getLayer("route-layer-" + i + "-walk"))
        this.map.removeLayer("route-layer-" + i + "-walk");
      if (this.map.getLayer(mode)) this.map.removeLayer(mode);
    }
    const geometry: any = d.route.features[0].geometry as any;
    let color;
    switch (d.data["transport-mode"]) {
      case "drive":
        color = [153, 51, 153];
        // i = "drive-"+i;
        break;
      case "bike":
        color = [51, 153, 102];
        // i = "bike-"+i;
        break;
      case "walk":
        color = [255, 204, 0];
        // i = "bike-"+i;
        break;
    }
    let layer = new MapboxLayer({
      id: mode,
      filter: ["==", "extrude", "true"],
      type: GeoJsonLayer,
      data: {
        type: "FeatureCollection",
        features: [{ type: "Feature", geometry: geometry }],
      },
      opacity: 0.7,
      stroked: true,
      lineWidthUnits: "pixels",
      // pointRadiusUnits: 'meter' ,
      filled: true,
      extruded: true,
      wireframe: true,
      getLineColor: color,
      getLineWidth: 5,
      // pickable: true,
      // onHover: info => setTooltip2(info.object, info.x, info.y)
    });

    this.map.addLayer(layer);
    let bb: any = bbox({
      type: "FeatureCollection",
      features: [{ type: "Feature", geometry: geometry, properties: {} }],
    });
    this.map.fitBounds(bb);
    this.AiMapService.isSpinner.next(false);
  }

  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // *******************************************************************************************************************************

  // ******************************************* GEOJSON ADD REMOVE FEATURE ************************************************************************************
  // ******************************************* GEOJSON ADD REMOVE FEATURE ************************************************************************************
  // ******************************************* GEOJSON ADD REMOVE FEATURE ************************************************************************************

  removeGeoJsonLayer(i) {
    if (this.map.getLayer("geoJson-layer-" + i))
      this.map.removeLayer("geoJson-layer-" + i);
  }

  async addGeoJson(geojson, i, popupDiv) {
    if (geojson.data) {
      this.AiMapService.isSpinner.next(true);
      const data = geojson.data;
      let fillColor, strokeColor;

      if (geojson.fillColor && !geojson.fillColor.includes("#")) {
        const hex = geojson.fillColor;
        // convert to RGB
        let c = hex.split("(")[1].split(")")[0].split(",");
        c.map((e, i) => {
          c[i] = +e;
          if (i == 3) {
            c[i] = Math.round(e * 255);
          }
        });
        fillColor = c;
      } else {
        fillColor = [0, 128, 255, 255];
      }

      if (geojson.strokeColor && !geojson.strokeColor.includes("#")) {
        const hex2 = geojson.strokeColor;
        // convert to RGB
        let b = hex2.split("(")[1].split(")")[0].split(",");
        b.map((e, i) => {
          b[i] = +e;
          if (i == 3) {
            b[i] = Math.round(e * 255);
          }
        });
        strokeColor = b;
      } else {
        strokeColor = [0, 0, 0, 255];
      }
      ////////

      if (this.map.getLayer("geoJson-layer-" + i))
        this.map.removeLayer("geoJson-layer-" + i);
      let bulkLayer2 = new MapboxLayer({
        id: "geoJson-layer-" + i,
        type: GeoJsonLayer,
        data: data,
        pointRadiusMinPixels: geojson.unit ? geojson.unit : 2,
        // autoHighlight: true,
        sizeUnits: "meters",
        // highlightColor: [0, 128, 255],
        opacity: 0.8,
        stroked: true,
        filled: true,
        extruded: geojson.scale ? true : false,
        // wireframe: true,
        // outline: true,
        // unit: "meters",
        // getElevation: d=> d.properties.height?d.properties.height:0,
        getElevation: (d) =>
          (geojson.scale ? geojson.scale : 1) *
          (geojson.selectedAttribute
            ? d.properties[geojson.selectedAttribute]
            : d.properties && d.properties.height
            ? d.properties.height
            : geojson.height
            ? geojson.height
            : 0),
        getFillColor: (d) =>
          d.properties.color
            ? ColorConverter.hex.rgb(d.properties.color)
            : fillColor,
        getLineColor: (d) =>
          d.properties.color
            ? ColorConverter.hex.rgb(d.properties.color)
            : strokeColor,
        getLineWidth: geojson.strokeWidth ? geojson.strokeWidth : 2,
        lineWidthMinPixels: 0.7,
        getRadius: 2,
        pickable: true,
        onHover: (info) => this.setTooltip(info.object, info.x, info.y, info),
      });

      let bb: any = bbox(data);
      this.map.addLayer(bulkLayer2);
      this.map.fitBounds(bb);
      this.AiMapService.isSpinner.next(false);
    }
  }

  // ******************************************* Query Layer  ************************************************************************************
  // ******************************************* Query Layer  ************************************************************************************
  // ******************************************* Query Layer  ************************************************************************************

  removeQueryLayer(i) {
    if (this.map.getLayer("query-layer-" + i))
      this.map.removeLayer("query-layer-" + i);
  }

  async addQueryLayer(geojson, i, popupDiv) {
    this.AiMapService.isSpinner.next(true);
    if (this.map.getLayer("query-layer-" + i))
      this.map.removeLayer("query-layer-" + i);
    let bulkLayer2 = new MapboxLayer({
      id: "query-layer-" + i,
      type: GeoJsonLayer,
      data: geojson,
      pointRadiusMinPixels: 2,
      sizeUnits: "meters",
      opacity: 0.8,
      stroked: true,
      filled: true,
      getFillColor: (d) =>
        d.properties.fillColor
          ? hexTorgbCovert(d.properties.fillColor)
          : [115, 199, 116, 200],
      getLineColor: [2, 2, 2],
      getLineWidth: 2,
      lineWidthMinPixels: 1.5,
      getRadius: 50,
      pickable: true,
      onHover: (info) => this.setTooltip(info.object, info.x, info.y, info),
    });

    let hexTorgbCovert = (color) => {
      return ColorConverter.hex.rgb(color.split("#")[1]);
    };

    if (geojson.features.length > 0) {
      let bb: any = bbox(geojson);
      this.map.fitBounds(bb);
    }
    this.map.addLayer(bulkLayer2);
    this.AiMapService.isSpinner.next(false);
  }

  async tableaddQueryLayer(geojson, i, popupDiv) {
    this.AiMapService.isSpinner.next(true);
    if (this.map.getLayer("query-layer-" + i))
      this.map.removeLayer("query-layer-" + i);
    let bulkLayer2 = new MapboxLayer({
      id: "query-layer-" + i,
      type: GeoJsonLayer,
      data: geojson,
      pointRadiusMinPixels: 2,
      sizeUnits: "meters",
      opacity: 0.8,
      stroked: true,
      filled: true,
      getFillColor: (d) =>
        d.properties.fillColor
          ? hexTorgbCovert(d.properties.fillColor)
          : [255, 255, 255, 0],
      getLineColor: [2, 2, 2],
      getLineWidth: 2,
      lineWidthMinPixels: 1.5,
      getRadius: 50,
      pickable: true,
      onHover: (info) => this.setTooltip(info.object, info.x, info.y, info),
    });

    let hexTorgbCovert = (color) => {
      return ColorConverter.hex.rgb(color.split("#")[1]);
    };

    if (geojson.features.length > 0) {
      let bb: any = bbox(geojson);
      this.map.fitBounds(bb);
    }
    this.map.addLayer(bulkLayer2);
    this.AiMapService.isSpinner.next(false);
  }

  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // *******************************************************************************************************************************

  // ***************************************** POI FUNCTION / FEATURES**************************************************************************************
  // ***************************************** POI FUNCTION / FEATURES**************************************************************************************
  // ***************************************** POI FUNCTION / FEATURES**************************************************************************************

  poi() {
    let onPoiClick = (e) => {
      this.PoiService.poiClickListner.next(e);
    };
    this.analysisModeSub = this.AiMapService.analysisMode.subscribe((val) => {
      this.analysisMode = val;

      if (!this.analysisMode) {
        if (this.PoiService.analysisLayer)
          this.PoiService.analysisLayer.clearLayers();
        this.map.off("dblclick", onPoiClick);
      } else if (this.analysisMode) {
        this.map.on("dblclick", onPoiClick);
      }
    });
  }

  displayPoi(data) {
    let poiGson: any = {
      type: "FeatureCollection",
      features: [],
    };

    this.overpassData = {
      college: [],
      cafe: [],
      atm: [],
      bank: [],
      bar: [],
      school: [],
      bus_station: [],
      taxi: [],
      fuel: [],
      pharmacy: [],
      hospital: [],
      clinic: [],
      childcare: [],
      police: [],
      parking: [],
    };

    data.map((val) => {
      const color = this.getColor(val.tags.amenity);
      let pro = val.tags;
      pro["fillColor"] = color;
      if (this.overpassData[val.tags.amenity]) {
        this.overpassData[val.tags.amenity].push(val);
        poiGson.features.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [val.lon, val.lat],
          },
          properties: pro,
        });
      }
    });

    this.poiDataPrepared.next(poiGson);
    this.AiMapService.isSpinner.next(false);
    this.chartDisplay();
  }

  chartDisplay() {
    // Plot Bar Chart
    var graphData = [
      {
        x: [
          "College",
          "Cafe",
          "ATM",
          "Bank",
          "Bar",
          "School",
          "Bus Stop",
          "Taxi",
          "Fuel",
          "Pharmacy",
          "Hospital",
          "Clinic",
          "Childcare",
          "Police",
          "Parking",
        ],
        y: [
          this.overpassData.college.length,
          this.overpassData.cafe.length,
          this.overpassData.atm.length,
          this.overpassData.bank.length,
          this.overpassData.bar.length,
          this.overpassData.school.length,
          this.overpassData.bus_station.length,
          this.overpassData.taxi.length,
          this.overpassData.fuel.length,
          this.overpassData.pharmacy.length,
          this.overpassData.hospital.length,
          this.overpassData.clinic.length,
          this.overpassData.childcare.length,
          this.overpassData.police.length,
          this.overpassData.parking.length,
        ],
        type: "bar",
        width: [
          0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5,
          0.5,
        ],
        marker: {
          color: [
            "#003f5c",
            "#2f4b7c",
            "#665191",
            "#a05195",
            "#d45087",
            "#f95d6a",
            "#ff7c43",
            "#ffa600",
            "#2EBD54",
            "#102fa8",
            "#086d89",
            "#9dc10b",
            "#490582",
            "#1e1310",
            "#A10035",
          ],
        },
      },
    ];
    const layout2 = { width: 310, margin: { l: 0, r: 0 } };

    this.diplayPoiChart.next({
      data: graphData,
      layout: layout2,
    });
  }

  addPoiLayer(poiGson, index?: any) {
    this.map.addSource("poidata" + index, {
      type: "geojson",
      data: poiGson,
    });

    // if(index)
    this.map.addLayer({
      id: "poi-layer-" + index,
      type: "circle",
      source: "poidata" + index,
      paint: {
        "circle-color": ["get", "fillColor"],
      },
    });

    let map = this.map;
    this.map.on("click", "poi-layer-" + index, function (e) {
      const coordinate = e.lngLat;
      const content = e.features[0].properties["amenity"];
      new mapboxgl.Popup().setLngLat(coordinate).setHTML(content).addTo(map);
    });
    let bb: any = bbox(poiGson);

    this.map.fitBounds(bb);
  }

  poiAnalysis(value, query) {
    this.AiMapService.isSpinner.next(true);

    let plotChart = (data) => this.displayPoi(data);

    let aminities = "";
    for (let i = 0; i < query.aminities.length; i++) {
      if (query.aminities[i].checked)
        aminities += query.aminities[i].name + "|";
    }

    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        plotChart(JSON.parse(this.response).elements);
      }
    };
    xhttp.open(
      "GET",
      "https://www.overpass-api.de/api/interpreter?" +
        "data=[out:json][timeout:120];" +
        "node(around:" +
        query.radius +
        "," +
        value.lngLat.lat +
        "," +
        value.lngLat.lng +
        ')["amenity"~"' +
        aminities.substr(0, aminities.length - 1) +
        '"];' +
        "out;",
      true
    );
    xhttp.send();
  }

  getColor(amenity) {
    switch (amenity) {
      case "college":
        return "#003f5c";
      case "school":
        return "#f95d6a";
      case "cafe":
        return "#2f4b7c";
      case "fuel":
        return "#2EBD54";
      case "atm":
        return "#665191";
      case "bank":
        return "#a05195";
      case "bar":
        return "#d45087";
      case "taxi":
        return "#ffa600";
      case "bus_station":
        return "#ff7c43";
      case "pharmacy":
        return "#102fa8";
      case "hospital":
        return "#086d89";
      case "clinic":
        return "#9dc10b";
      case "childcare":
        return "#490582";
      case "police":
        return "#1e1310";
      case "parking":
        return "#A10035";
      default:
        return "000000";
    }
  }

  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // *******************************************************************************************************************************

  // ****************************************** ISOCHRONE FEATURE *************************************************************************************
  // ****************************************** ISOCHRONE FEATURE *************************************************************************************
  // ****************************************** ISOCHRONE FEATURE *************************************************************************************

  isochrone() {
    let onIsoClick = (e) => {
      this.AiMapService.createIsochrone.next(e);
    };
    this.isochroneModeSub = this.AiMapService.isochroneListner.subscribe(
      (val) => {
        this.isochroneMode = val;
        if (this.isochroneMode) this.map.on("click", onIsoClick);
        else this.map.off("click", onIsoClick);
      }
    );

    this.recivedIsochroneDataSub =
      this.AiMapService.recivedIsochroneData.subscribe((data) => {
        // if(this.map.getLayer('isochrone')) this.map.removeLayer('isochrone');

        let IsochroneData: any = {
          type: "FeatureCollection",
          features: [],
        };

        data.features.map((p) => {
          let fillColor;
          switch (p.properties.value) {
            case 300:
              fillColor = "#e4ce0f";
              break;
            case 600:
              fillColor = "#f86a6a";
              break;
            case 900:
              fillColor = "#6cffa9";
              break;
            case 1200:
              fillColor = "#3766e7";
              break;
          }
          p.properties["height"] = (1 / +p.properties.value) * 150000;
          p.properties["fillColor"] = fillColor;
          IsochroneData.features.push(p);
        });
        this.isochronedataPreapred.next(IsochroneData);
        // this.addIsochronedata(IsochroneData);
      });
  }

  addIsochroneLayer(d, i?: any) {
    this.map.addSource("isochrone" + i, {
      type: "geojson",
      data: d,
    });
    this.map.addLayer({
      id: "isochrone-layer-" + i,
      type: "fill-extrusion",
      source: "isochrone" + i,
      layout: {},
      paint: {
        "fill-extrusion-height": [
          "interpolate",
          ["linear"],
          ["zoom"],
          5,
          10,
          15,
          ["get", "height"],
        ],
        "fill-extrusion-opacity": 0.6,
        "fill-extrusion-color": ["get", "fillColor"],
      },
    });

    let map = this.map;
    this.map.on("click", "isochrone-layer-" + i, function (e) {
      const coordinate = e.lngLat;
      const content =
        "Duration : " + e.features[0].properties["value"] / 60 + " minute";
      new mapboxgl.Popup().setLngLat(coordinate).setHTML(content).addTo(map);
    });
  }

  // ******************************************** SEARCH ON MAP AND DISPLAY***********************************************************************************
  // ******************************************** SEARCH ON MAP AND DISPLAY***********************************************************************************
  // ******************************************** SEARCH ON MAP AND DISPLAY***********************************************************************************

  searchOnMap() {
    this.searchOnMapDataSub = this.AiMapService.searchOnMapData.subscribe(
      (geometry) => {
        if (this.map.getLayer("places")) {
          this.map.removeLayer("places");
          this.map.removeSource("places");
        }
        let result;
        this.map.addSource("places", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [{ type: "Feature", geometry: geometry, properties: {} }],
          },
        });
        if (geometry.type == "Point") {
          this.map.addLayer({
            id: "places",
            type: "circle",
            source: "places",

            paint: {
              "circle-color": "#ff2233",
              // "fill-opacity": 0.6
              "circle-opacity": 0.6,
            },
          });
        } else if (geometry.type == "Polygon") {
          this.map.addLayer({
            id: "places",
            type: "fill",
            source: "places",
            paint: {
              "fill-color": "#ff2233",
              "fill-opacity": 0.6,
            },
          });
        }
        const [a, b, c, d] = bbox({
          type: "FeatureCollection",
          features: [{ type: "Feature", geometry: geometry, properties: {} }],
        });
        this.map.fitBounds([a, b, c, d]);
      }
    );
  }

  displayPair(p, i) {
    if (this.map.getLayer("processing-layer-" + i))
      this.map.removeLayer("processing-layer-" + i);
    let arcLayer = new MapboxLayer({
      id: "processing-layer-" + i,
      filter: ["==", "extrude", "true"],
      type: ArcLayer,
      data: p.data,
      getWidth: 5,
      getSourcePosition: (d) => [d["longitude_1"], d["latitude_1"]],
      getTargetPosition: (d) => [d["longitude_2"], d["latitude_2"]],
      getSourceColor: (d) => [250, 140, 0],
      getTargetColor: (d) => [0, 140, 250],
      // onHover: (info) => this.setTooltip(info.object, info.x, info.y),
    });

    this.map.addLayer(arcLayer);
  }

  layerChanged(layer: addlayersinfo) {
    this.currentSelectedLayer = layer;
  }

  sublayerChanged(layer: addSublayersinfo) {
    this.currentSelectedLayer = layer;
  }

  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // *******************************************************************************************************************************

  // *****************************************************  ZOOM TO LAYER / SUBLAYER**************************************************************************
  // *****************************************************  ZOOM TO LAYER / SUBLAYER**************************************************************************
  // *****************************************************  ZOOM TO LAYER / SUBLAYER**************************************************************************

  zoomToLayer(node) {
    if (this.dataSources.features.length > 0) {
      const bounds = new mapboxgl.LngLatBounds();
      let features = [];
      if (node.type == "layers") {
        features = this.dataSources.features.filter((feat) =>
          feat.properties.url
            ? feat.properties.layer == node.name &&
              feat.properties.url?.length < 5
            : feat.properties.layer == node.name
        );
        console.log(features);
      } else {
        this.dataSources.features.map((f) => {
          if (
            f.properties.layer == node.layer &&
            f.properties.sublayer == node.name &&
            !f.properties.url
          ) {
            features.push(f);
          }
        });
      }
      if (features.length > 0) {
        features.map((feat) => {
          if (feat.geometry.type == "Point") {
            bounds.extend((feat.geometry as any).coordinates);
          } else {
            if ((feat.geometry as any).coordinates)
              (feat.geometry as any).coordinates.flat(1).forEach((coord) => {
                // coord[0].map((cordPosition) => bounds.extend(cordPosition));
                bounds.extend(coord);
              });
          }
        });
        this.map.fitBounds(bounds);
      }
      // }
    }
  }

  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // *******************************************************************************************************************************

  // ********************************************** ADD DRAWN FEATURES *********************************************************************************
  // ********************************************** ADD DRAWN FEATURES *********************************************************************************
  // ********************************************** ADD DRAWN FEATURES *********************************************************************************

  addDrawnFeature(features: Array<Feature>) {
    console.log(features);

    if (this.currentSelectedLayer) {
      const feature = features[0];
      console.log(features[0]);

      const dialogdata: infoWindow = {
        name: "",
        description: "",
        type: feature.geometry.type,
      };
      const dialogRef = this.dialog.open(InfoWindowGSComponent, {
        width: "250px",
        data: dialogdata,
      });
      dialogRef.afterClosed().subscribe((data) => {
        console.log(data);

        if (data) {
          let featureType = feature.geometry.type;
          switch (featureType) {
            case "LineString":
              const lsresult = length(feature);
              const valperimeterls =
                lsresult < 1
                  ? (lsresult * 1000).toFixed(0) + " m"
                  : lsresult.toFixed(3) + " km";
              feature.properties.length = valperimeterls;
              break;
            case "Polygon":
              const valarea =
                area(feature) / 10e6 < 1
                  ? area(feature).toFixed(0) + " sq. m"
                  : (area(feature) / 10e6).toFixed(3) + " sq. km";
              feature.properties.area = valarea;
              const pyresult = length(feature);
              const valperimeter =
                pyresult < 1
                  ? (pyresult * 1000).toFixed(0) + " m"
                  : pyresult.toFixed(3) + " km";
              feature.properties.perimeter = valperimeter;
              // feature.setProperty('perimeter', parseInt((result*1000).toPrecision(4)))
              feature.properties.center =
                centroid(feature).geometry.coordinates;
              break;
            case "Point":
              const iconIndex = this.currentSelectedLayer.icon;
              feature.properties.icon = iconIndex;
              feature.properties.iconUrl = featureIcons[+iconIndex].icon;

              break;
            default:
              break;
          }
          feature.properties.name = data.name;
          feature.properties.title = data.name;

          feature.properties.description = data.description;

          if (this.currentSelectedLayer.layer) {
            feature.properties.layer = this.currentSelectedLayer.layer;
            feature.properties.sublayer = this.currentSelectedLayer.name;
          } else {
            feature.properties.layer = this.currentSelectedLayer.name;
          }
          feature.properties.colorClass = this.currentSelectedLayer.colorClass;

          feature.properties.category = "data";

          // // feature.setProperty('icon', parseInt(result.point))
          // // feature.setProperty('id', uuid.v4())

          // When Image is also uploaded
          let formData = new FormData();
          if (data.image) {
            formData.append("img", data.image, data.image.name);
            this.mapService.uploadIMG(formData).subscribe((res) => {
              console.log(res);

              feature.properties.image = res.imgurl;
              this.dataSources.features.push(feature as any);
              this.layerSources[feature.properties.layer].features.push(
                feature as any
              );
              this.addFeature(feature);
              this.layerTree = [];
              this.dataSources.features.forEach((feature) => {
                let similarLayerIndex = this.layerTree.findIndex((val) => {
                  if (this.layerTree.length > 0) {
                    return val.name === feature.properties.layer;
                  } else {
                    return false;
                  }
                });

                if (similarLayerIndex !== -1) {
                  this.layerTree[similarLayerIndex].children.push({
                    name: feature.properties.name,
                    id: feature.id,
                    type:
                      feature.geometry.type !== "Point"
                        ? feature.geometry.type === "LineString"
                          ? "timeline"
                          : "texture"
                        : "place",
                  });
                } else {
                  this.layerTree.push({
                    name: feature.properties.layer,
                    colorClass: feature.properties.colorClass,
                    type: "layers",
                    icon: feature.properties.icon,
                    // url: feature.properties.url,
                    visible: false,
                    children: [
                      {
                        name: feature.properties.name,
                        id: feature.id,
                        type:
                          feature.geometry.type !== "Point"
                            ? feature.geometry.type === "LineString"
                              ? "timeline"
                              : "texture"
                            : "place",
                      },
                    ],
                  });
                }
              });

              const source = this.map.getSource(feature.properties.layer);
              console.log(source);

              (source as any).setData(source);

              this.updateLayerTree(this.dataSources);

              // this.draw.trash();
            });
          } else {
            // No Image uploaded
            // if(!this.dataSources) this.dataSources =
            // if(!this.dataSources) this.dataSources =
            this.dataSources.features.push(feature as any);

            this.layerSources[feature.properties.layer].features.push(
              feature as any
            );
            console.log(feature);

            this.addFeature(feature);
            // const source = this.map.getSource(feature.properties.layer);
            // (source as any).setData(source);
            this.updateLayerTree(this.dataSources);
            // this.draw.trash();
          }
        } else {
          this.draw.trash();
        }
      });
    } else {
      setTimeout(() => {
        this.snackbar.open("Select a layer before digitizing", "OK");
      }, 1000);
    }
  }

  // Data Layer
  dataLayerVisibility(bool) {
    this.layerTree.forEach((layer) => {
      this.toggleLayerVisibility(layer.name, bool);

      if (bool) {
        this.zoomToLayer(layer);

        layer.visible = true;
      } else layer.visible = false;
    });

    this.gotProject.next({
      layerTree: this.layerTree,
      id: this.currentSelectedLayer ? this.currentSelectedLayer.id : "0",
    });
  }

  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // *******************************************************************************************************************************

  // ******************************************** VISIBILITY CONTROL FUNCTION ***********************************************************************************
  // ******************************************** VISIBILITY CONTROL FUNCTION ***********************************************************************************
  // ******************************************** VISIBILITY CONTROL FUNCTION ***********************************************************************************

  toggleLayerVisibility(name: string, visible: boolean) {
    try {
      this.map.setLayoutProperty(
        `${name}-Point`,
        "visibility",
        visible ? "visible" : "none"
      );
    } catch (error) {
      console.log(error);
    }
    try {
      this.map.setLayoutProperty(
        `${name}-Polygon`,
        "visibility",
        visible ? "visible" : "none"
      );
    } catch (error) {
      console.log(error);
    }
    try {
      this.map.setLayoutProperty(
        `${name}-LineString`,
        "visibility",
        visible ? "visible" : "none"
      );
    } catch (error) {
      console.log(error);
    }

    this.layerTree.map((t) => {
      if (t.name == name) {
        t.children.map((c) => {
          if (c.sublayer !== undefined) {
            this.toggleLayerVisibilitySub({
              layer: c.layer,
              name: c.sublayer,
              visible: visible,
            });
          }
        });
      }
    });
  }

  toggleLayerVisibilitySub(node) {
    console.log(node);

    try {
      this.map.setLayoutProperty(
        `${node.layer}-${node.name}-Point`,
        "visibility",
        node.visible ? "visible" : "none"
      );
    } catch (error) {
      console.log(error);
    }
    try {
      this.map.setLayoutProperty(
        `${node.layer}-${node.name}-Polygon`,
        "visibility",
        node.visible ? "visible" : "none"
      );
    } catch (error) {
      console.log(error);
    }
    try {
      this.map.setLayoutProperty(
        `${node.layer}-${node.name}-LineString`,
        "visibility",
        node.visible ? "visible" : "none"
      );
    } catch (error) {
      console.log(error);
    }
    // if (!node.visible) {
    //   // this.toggleLayerVisibility(node.layer,false);
    //   const name = node.layer;
    //   const visible = false;
    //   try {
    //     this.map.setLayoutProperty(
    //       `${name}-Point`,
    //       "visibility",
    //       visible ? "visible" : "none"
    //     );
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   try {
    //     this.map.setLayoutProperty(
    //       `${name}-Polygon`,
    //       "visibility",
    //       visible ? "visible" : "none"
    //     );
    //   } catch (error) {
    //     console.log(error);
    //   }
    //   try {
    //     this.map.setLayoutProperty(
    //       `${name}-LineString`,
    //       "visibility",
    //       visible ? "visible" : "none"
    //     );
    //   } catch (error) {
    //     console.log(error);
    //   }
    // }
  }

  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // *******************************************************************************************************************************

  // *************************************************** DELETE LAYER / SUBLAYER ****************************************************************************
  // *************************************************** DELETE LAYER / SUBLAYER ****************************************************************************
  // *************************************************** DELETE LAYER / SUBLAYER ****************************************************************************

  deleteLayer(layer: addlayersinfo) {
    if (layer.level == 0) {
      try {
        this.map.removeLayer(`${layer.name}-Point`);
      } catch (error) {
        console.log(error);
      }
      try {
        this.map.removeLayer(`${layer.name}-Polygon`);
      } catch (error) {
        console.log(error);
      }
      try {
        this.map.removeLayer(`${layer.name}-LineString`);
      } catch (error) {
        console.log(error);
      }

      this.layerTree.map((t) => {
        if (t.name == layer.name) {
          t.children.map((c) => {
            if (c.sublayer) {
              this.removeSubLayer({ layer: layer.name, sublayer: c.sublayer });
            }
          });
        }
      });

      this.dataSources.features = this.dataSources.features.filter(
        (val) => val.properties.layer !== layer.name
      );
      const source = this.map.getSource(layer.name);
      (source as any).setData(this.dataSources);
      this.updateLayerTree(this.dataSources);
      this.deleteMLayer(layer.name, "LAYERTRUE");
    } else if (layer.level == 1) {
      if (layer.type == "list") {
        this.removeSubLayer({ layer: layer.layer, sublayer: layer.name });
        let p = { ...this.dataSources };
        p.features = [];
        this.dataSources.features.map((f, i) => {
          if (
            f.properties.layer == layer.layer &&
            f.properties.sublayer !== layer.name
          ) {
            p.features.push(f);
          } else if (f.properties.layer !== layer.layer) {
            p.features.push(f);
          }
        });
        this.dataSources = p;
        const source = this.map.getSource(layer.layer);
        (source as any).setData(this.dataSources);
        this.updateLayerTree(this.dataSources);
        this.deleteMLayer(layer.name, layer.layer);
      } else {
        this.dataSources.features = this.dataSources.features.filter(
          (val) => val.id !== layer.id
        );
        const source = this.map.getSource(layer.layer);
        (source as any).setData(this.dataSources);
        this.updateLayerTree(this.dataSources);
        this.deleteMLayer(layer.id, "IDTRUE");
      }
    }
  }

  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // ******************************************************************************************************************************

  // ********************************************** REMOVE LAYER / SUBLAYER*********************************************************************************
  // ********************************************** REMOVE LAYER / SUBLAYER*********************************************************************************
  // ********************************************** REMOVE LAYER / SUBLAYER*********************************************************************************

  removeSubLayer(info) {
    if (this.map.getLayer(`${info.layer}-${info.sublayer}-Point`))
      this.map.removeLayer(`${info.layer}-${info.sublayer}-Point`);
    if (this.map.getLayer(`${info.layer}-${info.sublayer}-Polygon`))
      this.map.removeLayer(`${info.layer}-${info.sublayer}-Polygon`);
    if (this.map.getLayer(`${info.layer}-${info.sublayer}-LineString`))
      this.map.removeLayer(`${info.layer}-${info.sublayer}-LineString`);
  }

  // *****************************************CREATE LAYER / SUBLAYERS FUNCTION**************************************************************************************
  // *****************************************CREATE LAYER / SUBLAYERS FUNCTION**************************************************************************************
  // *****************************************CREATE LAYER / SUBLAYERS FUNCTION**************************************************************************************

  createLayer(layer: addlayersinfo) {
    this.layerTree.push({
      name: layer.name,
      colorClass: layer.colorClass,
      new: true,
      type: "layers",
      icon: layer.icon,
      visible: true,
      children: [],
    });

    this.layerTree = this.layerTree.map((val, index) => {
      return {
        ...val,
        id: JSON.stringify(index),
      };
    });
    this.updateLayerTree(this.dataSources);
    this.setLayer(layer);
  }

  createSubLayer(layer: addSublayersinfo) {
    this.layerTree.map((t) => {
      if (t.name == layer.layer) {
        t.children.push({
          name: layer.name,
          colorClass: layer.colorClass,
          new: true,
          type: "list",
          layer: layer.layer,
          icon: layer.icon,
          visible: t.visible ? true : false,
          children: [],
        });
        console.log(layer);
        // this.layerTree = this.layerTree.map((val, index) => {
        //   return {
        //     ...val,
        //     id: JSON.stringify(index)
        //   }
        // })
        // this.updateLayerTree(this.dataSources);
        // this.setSubLayer(layer);
      }
    });

    const fet = {
      id: uuid(),
      type: "Feature",
      properties: {
        name: "Geojson",
        sublayer: layer.name,
        layer: layer.layer,
        url: layer.url,
        icon: layer.icon,
        iconUrl: featureIcons[+layer.icon].icon,
        colorClass: layer.colorClass,
        category: "url",
      },
      geometry: {},
    } as any;

    this.dataSources.features.push(fet);
    //  this.projectData.data.features.push(fet)
    this.addFeature(fet);

    this.layerTree = this.layerTree.map((val, index) => {
      val.children.map((c, i) => {
        return {
          ...c,
          id: JSON.stringify(i),
        };
      });
      return {
        ...val,
        id: JSON.stringify(index),
      };
    });
    this.updateLayerTree(this.dataSources);

    setTimeout(() => {
      this.setSubLayer({
        name: layer.name,
        colorClass: layer.colorClass,
        icon: layer.icon,
        type: "list",
        sublayer: layer.name,
        layer: layer.layer,
        visible: true,
        children: [
          {
            name: "Geojson",
            type: "upload",
          },
        ],
      });
    }, 500);
  }

  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // *******************************************************************************************************************************

  // *************************************SET LAYER/SUBLAYERS FUNCTION******************************************************************************************
  // *************************************SET LAYER/SUBLAYERS FUNCTION******************************************************************************************
  // *************************************SET LAYER/SUBLAYERS FUNCTION******************************************************************************************

  setLayer(layer: addlayersinfo) {
    setTimeout(() => {
      if (this.dataSources) {
        let fet = [];
        this.dataSources.features.map((f) => {
          if (!f.properties.sublayer && f.properties.layer == layer.name) {
            fet.push(f);
          }
        });
        const source: dataGeoJSONMBx = {
          type: "FeatureCollection",
          features: fet,
        };
        this.map.addSource(layer.name, {
          type: "geojson",
          data: source,
        });
        this.layerSources[layer.name] = source;
        this.map.addLayer({
          id: `${layer.name}-Point`,
          source: layer.name,
          type: "symbol",
          layout: {
            "icon-image": [
              "case",
              ["has", "iconUrl"],
              ["get", "iconUrl"],
              "marker-15",
            ],
            "icon-size": 2.5,
            // 'text-field': ,
            // 'text-offset': [0, 1.25],
            // 'text-anchor': 'top'
          },
          // paint: {
          //   "circle-color": layer.colorClass,
          // },
          // filter: ['case',['==',`${layer.name}` ,['get', 'layer']], ['==', '$type', 'Point'], false],
          filter: ["==", "$type", "Point"],
          // filter: ['all',['==', '$type', 'Point'],['==',`${layer.name}` ,['get', 'layer']]]
        });
        this.map.addLayer({
          id: `${layer.name}-LineString`,
          source: layer.name,
          type: "line",
          paint: {
            "line-color": [
              "case",
              ["has", "colorClass"],
              ["get", "colorClass"],
              "blue",
            ],
            // "line-width": ['case',['get', "stroke-width"], 1],
          },
          // filter: ['case',['==',`${layer.name}` ,['get', 'layer']],['==', '$type', 'LineString'], false],
          filter: ["==", "$type", "LineString"],

          // filter: ['all',['==', '$type', 'LineString'],['==',`${layer.name}` ,['get', 'layer']]]
        });
        this.map.addLayer({
          id: `${layer.name}-Polygon`,
          source: layer.name,
          type: "fill",
          paint: {
            "fill-color": [
              "case",
              ["has", "colorClass"],
              ["get", "colorClass"],
              "blue",
            ],
            // "fill-outline-color": ['case', ['has', 'fill'], ['get', 'fill'], ['get', 'colorClass'], 'blue'],
            "fill-opacity": [
              "case",
              ["has", "fill-opacity"],
              [
                "case",
                ["==", ["get", "fill-opacity"], 0],
                0.2,
                ["get", "fill-opacity"],
              ],
              0.3,
            ],
          },
          // filter: ['case',['==',`${layer.name}` ,['get', 'layer']], ['==', '$type', 'Polygon'], false],
          filter: ["==", "$type", "Polygon"],
          // filter: ['all',['==', '$type', 'Polygon'],['==',`${layer.name}` ,['get', 'layer']]]
        });
        this.addPopupListener(`${layer.name}-Point`);
        this.addPopupListener(`${layer.name}-LineString`);
        this.addPopupListener(`${layer.name}-Polygon`);
        this.currentSelectedLayer = layer;
        this.map.on("click", this.currentSelectedLayer.name, (e) => {});
      }
    }, 500);
  }

  setSubLayer(layer: any) {
    setTimeout(() => {
      let fet = [];
      this.dataSources.features.map((f) => {
        if (
          f.properties.sublayer == layer.sublayer &&
          f.properties.layer == layer.layer
        ) {
          if (f.properties.url) {
            if (f.properties.url.length < 5) fet.push(f);
          } else {
            fet.push(f);
          }
        }
      });

      const source: dataGeoJSONMBx = {
        type: "FeatureCollection",
        features: fet,
      };
      this.map.addSource(`${layer.layer}-${layer.sublayer}`, {
        type: "geojson",
        data: source,
      });
      this.layerSources[layer.layer] = source;

      this.map.addLayer({
        id: `${layer.layer}-${layer.sublayer}-Point`,
        source: `${layer.layer}-${layer.sublayer}`,
        type: "symbol",
        layout: {
          "icon-image": [
            "case",
            ["has", "iconUrl"],
            ["get", "iconUrl"],
            "marker-15",
          ],
          "icon-size": 2.5,
          // 'text-field': ,
          // 'text-offset': [0, 1.25],
          // 'text-anchor': 'top'
        },
        paint: {
          "icon-color": [
            "case",
            ["has", "colorClass"],
            ["get", "colorClass"],
            "blue",
          ],
        },
        // filter: ['case',['==',`${layer.layer}` ,['get', 'layer']], ['==', '$type', 'Point'], false],
        filter: ["==", "$type", "Point"],
        // filter: ['all',['==', '$type', 'Point'],['==',`${layer.layer}` ,['get', 'layer']]]
      });
      this.map.addLayer({
        id: `${layer.layer}-${layer.sublayer}-LineString`,
        source: `${layer.layer}-${layer.sublayer}`,
        type: "line",
        paint: {
          "line-color": [
            "case",
            ["has", "colorClass"],
            ["get", "colorClass"],
            "blue",
          ],
          // "line-width": ['case',['get', "stroke-width"], 1],
        },
        // filter: ['case',['==',`${layer.layer}` ,['get', 'layer']],['==', '$type', 'LineString'], false],
        filter: ["==", "$type", "LineString"],

        // filter: ['all',['==', '$type', 'LineString'],['==',`${layer.layer}` ,['get', 'layer']]]
      });
      this.map.addLayer({
        id: `${layer.layer}-${layer.sublayer}-Polygon`,
        source: `${layer.layer}-${layer.sublayer}`,
        type: "fill",
        paint: {
          "fill-color": [
            "case",
            ["has", "colorClass"],
            ["get", "colorClass"],
            "blue",
          ],
          "fill-outline-color": [
            "case",
            ["has", "colorClass"],
            ["get", "colorClass"],
            "blue",
          ],
          "fill-opacity": [
            "case",
            ["has", "fill-opacity"],
            [
              "case",
              ["==", ["get", "fill-opacity"], 0],
              0.2,
              ["get", "fill-opacity"],
            ],
            0.3,
          ],
        },
        // filter: ['case',['==',`${layer.layer}` ,['get', 'layer']], ['==', '$type', 'Polygon'], false],
        filter: ["==", "$type", "Polygon"],
        // filter: ['all',['==', '$type', 'Polygon'],['==',`${layer.layer}` ,['get', 'layer']]]
      });
      this.addPopupListener(`${layer.layer}-${layer.sublayer}-Point`);
      this.addPopupListener(`${layer.layer}-${layer.sublayer}-LineString`);
      this.addPopupListener(`${layer.layer}-${layer.sublayer}-Polygon`);

      this.currentSelectedLayer = layer;
      this.map.on("click", this.currentSelectedLayer.name, (e) => {});
    }, 500);
  }

  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // *******************************************************************************************************************************

  // ************************************************POP UP LISTNER FUCTION*******************************************************************************
  // ************************************************POP UP LISTNER FUCTION*******************************************************************************
  // ************************************************POP UP LISTNER FUCTION*******************************************************************************

  addPopupListener(layer: string) {
    this.map.on("click", layer, (e) => {
      const feature = e.features[0];
// <<<<<<< HEAD
// =======
      console.log(feature);
// >>>>>>> feature/geoprocessingHeliAi
      if (this.mode != "deleteMode") {
        const geometryType = feature.geometry.type;
        let coordinates: mapboxgl.LngLatLike;
        switch (geometryType) {
          case "Point":
            coordinates = (feature.geometry as any).coordinates.slice();
            break;
          case "LineString":
            coordinates = (feature.geometry as any).coordinates[0].slice();
            break;
          case "Polygon":
          default:
            coordinates = (feature.geometry as any).coordinates[0][0].slice();
        }
        feature.geometry.type == "Point"
          ? (feature.geometry as any).coordinates.slice()
          : (feature.geometry as any).coordinates[0].slice();
        const name = feature.properties.name;
        const description = feature.properties.description;
        const image = feature.properties.image;
        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
          coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }
        let html = image
          ? `
        <div>
          <img style="width: 200px" src="${image}">
          <h4>${name}</h4>
          <h4>Description: ${description ? description : "No Description"}</h4>
        `
          : `
        <div>
          <h4>${name}</h4>
          <h4>Description: ${description ? description : "No Description"}</h4>
        `;
        switch (geometryType) {
          case "Point":
            html += `
            <h4>Position Clicked :</h4>
            <h4>Latitude: ${e.lngLat.lat}</h4>
            <h4>Longitude: ${e.lngLat.lng}</h4>
            </div>
            `;
            break;
          case "LineString":
            let lengths = feature.properties.length;
            html += `
              <h4>Length: ${lengths}</h4>
              <h4>Position Clicked :</h4>
              <h4>Latitude: ${e.lngLat.lat}</h4>
              <h4>Longitude: ${e.lngLat.lng}</h4>
              </div>
              `;
            break;
          case "Polygon":
            let perimeter = feature.properties.perimeter;
            let areas = feature.properties.area;
            html += `
            <h4>Perimeter: ${perimeter}</h4>
            <h4>Area: ${areas}</h4>
            <h4>Position Clicked :</h4>
            <h4>Latitude: ${e.lngLat.lat}</h4>
            <h4>Longitude: ${e.lngLat.lng}</h4>
            </div>
            `;
            break;

          default:
            break;
        }

        if (feature.properties.url) {
          let keys = Object.keys(feature.properties);
          keys.map((key) => {
            if (
              key !== "colorClass" &&
              key !== "icon" &&
              key !== "iconUrl" &&
              key !== "area" &&
              key !== "perimeter" &&
              key !== "layer" &&
              key !== "sublayer"
            ) {
              html += `<h4>${key}:${feature.properties[key]}</h4>`;
            }
          });
        }
        new mapboxgl.Popup()
          .setLngLat(coordinates)
          .setHTML(html)
          .addTo(this.map);
      }
      if (this.mode == "deleteMode") {
        const featIndex = this.dataSources.features.findIndex(
          (feat) => feat.id == feature.id
        );
        if (featIndex > -1) {
          const layerName =
            this.dataSources.features[featIndex].properties.layer;
          const source = this.map.getSource(layerName);
          this.dataSources.features.splice(featIndex, 1);
          (source as any).setData(this.dataSources);
          this.updateLayerTree(this.dataSources);
          this.deleteFeature(feature);
        } else {
          // console.log('Feature not found');
        }
      }
    });

    // Change the cursor to a pointer when the mouse is over the places layer.
    this.map.on("mouseenter", layer, () => {
      this.map.getCanvas().style.cursor = "pointer";
    });

    // Change it back to a pointer when it leaves.
    this.map.on("mouseleave", layer, () => {
      this.map.getCanvas().style.cursor = "";
    });
  }

  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // *******************************************************************************************************************************

  // ***********************************************LAYER TREE UPDATE FUNCTION********************************************************************
  // ***********************************************LAYER TREE UPDATE FUNCTION********************************************************************
  // ***********************************************LAYER TREE UPDATE FUNCTION********************************************************************

  // Update Layer Tree
  updateLayerTree(data: dataGeoJSONMBx) {
    let tr = [];
    this.layerTree.map((t, i) => {
      if (t.new) {
        tr.push(t);
      } else {
        let ntr = { ...t };
        ntr.children = [];
        t.children.map((c, j) => {
          if (c.new) {
            ntr.children.push(c);
          }
        });
        if (ntr.children.length > 0) {
          tr.push(ntr);
        }
      }
    });
    this.layerTree = tr;
    console.log(data);

    if (data.features.length > 0) {
      data.features.forEach((feature) => {
        console.log(feature.properties.url);

        if (feature.properties.url !== "yes") {
          let similarLayerIndex =
            this.layerTree.length > 0
              ? this.layerTree.findIndex((val) => {
                  return val.name == feature.properties.layer;
                })
              : -1;

          // let similarLayerIndexSub;
          const index = feature.properties.icon ? feature.properties.icon : "0";

          // if(similarLayerIndex !== -1)

          if (similarLayerIndex !== -1) {
            this.layerTree[similarLayerIndex].new = false;
            if (feature.properties.sublayer) {
              let similarLayerIndexSub =
                this.layerTree[similarLayerIndex].children.length > 0
                  ? this.layerTree[similarLayerIndex].children.findIndex(
                      (val) => {
                        return val.name == feature.properties.sublayer;
                      }
                    )
                  : -1;
              // const indexSub = feature.properties.icon ? feature.properties.icon : '0';
              if (similarLayerIndexSub !== -1) {
                this.layerTree[similarLayerIndex].children[
                  similarLayerIndexSub
                ].new = false;
                if (feature.properties.url) {
                  this.pushuploadedDataIntoSource(feature);
                  // this.layerTree[similarLayerIndex].children[similarLayerIndexSub].children.push({ name: 'Geojson', type:'upload' })
                } else
                  this.layerTree[similarLayerIndex].children[
                    similarLayerIndexSub
                  ].children.push({
                    name: feature.properties.name,
                    id: feature.id,
                    type:
                      feature.geometry.type !== "Point"
                        ? feature.geometry.type === "LineString"
                          ? "timeline"
                          : "texture"
                        : matIcons[index].mat,
                  });
              } else {
                if (!feature.properties.url)
                  this.layerTree[similarLayerIndex].children.push({
                    name: feature.properties.sublayer,
                    colorClass: feature.properties.colorClass,
                    icon: index,
                    type: "list",
                    sublayer: feature.properties.sublayer,
                    layer: feature.properties.layer,
                    visible: false,
                    children: [
                      {
                        name: feature.properties.name,
                        id: feature.id,
                        layer: feature.properties.layer,
                        type: feature.type,
                        // type: feature.geometry.type !== 'Point' ?
                        //   feature.geometry.type === 'LineString' ? 'timeline' : 'texture'
                        //   : matIcons[index].mat,
                        color: matIcons[index].color,
                      },
                    ],
                  });
                else {
                  this.layerTree[similarLayerIndex].children.push({
                    name: feature.properties.sublayer,
                    colorClass: feature.properties.colorClass,
                    icon: index,
                    type: "list",
                    sublayer: feature.properties.sublayer,
                    layer: feature.properties.layer,
                    visible: false,
                    children: [],
                  });
                  this.pushuploadedDataIntoSource(feature);
                }
              }
            } else {
              if (feature.properties.url) {
                this.pushuploadedDataIntoSource(feature);
                // this.layerTree[similarLayerIndex].children.push({ name: 'Geojson', type: 'upload' });
              } else
                this.layerTree[similarLayerIndex].children.push({
                  name: feature.properties.name,
                  id: feature.id,
                  type:
                    feature.geometry.type !== "Point"
                      ? feature.geometry.type === "LineString"
                        ? "timeline"
                        : "texture"
                      : matIcons[index].mat,
                  layer: feature.properties.layer,
                });

              // this.layerTree[similarLayerIndex].children.push({ name: feature.properties.name, id: feature.id, type: "sublayer" })
            }
          } else {
            if (!feature.properties.url) {
              if (index) {
                if (feature.properties.sublayer) {
                  this.layerTree.push({
                    name: feature.properties.layer,
                    colorClass: feature.properties.colorClass,
                    icon: index,
                    type: "layers",
                    visible: false,
                    children: [
                      {
                        name: feature.properties.sublayer,
                        colorClass: feature.properties.colorClass,
                        icon: index,
                        type: "list",
                        sublayer: feature.properties.sublayer,
                        layer: feature.properties.layer,
                        visible: false,
                        children: [
                          {
                            name: feature.properties.name,
                            id: feature.id,
                            type: feature.type,
                            // type: feature.geometry.type !== 'Point' ?
                            //   feature.geometry.type === 'LineString' ? 'timeline' : 'texture'
                            // : matIcons[index].mat,
                            color: matIcons[index].color,
                            layer: feature.properties.layer,
                          },
                        ],
                      },
                    ],
                  });
                } else {
                  this.layerTree.push({
                    name: feature.properties.layer,
                    colorClass: feature.properties.colorClass,
                    icon: index,
                    type: "layers",
                    visible: false,
                    children: [
                      {
                        name: feature.properties.name,
                        id: feature.id,
                        type: feature.type,
                        // type: feature.geometry.type !== 'Point' ?
                        //   feature.geometry.type === 'LineString' ? 'timeline' : 'texture'
                        //   : matIcons[index].mat,
                        color: matIcons[index].color,
                        layer: feature.properties.layer,
                      },
                    ],
                  });
                }
              } else {
                if (feature.properties.sublayer) {
                  this.layerTree.push({
                    name: feature.properties.layer,
                    colorClass: feature.properties.colorClass,
                    icon: index,
                    type: "layers",
                    visible: false,
                    children: [
                      {
                        name: feature.properties.sublayer,
                        colorClass: feature.properties.colorClass,
                        icon: index,
                        type: "list",
                        sublayer: feature.properties.sublayer,
                        layer: feature.properties.layer,
                        visible: false,
                        children: [
                          {
                            name: feature.properties.name,
                            id: feature.id,
                            type:
                              feature.geometry.type !== "Point"
                                ? feature.geometry.type === "LineString"
                                  ? "timeline"
                                  : "texture"
                                : "place",
                            layer: feature.properties.layer,
                          },
                        ],
                      },
                    ],
                  });
                } else {
                  this.layerTree.push({
                    name: feature.properties.layer,
                    colorClass: feature.properties.colorClass,
                    icon: index,
                    type: "layers",
                    visible: false,
                    children: [
                      {
                        name: feature.properties.name,
                        id: feature.id,
                        type:
                          feature.geometry.type !== "Point"
                            ? feature.geometry.type === "LineString"
                              ? "timeline"
                              : "texture"
                            : "place",
                        layer: feature.properties.layer,
                      },
                    ],
                  });
                }
              }
            } else {
              this.addFeaturesFromUploaded(feature, index);
            }
          }
        }
      });
    }
    this.layerTree = this.layerTree.map((value, index) => {
      value.children.map((v, i) => {
        return {
          ...v,
          id: JSON.stringify(i),
        };
      });
      return {
        ...value,
        id: JSON.stringify(index),
      };
    });
    console.log(this.layerTree);

    this.gotProject.next({
      layerTree: this.layerTree,
      id: this.currentSelectedLayer ? this.currentSelectedLayer.id : "0",
    }); // trigger displaying of data layers
  }

  addFeaturesFromUploaded(feature, index) {
    if (feature.properties.sublayer) {
      this.layerTree.push({
        name: feature.properties.layer,
        colorClass: feature.properties.colorClass,
        icon: index,
        type: "layers",
        visible: false,

        children: [
          {
            name: feature.properties.sublayer,
            colorClass: feature.properties.colorClass,
            icon: index,
            type: "list",
            sublayer: feature.properties.sublayer,
            layer: feature.properties.layer,
            visible: false,
            children: [],
          },
        ],
      });
    } else {
      this.layerTree.push({
        name: feature.properties.layer,
        colorClass: feature.properties.colorClass,
        icon: index,
        type: "layers",
        visible: false,
        children: [],
      });
    }

    this.pushuploadedDataIntoSource(feature);
  }

  async pushuploadedDataIntoSource(fet) {
    console.log(fet);

    const file = await Axios.get(fet.properties.url);
    console.log("file: ", file, "fileurl: ", fet.properties.url);

    file.data.features.map((feature, i) => {
      if (fet.properties.sublayer) {
        feature.properties = {
          ...feature.properties,
          layer: fet.properties.layer,
          sublayer: fet.properties.sublayer,
          url: "yes",
          colorClass: fet.properties.colorClass,
          iconUrl: fet.properties.iconUrl,
        };
      } else {
        feature.properties = {
          ...feature.properties,
          layer: fet.properties.layer,
          url: "yes",
          colorClass: fet.properties.colorClass,
          iconUrl: fet.properties.iconUrl,
        };
      }

      let featureType = feature.geometry.type;
      switch (featureType) {
        case "LineString":
          const lsresult = length(feature);
          const valperimeterls =
            lsresult < 1
              ? (lsresult * 1000).toFixed(0) + " m"
              : lsresult.toFixed(3) + " km";
          feature.properties.length = valperimeterls;
          break;
        case "Polygon":
          const valarea =
            area(feature) / 10e6 < 1
              ? area(feature).toFixed(0) + " sq. m"
              : (area(feature) / 10e6).toFixed(3) + " sq. km";
          feature.properties.area = valarea;
          const pyresult = length(feature);
          const valperimeter =
            pyresult < 1
              ? (pyresult * 1000).toFixed(0) + " m"
              : pyresult.toFixed(3) + " km";
          feature.properties.perimeter = valperimeter;
          feature.properties.center = centroid(feature).geometry.coordinates;
          break;
        case "Point":
          const iconIndex = fet.properties.icon;
          feature.properties.icon = iconIndex;
          feature.properties.iconUrl = featureIcons[+iconIndex].icon;
          break;
        default:
          break;
      }

      this.dataSources.features.push(feature);
    });
  }

  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // *******************************************************************************************************************************

  toggleDeletion(deleteMode: boolean) {
    this.mode = deleteMode ? "deleteMode" : "dataMode";
    this.modeUpdated.next(this.mode);
  }

  // **************************************************SCRIPTING FUNCTION*****************************************************************
  // **************************************************SCRIPTING FUNCTION*****************************************************************
  // **************************************************SCRIPTING FUNCTION*****************************************************************

  removeScriptingData(i, isOnlyVisualize?: boolean) {
    if (isOnlyVisualize) {
      if (this.map.getLayer("script-layer-only-" + i))
        this.map.removeLayer("script-layer-only-" + i);
    } else {
      if (this.map.getLayer("script-layer-" + i))
        this.map.removeLayer("script-layer-" + i);
    }
  }

  displayScriptData(data, el, i?: number, isOnlyVisualize?: boolean) {
    let id;
    if (isOnlyVisualize) {
      if (this.map.getLayer("script-layer-only-" + i))
        this.map.removeLayer("script-layer-only-" + i);
      id = "script-layer-only-" + i;
    } else {
      if (this.map.getLayer("script-layer-" + i))
        this.map.removeLayer("script-layer-" + i);
      id = "script-layer-" + i;
    }

    if (data.type == "sheetdata") {
      let layer, locationkeyss;
      data.query.split(" ").map((d, i) => {
        if (d == "From") layer = data.query.split(" ")[i + 1];
      });

      this.projectData.bulkdata.map((b) => {
        if (b.name == layer) {
          locationkeyss = b.locationkeys;
        }
      });

      let bulkLayer = new MapboxLayer({
        id: id,
        // filter: ['==', 'extrude', 'true'],
        type: ScatterplotLayer,
        data: data.data,
        pickable: true,
        opacity: 0.8,
        stroked: true,
        filled: true,
        // radiusScale: 100,
        radiusMinPixels: 5,
        wrapLongitude: true,
        getPosition: (d) => [
          d[(locationkeyss as any).longitude],
          d[(locationkeyss as any).latitude],
          0,
        ],
        getRadius: 0.05,
        getFillColor: data.fillColor
          ? ColorConverter.keyword.rgb(data.fillColor)
          : [45, 56, 90],
        getLineColor: data.strokeColor
          ? ColorConverter.keyword.rgb(data.strokeColor)
          : [0, 0, 0],
        onHover: (info) => setTooltip(info.object, info.x, info.y),
      });

      let setTooltip = (object, x, y) => {
        if (object) {
          // el.style.backgroundColor = "rgb(0,0,75)";
          el.style.color = "#aaaaaa";

          el.innerHTML = "";
          let keys = Object.keys(object);
          keys.map((e) => {
            el.innerHTML += `${e} :  ${object[e]} <br>`;
          });
          el.style.display = "block";
          el.style.left = x + "px";
          el.style.top = y + "px";
          el.style.padding = "25px";
          el.style.fontSize = "12px";
          el.style.backgroundColor = "#000075";
          el.style.border = "1px solid #000";
        } else {
          el.style.display = "none";
        }
      };

      // this.map.setCenter([data.data[0][longitude], data.data[0][latitude]])
      this.map.addLayer(bulkLayer);
    } else if (data.type == "geojson") {
      let features = { type: "FeatureCollection", features: [] };
      data.data.map((l) => {
        let keys = Object.keys(l);
        let properties = {};
        keys.map((k) => {
          if (k !== "coordinates" && k !== "type") {
            properties[k] = l[k];
          }
        });
        if (l.type == "Point") {
          features.features.push(point(l.coordinates, properties));
        } else if (l.type == "LineString") {
          features.features.push(lineString(l.coordinates, properties));
        } else if (l.type == "Polygon") {
          features.features.push(polygon(l.coordinates, properties));
        } else if (l.type == "MultiPolygon") {
          features.features.push(multiPolygon(l.coordinates, properties));
        }
      });
      let bulkLayer2 = new MapboxLayer({
        id: id,
        // filter: ['==', 'extrude', 'true'],
        type: GeoJsonLayer,
        pointRadiusMinPixels: 5,
        data: features,
        opacity: 0.7,
        stroked: true,
        filled: true,
        extruded: true,
        wireframe: true,
        getElevation: (d) => (d.properties.height ? d.properties.height : 10),
        getFillColor: data.fillColor
          ? ColorConverter.keyword.rgb(data.fillColor)
          : [45, 56, 90],
        getLineColor: data.strokeColor
          ? ColorConverter.keyword.rgb(data.strokeColor)
          : [0, 0, 0],
        getLineWidth: 5,
        getRadius: 5,
        pickable: true,
        onHover: (info) => this.setTooltip(info.object, info.x, info.y, info),
      });

      let bb: any = bbox(features);
      this.map.addLayer(bulkLayer2);
      this.map.fitBounds(bb);
    }

    this.map.on("mouseenter", id, () => {
      this.map.getCanvas().style.cursor = "pointer";
    });
    this.map.on("mouseleave", id, () => {
      this.map.getCanvas().style.cursor = "";
    });
  }

  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // *******************************************************************************************************************************

  // ********************************************************LABLE*****************************************************************
  // ********************************************************LABLE*****************************************************************
  // ********************************************************LABLE*****************************************************************

  AddLabeltoScript(data, i, labelproperty?: string) {
    let textLayer = new MapboxLayer({
      id: "text-layer-script-" + i,
      type: TextLayer,
      data: data.data,
      pickable: true,
      sizeMaxPixels: 20,
      getPosition: (d) => [...getCenter(d), d.height ? d.height + 1 : 0],
      getText: (d) =>
        d[labelproperty].toString() ? d[labelproperty].toString() : "",
      getColor: (d) => [0, 255, 0, 255],
      outlineWidth: 5,
      outlineColor: [0, 0, 255, 255],
      getSize: (d) => 50,
      getAngle: (d) => 90,
      getTextAnchor: "middle",
      getAlignmentBaseline: "center",
    });

    function getCenter(d) {
      let centerC;
      if (d.type == "Point") {
        centerC = center(point(d.coordinates)).geometry.coordinates;
      } else if (d.type == "LineString") {
        centerC = center(lineString(d.coordinates)).geometry.coordinates;
      } else if (d.type == "Polygon") {
        centerC = center(polygon(d.coordinates)).geometry.coordinates;
      } else if (d.type == "MultiPolygon") {
        centerC = center(multiPolygon(d.coordinates)).geometry.coordinates;
      } else {
        centerC = [
          d[data.locationkeys.longitude],
          d[data.locationkeys.latitude],
        ];
      }
      return centerC;
    }

    if (this.map.getLayer("text-layer-script-" + i))
      this.map.removeLayer("text-layer-script-" + i);
    this.map.addLayer(textLayer);
  }

  removelabelScript(i) {
    if (this.map.getLayer("text-layer-script-" + i))
      this.map.removeLayer("text-layer-script-" + i);
  }

  AddLabeltoGeojson(data, i, labelproperty?: string) {
    console.log(i);
    // let textLayer = new MapboxLayer({
    //   id: 'text-layer-geojson-'+i,
    //   type:TextLayer,
    //   data:data.features,
    //   // pickable: true,
    //   sizeUnits: "meters",
    //   sizeMinPixels:5,
    //   // billboard : false,
    //   background: true,
    //   backgroundPadding: [5,5],
    //   lineHeight: 5,
    //   getPosition: d => [...center(d).geometry.coordinates,5],
    //   getText: d => d.properties[labelproperty].toString()?d.properties[labelproperty].toString():"",
    //   // getSize: 32,
    //   getColor:d => [0,0,0,255],
    //   outlineWidth:5,
    //   outlineColor:[0,0,255,255],
    //   getSize:d => 100,
    //   getTextAnchor: 'middle',
    //   getAlignmentBaseline: 'center'
    // })

    if (this.map.getLayer("poi-labels" + i)) {
      this.map.removeLayer("poi-labels" + i);
      this.map.removeSource("places" + i);
    }

    this.map.addSource("places" + i, {
      type: "geojson",
      data: data,
    });

    this.map.addLayer({
      id: "poi-labels" + i,
      type: "symbol",
      source: "places" + i,
      layout: {
        "text-field": ["get", labelproperty],
        "text-variable-anchor": ["top", "bottom", "left", "right"],
        "text-radial-offset": 0.5,
        "text-justify": "auto",
        "text-size": [
          "interpolate",
          ["exponential", 2],
          ["zoom"],
          0,
          8,
          // 15, [ "*", [ "^", [ "-", 15, map.getZoom()], 2], ["get", "font"]],
          // 16, [ "*", [ "^", [ "-", 16, map.getZoom()], 2], ["get", "font"]],
          // 17, [ "*", [ "^", [ "-", 17, map.getZoom()], 2], ["get", "font"]],
          // 18, [ "*", [ "^", [ "-", 18, map.getZoom()], 2], ["get", "font"]],
          // 19, [ "*", [ "^", [ "-", 19, map.getZoom()], 2], ["get", "font"]],
          // 20, [ "*", [ "^", [ "-", 20, map.getZoom()], 2], ["get", "font"]],
          // 21, [ "*", [ "^", [ "-", 21, map.getZoom()], 2], ["get", "font"]],
          // 22, [ "*", [ "^", [ "-", 22, map.getZoom()], 2], ["get", "font"]],
          // 23, [ "*", [ "^", [ "-", 23, map.getZoom()], 2], ["get", "font"]],
          // 24, [ "*", [ "^", [ "-", 24, map.getZoom()], 2], ["get", "font"]],
          // 25, [ "*", [ "^", [ "-", 25, map.getZoom()], 2], ["get", "font"]],
          // 26, [ "*", [ "^", [ "-", 26, map.getZoom()], 2], ["get", "font"]],
          // 27, [ "*", [ "^", [ "-", 27, map.getZoom()], 2], ["get", "font"]],
          // 28, [ "*", [ "^", [ "-", 28, map.getZoom()], 2], ["get", "font"]],
          // 29, [ "*", [ "^", [ "-", 29, map.getZoom()], 2], ["get", "font"]],
          // 30, [ "*", [ "^", [ "-", 30, map.getZoom()], 2], ["get", "font"]],
        ],
      },
    });

    // this.map.addLayer(textLayer);
    this.AiMapService.isSpinner.next(false);
  }

  AddSymboltoGeojson(res, i) {
// <<<<<<< HEAD
// =======
//     console.log(typeof res.data.features[0].properties[res.selected]);

// >>>>>>> feature/geoprocessingHeliAi
    let self = this;
    let textLayer = new MapboxLayer({
      id: "icon-layer-geojson-" + i,
      type: IconLayer,
      data: res.data.features,
      pickable: true,
      sizeUnits: "pixels",
      getPosition: (d) =>
        typeof res.data.features[0].properties[res.selected] === "string"
          ? geticonpos(d)
          : [...center(d).geometry.coordinates, 2],
      getIcon: (d) => ({
        url: self.geoIconsUrl[res.iconIndex],
        width: 128,
        height: 128,
        anchorY: 128,
      }),
      sizeScale: 15,

      getSize: (d) =>
        typeof res.data.features[0].properties[res.selected] === "string"
          ? geticonSize(d)
          : geticonSizeNum(d),
      getTextAnchor: "middle",
      getAlignmentBaseline: "center",
      onHover: (info) => this.setTooltip(info.object, info.x, info.y),
    });

    let geticonSizeNum = (f) => {
      const nr = normalize(f.properties[res.selected], [
        { value: getMinGeo(), norm: 2.0 },
        { value: getMaxGeo(), norm: 3.5 },
      ]);
      return nr;
    };

    let getMaxGeo = () => {
      let max = res.data.features[0].properties[res.selected]
        ? res.data.features[0].properties[res.selected]
        : 0;
      res.data.features.map((data) => {
        if (data.properties[res.selected] > max)
          max = data.properties[res.selected];
      });
      return max;
    };

    let getMinGeo = () => {
      let min = res.data.features[0].properties[res.selected]
        ? res.data.features[0].properties[res.selected]
        : 0;
      res.data.features.map((data) => {
        if (data.properties[res.selected] < min)
          min = data.properties[res.selected];
      });
      return min;
    };

    let geticonSize = (f) => {
      // typeof res.data.features[0].properties[res.selected] === "string"

      let index = res.textValue.findIndex(
        (t) => t == f.properties[res.selected].toLowerCase()
      );
// <<<<<<< HEAD
//       if (index !== -1) {
// =======

      if (index !== -1) {
        // console.log(res.values[index]);
// >>>>>>> feature/geoprocessingHeliAi
        return res.values[index];
      }
    };

    let geticonpos = (f) => {
      let index = res.textValue.findIndex(
        (t) => t == f.properties[res.selected].toLowerCase()
      );
      if (index !== -1) {
// <<<<<<< HEAD
// =======
        // console.log([...center(f).geometry.coordinates, 2]);
// >>>>>>> feature/geoprocessingHeliAi
        return [...center(f).geometry.coordinates, 2];
      }
    };

    if (this.map.getLayer("icon-layer-geojson-" + i))
      this.map.removeLayer("icon-layer-geojson-" + i);
    this.map.addLayer(textLayer);
    this.AiMapService.isSpinner.next(false);
  }

  removelabelGeoJson(i) {
    // if(this.map.getLayer('text-layer-geojson-'+i)) this.map.removeLayer('text-layer-geojson-'+i)
    if (this.map.getLayer("poi-labels" + i)) {
      this.map.removeLayer("poi-labels" + i);
      this.map.removeSource("places" + i);
    }
  }

  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // displayCallData(g){
  //   let layer = new MapboxLayer({
  //     id: 'layerr'+Math.random(),
  //     type: GeoJsonLayer,
  //     data: g,
  //     opacity: 0.8,
  //     stroked: true,
  //     filled: true,
  //     extruded: true,
  //     // wireframe: true,
  //     lineWidthUnits: 'pixels',
  //     pointRadiusUnits: 'pixels' ,
  //     lineWidthScale: 20,
  //     lineWidthMinPixels: 20,
  //     getRadius: 10,
  //     pointRadiusMinPixels:10,
  //     getLineWidth: 3,
  //     pickable: true,

  //   });
  //   let bb:any =  bbox(g.data);
  //   this.map.addLayer(layer);
  //   this.map.fitBounds(bb)

  ///////////////

  //  let popupgeo = document.getElementById('tooltip');
  //  if(this.map.getLayer('data-cleaning-'+i))this.map.removeLayer('data-cleaning-'+i);
  //  let geodata = new MapboxLayer({
  //   id: 'data-cleaning-'+i,
  //   type: GeoJsonLayer,
  //   data: g,
  //   opacity: 1,
  //   stroked:true,
  //   autoHighlight: true,
  //   highlightColor: [100, 128, 255],
  //   filled: true,
  //   extruded: true,
  //   wireframe: true,
  //   getLineColor: d => [250,55,250],
  //   getLineWidth: 3,
  //   pickable: true,
  //   onHover: info => setTooltip2(info, info.x, info.y)
  // })
  // let setTooltip2 = (info, x, y)=>{
  //   if (info.object) {
  //     let object = info.object;
  //     popupgeo.innerHTML = '';
  //     let keys = Object.keys(object.properties);
  //     keys.map(e=>{
  //        popupgeo.innerHTML += `${e} :  ${object.properties[e]} <br>`;
  //     })
  //     popupgeo.style.display = 'block';
  //     popupgeo.style.left = x + 'px';
  //     popupgeo.style.top = y + 'px';
  //     popupgeo.style.padding = "15px";
  //     popupgeo.style.backgroundColor = "#eeeeee";
  //     popupgeo.style.border = "1px solid #000"
  //   } else {
  //     popupgeo.style.display = 'none';
  //   }
  // }
  // let bb:any =  bbox(g);
  // this.map.addLayer(geodata);
  // this.map.fitBounds(bb)
  // }

  // ************************************************** UNSUBSCRIBE *****************************************************************************
  // ************************************************** UNSUBSCRIBE *****************************************************************************
  // ************************************************** UNSUBSCRIBE *****************************************************************************

  unSubSocketListeners() {
    if (this.listenAddFeatureSub) this.listenAddFeatureSub.unsubscribe();
    if (this.listenDeleteFeatureSub) this.listenDeleteFeatureSub.unsubscribe();
    if (this.listenAddMLayerSub) this.listenAddMLayerSub.unsubscribe();
    if (this.listenDeleteMLayerSub) this.listenDeleteMLayerSub.unsubscribe();
    if (this.listenAddALayerSub) this.listenAddALayerSub.unsubscribe();
    if (this.listenDeleteALayerSub) this.listenDeleteALayerSub.unsubscribe();
  }

  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // *******************************************************************************************************************************

  //****************************************************Measurment Automation*******************************************88 */
  lengthLabel(data) {
    if (this.map.getLayer("text-layer-length"))
      this.map.removeLayer("text-layer-length");
    let textLayer = new MapboxLayer({
      id: "text-layer-length",
      type: TextLayer,
      data: data,
      sizeMaxPixels: 20,
      getPosition: (d) => [...d.geometry.coordinates, 4],
      getText: (d) => d.properties.length.toString(),
      getColor: (d) => [255, 255, 255, 255],
      outlineWidth: 5,
      outlineColor: [0, 0, 255, 255],
      getSize: (d) => 30,
      getAngle: (d) => 0,
      getTextAnchor: "middle",
      getAlignmentBaseline: "center",
    });

    this.map.addLayer(textLayer);
  }

  removelengthLabel() {
    if (this.map.getLayer("text-layer-length"))
      this.map.removeLayer("text-layer-length");
  }

  areaLabel(data) {
    if (this.map.getLayer("text-layer-area"))
      this.map.removeLayer("text-layer-area");
    let textLayer = new MapboxLayer({
      id: "text-layer-area",
      type: TextLayer,
      data: data,
      sizeMaxPixels: 20,
      getPosition: (d) => [...d.geometry.coordinates, 4],
      getText: (d) => d.properties.area.toString(),
      getColor: (d) => [255, 255, 255, 255],
      outlineWidth: 5,
      outlineColor: [0, 0, 255, 255],
      getSize: (d) => 30,
      getAngle: (d) => 0,
      getTextAnchor: "middle",
      getAlignmentBaseline: "center",
    });

    this.map.addLayer(textLayer);
  }
  removeareaLabel() {
    if (this.map.getLayer("text-layer-area"))
      this.map.removeLayer("text-layer-area");
  }
  perimeterLabel(data) {
    if (this.map.getLayer("text-layer-perimeter"))
      this.map.removeLayer("text-layer-perimeter");
    let textLayer = new MapboxLayer({
      id: "text-layer-perimeter",
      type: TextLayer,
      data: data,
      sizeMaxPixels: 20,
      getPosition: (d) => [...d.geometry.coordinates, 4],
      getText: (d) => d.properties.perimeter.toString(),
      getColor: (d) => [255, 255, 255, 255],
      outlineWidth: 5,
      outlineColor: [0, 0, 255, 255],
      getSize: (d) => 30,
      getAngle: (d) => 0,
      getTextAnchor: "middle",
      getAlignmentBaseline: "center",
    });

    this.map.addLayer(textLayer);
  }
  removeperimeterLabel() {
    if (this.map.getLayer("text-layer-perimeter"))
      this.map.removeLayer("text-layer-perimeter");
  }

  // **************************************************SOCKET FUCTION*****************************************************************
  // **************************************************SOCKET FUCTION*****************************************************************
  // **************************************************SOCKET FUCTION*****************************************************************

  joinRoom(id: string) {
    this.socket.emit("join", {
      token: this.auth.getToken(),
      id,
    });
  }

  addFeature(feature: any) {
    console.log(feature);

    this.socket.emit("add feature", feature);
  }

  deleteFeature(feature: any) {
    this.socket.emit("delete feature", feature);
  }

  deleteMLayer(layerName: any, mode: string) {
    this.socket.emit("delete M layer", { obj: layerName, mode: mode });
  }

  addMLayer(features: any[]) {
    this.socket.emit("add M layer", features);
  }

  listenAddFeature() {
    return this.socket.fromEvent("add feature");
  }

  listenDeleteFeature() {
    return this.socket.fromEvent("delete feature");
  }

  listenDeleteMLayer() {
    return this.socket.fromEvent<string>("delete M layer");
  }

  listenAddMLayer() {
    return this.socket.fromEvent<any[]>("add M layer");
  }

  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
  // *******************************************************************************************************************************
}
