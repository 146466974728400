import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { element } from 'protractor';

@Component({
  selector: 'app-delete-data-science',
  templateUrl: './delete-data-science.component.html',
  styleUrls: ['./delete-data-science.component.scss']
})
export class DeleteDataScienceComponent implements OnInit {

  userdata:any;
  col;
  dtype;
  nann;
  nullval;
  totalcount;
  head;
  dt;
  stringify:any;
  stringifyy:any;
  stringif:any;
  ng;
  // usersList=[];
constructor(public dialogRef: MatDialogRef<DeleteDataScienceComponent>,
  @Inject(MAT_DIALOG_DATA) public data){} 
ELEMENT_DATA: any[] = this.data
ngOnInit(): void {

  this.userdata= this.data;
  console.log(this.userdata);
  this.head= Object.keys(this.userdata);
  this.col = this.userdata.columns_name;
  this.dtype = Object.values(this.userdata.d_types);
  this.dt = JSON.stringify(this.dtype)
  this.nann = Object.values(this.userdata.nan_value_per_column);
  
  this.ng = JSON.stringify(this.nann)
  this.nullval = Object.values(this.userdata.null_value_per_column);
  this.totalcount = Object.values(this.userdata.total_counts); 
 }

}

