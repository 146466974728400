import { Injectable } from '@angular/core';
import { Dsm } from '../models/dsm.interface';
import { ServerService } from '../server.service';
import { Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
  providedIn: 'root'
})
export class DsmService {
  dsmProjects: Array<{id?: string, item: string, siteid: string, creatorid: string, processing?: boolean}>;
  dsmProjectsChanged: Subject<Array<{id?: string, item: string, siteid: string, creatorid: string, processing?: boolean}>> = new Subject();
  dsmProjectUpdated: Subject<Dsm> = new Subject();
  pseudodsmProjectUpdated: Subject<Dsm> = new Subject();
  triggerMap: Subject<string> = new Subject();
  chartVisible: Subject<boolean> = new Subject();


  constructor(private serverService: ServerService, private snackbar: MatSnackBar, private server:ServerService,) {

  }

  getdsmProject(id: string){
    let dsmProject = this.dsmProjects.find((val)=>{
      return val.id === id
    })
    return dsmProject
  }

  populatedsmProjects(siteid: string){
    this.serverService.getDSMProjects(siteid).subscribe(data=>{
      this.dsmProjects = data
      this.dsmProjectsChanged.next(this.dsmProjects)
    },err=>{
    })
  }

  updateProject(project:Dsm){
    // this.dsmProjectUpdated.next(project);
    this.serverService.updateDSMProjects(project).subscribe(_=>{
    }, err=> {
      console.log(err);
    })
  }

  updateprocessProject(projectData: Dsm, id: string, isupdate?:boolean){
       // this.dsmProjectUpdated.next(project);
       this.serverService.updateDSMProjects(projectData).subscribe(_=>{
      }, err=> {
        console.log(err);
      })
  }


  createdsmProject(dsm: Dsm, mode: 'MESH'|'PTS'){
    this.serverService.createDSMProject(dsm, mode).subscribe(res=>{
      dsm.id = res.id
      this.dsmProjects.push(dsm)
      this.dsmProjectsChanged.next(this.dsmProjects)
    },err=>{
      if(mode == 'PTS'){
        console.log(err);
        this.dsmProjectsChanged.next(this.dsmProjects)
      }
    })
  }


  fetchDSMProject(id){
    this.serverService.fetchDSMProject(id).subscribe(res=>{
      this.dsmProjectUpdated.next(res.project);
    },err=>{
      this.snackbar.open('Cannot fetch', 'OK',{
        duration: 3000
      })
    })
  }

  pseudofetchDSMProject(id){
    this.serverService.fetchDSMProject(id).subscribe(res=>{
      this.pseudodsmProjectUpdated.next(res.project);
    },err=>{
      this.snackbar.open('Cannot fetch', 'OK',{
        duration: 3000
      })
    })
  }

  uploadIMG(data: FormData){
    return this.server.uploadIMG(data)
  }

  shareProject(data: {id: string, userid: string, accessControl: 'canEdit'| 'canView', message: string}){
    this.serverService.shareDSMProject(data).subscribe(res=>{
      this.snackbar.open('Successfully Shared', 'ok', {
        duration: 3000
      });
      this.pseudofetchDSMProject(data.id);
    },error=>{
      this.snackbar.open('Something went wrong', 'ok', {
        duration: 3000
      });
    })
  }

  unShareProject(data: {id: string, userid: string, accessControl: 'canEdit'| 'canView'}){
    this.serverService.unShareDSMProject(data).subscribe(res=>{
      this.snackbar.open('Successfully UnShared', 'ok', {
        duration: 3000
      });
      this.pseudofetchDSMProject(data.id);
    },error=>{
      console.log(error);
      this.snackbar.open('Something went wrong', 'ok', {
        duration: 3000
      });
    })
  }

  updateProjectEdit(projectData: Dsm, id: string){
    console.log(projectData);
    this.server.updateDSMDataToServer(projectData, id).subscribe(_=>{
    },err=>{
      console.log(err);
    })
  }

  /////imagery
  onTriggerMap(option: string) {
    this.triggerMap.next(option)
  }

  parsePythonServerDSM(dsm: Dsm){
    const updatedDsm: Dsm = {
      creatorid: dsm.creatorid,
      item: dsm.item,
      models: this.checkProp(dsm.models),
      siteid: dsm.siteid,
      accessoryLayers: this.checkProp(dsm.accessoryLayers),
      buildingData: this.checkProp(dsm.buildingData),
      canEdit: this.checkProp(dsm.canEdit),
      canView: this.checkProp(dsm.canView),
      crossSections: this.checkProp(dsm.crossSections),
      data: this.checkProp(dsm.data),
      dataSources: this.checkProp(dsm.dataSources),
      id: dsm.id,
      image: dsm.image?dsm.image:null,
      modelPositionZ: dsm.modelPositionZ,
      onMapPosition: this.checkProp(dsm.onMapPosition),
      polygon: this.checkProp(dsm.polygon),
      polyline: this.checkProp(dsm.polyline),
      position: this.checkProp(dsm.position),
      processed: dsm.processed,
      processing: dsm.processing,
      procid: dsm.procid,
      ptsGridData: this.checkProp(dsm.ptsGridData),
      meshGridData: this.checkProp(dsm.meshGridData),
      sGroup: this.checkProp(dsm.sGroup),
      sPanel: this.checkProp(dsm.sPanel),
    }
    return updatedDsm
  }

  checkProp(prop: Object|Array<Object>): any{
    if(prop instanceof Array){
      const pProp = prop && prop.length>0 && Object.keys(prop[0]).length>0?prop:[];
      return pProp
    }else{
      const pProp = prop && Object.keys(prop).length>0?prop:null;
      return pProp
    }
  }
}
