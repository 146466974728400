import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ServerService } from '../../server.service';
import { Review } from '../../models/review.model';
import { AuthService } from '../../auth/auth.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { User } from '../../models/users.model';
import { ReportService } from '../../report.service';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss']
})
export class ReviewsComponent implements OnInit {
  @Input('id') id: string
  commentForm: FormGroup
  reviews: Review[];
  currentuser: User
  constructor(private serverService: ServerService, private authService: AuthService, private snackbar: MatSnackBar) { }

  ngOnInit() {
    this.currentuser = this.authService.getUser()
    this.serverService.getReviews(this.id).subscribe(data=>{
      if(data.reviews){
        this.reviews = data.reviews
      }

    }, err=>{
      this.snackbar.open('Cannot Fetch Comments','Dismiss',{
        duration: 3000
      })
    })
    this.commentForm = new FormGroup({
      'content': new FormControl(null, {validators: [Validators.required]})
    })
  }

  onComment(){
    const review: Review= {
      id: null,
      projectid: this.id,
      creatorid: this.authService.getUser().id,
      username: this.authService.getUser().username,
      content: this.commentForm.get('content').value
    }
    this.commentForm.reset()
    this.serverService.createReview(review).subscribe(res=>{
     if(res.id){
       this.serverService.getReviews(this.id).subscribe(data=>{
         if(data.reviews){
           this.reviews = data.reviews
         }
       }, err=>{
        this.snackbar.open('Cannot Fetch Comments','Dismiss',{
          duration: 3000
        })
       })
     }
    }, err=>{
      this.snackbar.open('An Unexpected Error Occurred','Dismiss',{
        duration: 3000
      })
    })
  }

  onDelete(id: string){
    this.serverService.delteteReview(id).subscribe(res=>{
      this.snackbar.open(res.message,'Dismiss',{
        duration: 3000
      })
      this.serverService.getReviews(this.id).subscribe(data=>{
        if(data.reviews){
          this.reviews = data.reviews
        }
      }, err=>{
       this.snackbar.open('Cannot Fetch Comments','Dismiss',{
         duration: 3000
       })
      })
    },err=>{
      this.snackbar.open('An Unexpected Error Occurred','Dismiss',{
        duration: 3000
      })
    })
  }

}
