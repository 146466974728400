
<ng-container>
<table border="2" style="display: flex;">
    <div>
      <tr>
        <th>Column Name</th>
      </tr>
      <tr style="display: flex; flex-direction: column;">
        <td *ngFor="let val of this.col; let i= index;">{{ val }}</td>
      </tr>
    </div>  
  
    <div>
      <tr>
        <th>Null Values</th>
      </tr>
      <tr style="display: flex; flex-direction: column;">
        <td *ngFor="let val of this.nullval; let i= index;">{{ val }}</td>
      </tr>
    </div>

    <div>
      <tr>
        <th>Total Count</th>
      </tr>
      <tr style="display: flex; flex-direction: column;">
          <td *ngFor="let val of this.totalcount; let i= index;">{{ val }}</td>
      </tr>
    </div>

    <div>
      <tr>
        <th>Nan Values</th>
      </tr>
      <tr style="display: flex; flex-direction: column;">
        <td *ngFor="let val of this.nann; let i= index;">{{val}}</td> 
      </tr>
    </div>

    <div>
      <tr>
         <th>Data Type</th>
      </tr>
      <tr style="display: flex; flex-direction: column;">
          <td *ngFor="let val of this.dtype; let i= index;">{{val}}</td>
      </tr>
    </div>
</table>
</ng-container>
