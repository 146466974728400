import { Component, OnInit, OnDestroy} from '@angular/core';
import { AuthService } from './auth/auth.service';
import { Router, NavigationStart, NavigationEnd, NavigationCancel } from '@angular/router';
import { Subscription, Subject } from 'rxjs';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'heliware';
  loadingSub: Subject<boolean> = new Subject();
  isLoggedInSubscription: Subscription
  constructor(private auth: AuthService, private router: Router, private snackbar: MatSnackBar){}
  ngOnInit(){
    this.router.events.subscribe(ev=>{
      if(ev instanceof NavigationStart) {
        this.loadingSub.next(true);
      }
      if(ev instanceof NavigationEnd || ev instanceof NavigationCancel) {
        this.loadingSub.next(false);
      }
    })
    this.isLoggedInSubscription = this.auth.isLoggedIn.subscribe(res=>{
      if(!res){
        this.router.navigate(['/']);
        this.snackbar.open('Logged Out', 'OK', {
          duration: 5000
        })
      }
    })
    this.auth.autoAuth();
  }

  ngOnDestroy(){
    this.isLoggedInSubscription.unsubscribe()
  }
}
