import { featureIcon, matIcon } from "../models/dataGeoJSON.interface";

export const matIcons: matIcon[] = [
  {
    name: "Place",
    mat: "place",
    color: "rgb(48, 48, 247)",
  },
  {
    name: "Hotel",
    mat: "hotel",
    color: "rgb(9, 255, 0)",
  },
  {
    name: "Local Hospital",
    mat: "local_hospital",
    color: "rgb(138, 138, 231)",
  },
  {
    name: "Home",
    mat: "home",
    color: "rgb(224, 113, 9)",
  },
  {
    name: "Restaurant",
    mat: "restaurant",
    color: "rgb(233, 117, 223)",
  },
  {
    name: "School",
    mat: "school",
    color: "rgb(241, 34, 34)",
  },
  {
    name: "Subway",
    mat: "subway",
    color: "rgb(228, 225, 55)",
  },
  {
    name: "Phone",
    mat: "phone",
    color: "rgb(241, 198, 6)",
  },
  {
    name: "Local Post Office",
    mat: "local_post_office",
    color: "rgb(233, 16, 179)",
  },
  {
    name: "Location City",
    mat: "location_city",
    color: "rgb(28, 167, 231)",
  },
  {
    name: "Local Parking",
    mat: "local_parking",
    color: "rgb(5, 228, 209)",
  },
  {
    name: "Flight",
    mat: "flight",
    color: "rgb(158, 108, 223)",
  },
  {
    name: "Public",
    mat: "public",
    color: "rgb(30, 70, 61)",
  },
];

export const featureIcons: featureIcon[] = [
  {
    featureDesc: "place",
    url: "http://maps.google.com/mapfiles/kml/paddle/P.png",
    icon: "marker-15",
  },
  {
    featureDesc: "hotel",
    url: "http://maps.google.com/mapfiles/kml/shapes/lodging.png",
    icon: "lodging-15",
  },
  {
    featureDesc: "local_hospital",
    url: "http://maps.google.com/mapfiles/kml/shapes/hospitals.png",
    icon: "hospital-15",
  },
  {
    featureDesc: "home",
    url: "http://maps.google.com/mapfiles/kml/shapes/homegardenbusiness.png",
    icon: "town-hall-15",
  },
  {
    featureDesc: "restaurant",
    url: "http://maps.google.com/mapfiles/kml/shapes/dining.png",
    icon: "restaurant-15",
  },
  {
    featureDesc: "school",
    url: "http://maps.google.com/mapfiles/kml/paddle/S.png",
    icon: "school-15",
  },
  {
    featureDesc: "subway",
    url: "http://maps.google.com/mapfiles/kml/shapes/subway.png",
    icon: "rail-metro-15",
  },
  {
    featureDesc: "phone",
    url: "http://maps.google.com/mapfiles/kml/shapes/phone.png",
    icon: "music-15",
  },
  {
    featureDesc: "local_post_office",
    url: "http://maps.google.com/mapfiles/kml/shapes/post_office.png",
    icon: "post-15",
  },
  {
    featureDesc: "location_city",
    url: "http://maps.google.com/mapfiles/kml/shapes/ranger_station.png",
    icon: "garden-15",
  },
  {
    featureDesc: "local_parking",
    url: "http://maps.google.com/mapfiles/kml/shapes/parking_lot.png",
    icon: "car-15",
  },
  {
    featureDesc: "flight",
    url: "http://maps.google.com/mapfiles/kml/shapes/airports.png",
    icon: "airport-15",
  },
  {
    featureDesc: "public",
    url: "http://maps.google.com/mapfiles/kml/paddle/blu-blank.png",
    icon: "amusement-park-15",
  },
  {
    featureDesc: "temple",
    url: "http://maps.google.com/mapfiles/kml/paddle/T.png",
    icon: "place-of-worship-15",
  },
];

export const legendColor =
  //  [
  //   ["#2c7fb8", "#636363" , "#2ca25f", "#dd1c77", "#edf8b1", "#632263" , "#2ca23a", "#444444"],
  //   ["#7c7fb8", "#630063" , "#2ca2ff", "#565465", "#edffff", "#63f063" , "#2111f0", "#dddddd"],
  //   ["#8c7fb8", "#63f963" , "#23435f", "#dd3534", "#e33331", "#63d463" , "#2ca200", "#af3409"],
  //   ["#5c7fb8", "#639063" , "#2ca299", "#543577", "#e234b1", "#631163" , "#2fff33", "#dd4632"],
  //   ["#6c7fb8", "#631163" , "#2fff33", "#dd4632", "#e45451", "#2c7fb8", "#636363" , "#2ca25f"],
  //   ["#f9e8f4", "#f2d0ea", "#ebb9df", "#e3a1d5","#da89ca","#d171c0","#c857b5", "#be39ab"],
  //   [ "#ffefe5", "#ffdfcb", "#ffceb2", "#ffbe99", "#ffae80", "#fd9e68", "#f98e4f", "#f37e36" ],
  //   [ "#fafdff","#cce8fe" , "#a0d1fe", "#77b9fe", "#4ea0fc", "#2286f8", "#006af1", "#004be5" ]
  // ]

  //   [
  //     ['#ff8d89', '#ff827e', '#ff7872', '#ff6d67', '#ff615b', '#ff554f', '#ff4842', '#ff3935', '#ff2627', '#ff0018'],
  //     ['#72a1ff', '#6295ff', '#5389ff', '#447cff', '#366fff', '#2a61ff', '#2051ff', '#1d40ff', '#212bff', '#2a00ff'],
  //     ['#93ff98', '#8aff8f', '#80ff87', '#76ff7e', '#6bff74', '#60ff6b', '#53ff60', '#43ff55', '#2eff4a', '#00ff3c'],
  //     ['#9df09d', '#99f099', '#95f094', '#91ef90', '#8cef8c', '#88ee88', '#84ee83', '#7fed7f', '#7bed7a', '#76ec76'],
  //     ['#618787', '#5b8686', '#548585', '#4d8585', '#458484', '#3d8383', '#348282', '#298282', '#1b8181', '#008080'],
  //     ['#f8bc55', '#f9ba4e', '#f9b747', '#fab540', '#fbb239', '#fcb032', '#fcad29', '#fdaa20', '#fea813', '#ffa500'],
  //     ['#b66ce5', '#b466e7', '#b15fe8', '#af58e9', '#ad51ea', '#ab49ec', '#a841ed', '#a537ee', '#a32def', '#a020f0'],
  //     ['#eb75af', '#ec71ad', '#ed6cab', '#ee67a9', '#ef62a7', '#f05ca5', '#f157a2', '#f251a0', '#f34a9d', '#f4439b'],
  //     ['#b0c383', '#b4bf81', '#b9bb80', '#bcb87f', '#bfb47f', '#c1b17f', '#c3ad80', '#c4aa81', '#c4a782', '#c4a484'],
  //     ['#94d3cd', '#8cd3cd', '#84d3cc', '#7bd4cc', '#72d4cb', '#68d4cb', '#5dd4ca', '#51d5c9', '#43d5c9', '#30d5c8']
  //  ]

  // [
  // ['#d9aca2', '#ce988c', '#c38477', '#b77162', '#aa5d4e', '#9d4a3b', '#903628', '#730000'],
  // ['#b7d7ca', '#a6cdbd', '#94c3b0', '#82b9a3', '#70af97', '#5da68b', '#489c7f', '#0d8867'],
  // ['#cfb1ff', '#c19eff', '#b38bff', '#a378ff', '#9265ff', '#7e52ff', '#673dff', '#0000ff'],
  // ['#ceffbc', '#c0ffac', '#b2ff9a', '#a2ff89', '#91ff77', '#7eff64', '#68ff4f', '#12ff00'],
  // ['#ceffff', '#c0ffff', '#b2ffff', '#a2ffff', '#91ffff', '#7effff', '#67ffff', '#00ffff'],
  // ['#ffe0b5', '#ffd9a3', '#ffd191', '#ffca7e', '#ffc26c', '#ffbb58', '#ffb444', '#ffa500'],
  // ['#cfaecb', '#c29abf', '#b686b2', '#a973a5', '#9d6099', '#904c8d', '#833881', '#690069'],
  // ['#b7d4d3', '#a5c9c8', '#93bebd', '#81b4b3', '#6ea9a8', '#5b9f9e', '#469594', '#008080'],
  // ['#c2dfef', '#b2d7eb', '#a2cfe7', '#91c7e3', '#7fc0df', '#6cb8db', '#56b0d7', '#00a1cf'],
  // ['#cff1ca', '#c3eebc', '#b6eaaf', '#a9e7a2', '#9ce395', '#8edf88', '#80db7b', '#60d360'],
  // ['#ffbba8', '#ffaa93', '#ff997f', '#fe876b', '#fa7557', '#f56244', '#ef4d30', '#e20101'],
  // ['#a2a2a2', '#8c8c8c', '#777777', '#626262', '#4e4e4e', '#3b3b3b', '#292929', '#000000'],
  // ['#cacaca', '#bebebe', '#b1b1b1', '#a5a5a5', '#989898', '#8c8c8c', '#808080', '#696969'],
  // [ "#b5de2b","#6ece58","#35b779","#1f9e89","#26828e","#31688e","#3e4989","#482878"],
  // [ "#a6a900","#c78c00","#e26800","#f62f00","#ff0035","#f90061","#e0008f","#ad00be"],
  // ["#feca8d","#fd9668","#f1605d","#cd4071","#9e2f7f","#721f81","#440f76","#180f3d"]
  // ]

  [
    [{ color: "#d7191c" }, { color: "#000000" }],
    [{ color: "#2b83ba" }, { color: "#000000" }],
    [{ color: "#32cd32" }, { color: "#000000" }],
    [{ color: "#d7191c" }, { color: "#2b83ba" }],
    [{ color: "#30123b" }, { color: "#7a0403" }],
    [{ color: "#03051a" }, { color: "#faebdd" }],
    [{ color: "#00204d" }, { color: "#ffea46" }],
    [{ color: "#440154" }, { color: "#fde725" }],
    [{ color: "#0d0887" }, { color: "#f0f921" }],
  ];

// export const legendColorString =
// [
//   ["#2c7fb8", "#636363" , "#2ca25f", "#dd1c77", "#edf8b1", "#7c7fb8", "#630063" , "#2ca2ff", "#565465", "#edffff"],
//   ["#7c7fb8", "#630063" , "#2ca2ff", "#565465", "#edffff", "#2c7fb8", "#636363" , "#2ca25f", "#dd1c77", "#edf8b1"],
//   ["#8c7fb8", "#63f963" , "#23435f", "#dd3534", "#e33331", "#5c7fb8", "#639063" , "#2ca299", "#543577", "#e234b1"],
//   ["#5c7fb8", "#639063" , "#2ca299", "#543577", "#e234b1", "#8c7fb8", "#63f963" , "#23435f", "#dd3534", "#e33331"],
//   ["#6c7fb8", "#631163" , "#2fff33", "#dd4632", "#e45451", "#447fb8", "#63d463" , "#2ca200", "#af3409", "#ed1567"],
//   ["#447fb8", "#63d463" , "#2ca200", "#af3409", "#ed1567", "#6c7fb8", "#631163" , "#2fff33", "#dd4632", "#e45451"],
//   ["#ffffff", "#ffefe5", "#ffdfcb", "#ffceb2", "#ffbe99", "#ffae80", "#fd9e68", "#f98e4f", "#f37e36", "#ed6d17"],
//   ["#ffffff", "#f9e8f4", "#f2d0ea", "#ebb9df", "#e3a1d5","#da89ca","#d171c0","#c857b5", "#be39ab", "#b300a0"]

// ]

//   [
//     ['#ff8d89', '#ff827e', '#ff7872', '#ff6d67', '#ff615b', '#ff554f', '#ff4842', '#ff3935', '#ff2627', '#ff0018'],
//     ['#72a1ff', '#6295ff', '#5389ff', '#447cff', '#366fff', '#2a61ff', '#2051ff', '#1d40ff', '#212bff', '#2a00ff'],
//     ['#93ff98', '#8aff8f', '#80ff87', '#76ff7e', '#6bff74', '#60ff6b', '#53ff60', '#43ff55', '#2eff4a', '#00ff3c'],
//     ['#9df09d', '#99f099', '#95f094', '#91ef90', '#8cef8c', '#88ee88', '#84ee83', '#7fed7f', '#7bed7a', '#76ec76'],
//     ['#618787', '#5b8686', '#548585', '#4d8585', '#458484', '#3d8383', '#348282', '#298282', '#1b8181', '#008080'],
//     ['#f8bc55', '#f9ba4e', '#f9b747', '#fab540', '#fbb239', '#fcb032', '#fcad29', '#fdaa20', '#fea813', '#ffa500'],
//     ['#b66ce5', '#b466e7', '#b15fe8', '#af58e9', '#ad51ea', '#ab49ec', '#a841ed', '#a537ee', '#a32def', '#a020f0'],
//     ['#eb75af', '#ec71ad', '#ed6cab', '#ee67a9', '#ef62a7', '#f05ca5', '#f157a2', '#f251a0', '#f34a9d', '#f4439b'],
//     ['#b0c383', '#b4bf81', '#b9bb80', '#bcb87f', '#bfb47f', '#c1b17f', '#c3ad80', '#c4aa81', '#c4a782', '#c4a484'],
//     ['#94d3cd', '#8cd3cd', '#84d3cc', '#7bd4cc', '#72d4cb', '#68d4cb', '#5dd4ca', '#51d5c9', '#43d5c9', '#30d5c8']
//  ]

// [
//   ['#e3c1b9', '#d9aca2', '#ce988c', '#c38477', '#b77162', '#aa5d4e', '#9d4a3b', '#903628', '#822016', '#730000'],
// ['#c9e1d7', '#b7d7ca', '#a6cdbd', '#94c3b0', '#82b9a3', '#70af97', '#5da68b', '#489c7f', '#319273', '#0d8867'],
// ['#dcc4ff', '#cfb1ff', '#c19eff', '#b38bff', '#a378ff', '#9265ff', '#7e52ff', '#673dff', '#4926ff', '#0000ff'],
// ['#dbffcd', '#ceffbc', '#c0ffac', '#b2ff9a', '#a2ff89', '#91ff77', '#7eff64', '#68ff4f', '#4bff35', '#12ff00'],
// ['#dbffff', '#ceffff', '#c0ffff', '#b2ffff', '#a2ffff', '#91ffff', '#7effff', '#67ffff', '#49ffff', '#00ffff'],
// ['#ffe8c7', '#ffe0b5', '#ffd9a3', '#ffd191', '#ffca7e', '#ffc26c', '#ffbb58', '#ffb444', '#ffac2c', '#ffa500'],
// ['#dbc2d8', '#cfaecb', '#c29abf', '#b686b2', '#a973a5', '#9d6099', '#904c8d', '#833881', '#762275', '#690069'],
// ['#c9dede', '#b7d4d3', '#a5c9c8', '#93bebd', '#81b4b3', '#6ea9a8', '#5b9f9e', '#469594', '#2e8a8a', '#008080'],
// ['#d1e7f3', '#c2dfef', '#b2d7eb', '#a2cfe7', '#91c7e3', '#7fc0df', '#6cb8db', '#56b0d7', '#3ba9d3', '#00a1cf'],
// ['#dbf5d7', '#cff1ca', '#c3eebc', '#b6eaaf', '#a9e7a2', '#9ce395', '#8edf88', '#80db7b', '#71d76e', '#60d360'],
// ['#ffccbd', '#ffbba8', '#ffaa93', '#ff997f', '#fe876b', '#fa7557', '#f56244', '#ef4d30', '#e9331b', '#e20101'],
// ['#b9b9b9', '#a2a2a2', '#8c8c8c', '#777777', '#626262', '#4e4e4e', '#3b3b3b', '#292929', '#181818', '#000000'],
// ['#d7d7d7', '#cacaca', '#bebebe', '#b1b1b1', '#a5a5a5', '#989898', '#8c8c8c', '#808080', '#757575', '#696969'],
// [ "#fde725","#b5de2b","#6ece58","#35b779","#1f9e89","#26828e","#31688e","#3e4989","#482878","#440154"],
// [ "#80c000","#a6a900","#c78c00","#e26800","#f62f00","#ff0035","#f90061","#e0008f","#ad00be","#3614e6"],
// ["#fcfdbf","#feca8d","#fd9668","#f1605d","#cd4071","#9e2f7f","#721f81","#440f76","#180f3d","#000004"]
// ]

export const graphType = ["pie", "pie-hole", "bar", "scatter"];

export const geoIcon = [
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-location-50.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-slightly-smiling-face-48.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-sad-48.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-thick-arrow-pointing-up-26.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-thick-arrow-pointing-down-30.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-thumbs-up-48.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-thumbs-down-48.png",
  // "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-footwear-64.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-rectangular-30.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-fuel-pump-65.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-parking-64.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-hospital-3-100.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/female-icon.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/male-icon.png",
  // "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-retail-store-64.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-restaurant-64.png",
  // "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/mobile.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-bus-64.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-car-100.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-house-100.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-college-62.png",
  // "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-school-64.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-flag-filled-16.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-cross-mark-48.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/right-icon.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/bank.png",
  "https://heliwarestorage.s3.us-west-2.amazonaws.com/icons/icons8-atm-64.png",
];

export const geoIconName = [
  "location_on",
  "sentiment_satisfied_alt",
  "sentiment_very_dissatisfied",
  "arrow_upward",
  "arrow_downward",
  "thumb_up",
  "thumb_down",
  "rectangle",
  "local_gas_station",
  "local_parking",
  "local_hospital",
  "woman_2",
  "boy",
  "restaurant",
  "directions_bus_filled",
  "drive_eta",
  "house",
  "school",
  "golf_course",
  "dangerous",
  "done",
  "account_balance",
  "atm",
];
