<h1 style="text-align: center;" mat-dialog-title>Share</h1>
<div mat-dialog-content>
  <h3>Add User</h3>
  <form [formGroup]="usersForm">
    <div class="search">
      <mat-form-field class="email">
        <input matInput formControlName="email" placeholder="Enter Email">
      </mat-form-field>
      <mat-form-field class="access-type">
        <mat-select #ac formControlName="accessControl">
          <mat-select-trigger>
            <mat-icon>{{ac.value == 'canView' ? 'remove_red_eye' : 'edit'}}</mat-icon>
          </mat-select-trigger>
          <mat-option matTooltip="View" value="canView"> <mat-icon>remove_red_eye</mat-icon> </mat-option>
          <mat-option matTooltip="Edit" value="canEdit"> <mat-icon>edit</mat-icon> </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <mat-icon (click)="checkEmailPhoneNo()">add</mat-icon>
    <div *ngIf="data.type == 'GIS' && emailPhone">
      <mat-form-field class="phone">
        <input matInput formControlName="phone" placeholder="Enter Phone Number">
      </mat-form-field>
    </div>
    <mat-form-field style="display: block;">
      <textarea matInput placeholder="Message" formControlName="message" rows="5" style="width: 100%;"></textarea>
    </mat-form-field>
    <button *ngIf="!submitting" [disabled]="usersForm.invalid" (click)="onAdd()" mat-button>Add</button>
    <mat-progress-spinner *ngIf="submitting" mode="indeterminate"></mat-progress-spinner>
  </form>
  <br>
  <h3>Collaborators</h3>
  <mat-list>
    <mat-list-item *ngFor="let user of collaborators">
      <mat-icon [matTooltip]="user.owner?'Owner':'Guest'" matListIcon>{{user.owner?'verified_user':'person'}}</mat-icon>
      <mat-icon [matTooltip]="user.type == 'canEdit'?'Edit':'View'" matListIcon>{{user.type == 'canEdit'?'edit':'remove_red_eye'}}</mat-icon>
      <h3 matLine>
        {{user.username}}
      </h3>
      <button *ngIf="!user.owner" (click)="removeUser(user.id, user.type)" mat-icon-button>
        <mat-icon>close</mat-icon>
      </button>
    </mat-list-item>
  </mat-list>
  <br>
  <br>
  <div mat-dialog-actions style="display: flex; justify-content: space-around;">
    <!-- <button (click)="onDone()" mat-button>Close</button> -->
    <button mat-raised-button (click)="onDone()" >Close</button>
    <button mat-raised-button (click)="videoUploadNotify()" >Mail to all</button>

    <!-- <button (click)="videoUploadNotify()" mat-button>Mail to all</button> -->
    <!-- <button (click)="onClose()" mat-button>Close</button> -->
  </div>
</div>
