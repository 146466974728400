import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { ServerService } from 'src/app/server.service';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-get-in-touch',
  templateUrl: './get-in-touch.component.html',
  styleUrls: ['./get-in-touch.component.scss']
})
export class GetInTouchComponent implements OnInit, AfterViewInit {

  @Input('btnStyle') btnStyle;
  constructor(private authService: AuthService, private serverService: ServerService, private snackbar: MatSnackBar) { }

  ngOnInit() {
  }

  ngAfterViewInit(){
  }

  send(){
    const user = this.authService.getUser();
    this.serverService.emailUserDetail(user).subscribe(res=>{
      this.snackbar.open('We will get back to you', 'ok', {
        duration: 1000
      })
    },(err)=>{
      this.snackbar.open('Something went wrong.', 'ok', {
        duration: 1000
      })
    });
  }
}
