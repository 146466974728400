<mat-card>
    <mat-card-header>
      <mat-card-title style="font-size: 14px;
      line-height: 1.2;
      max-width: 200px;
      width: fit-content;
      color: white;">
         {{data.content}}
      </mat-card-title>
    </mat-card-header>
  </mat-card>