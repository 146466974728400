import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-pan-tagging',
  templateUrl: './pan-tagging.component.html',
  styleUrls: ['./pan-tagging.component.scss']
})
export class PanTaggingComponent implements OnInit {
  tagForm: FormGroup
  canSubmit: boolean= false
  constructor(
    public dialogRef: MatDialogRef<PanTaggingComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {}

    ngOnInit() {
      console.log(this.data);
      this.tagForm = new FormGroup({
        'name':new FormControl(null, {validators: [Validators.required, Validators.maxLength(7)] }),
        'content': new FormControl(null, {validators: [Validators.required, Validators.maxLength(150)] })
      })
      this.tagForm.valueChanges.subscribe((val)=>{
        this.data = {
          ...this.data,
          content: val.content,
          name: val.name
        }
      })
    }

    onCancel(){
      this.dialogRef.close()
    }

}