import { CanActivate, Router, CanActivateChild, ActivatedRouteSnapshot, CanLoad } from '@angular/router';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
const prod = environment.production;

@Injectable()
export class AuthGaurd implements CanActivate {

  constructor(private auth: AuthService, private router: Router) { }
  canActivate(route: ActivatedRouteSnapshot): boolean {
    const token = this.auth.getToken()
    const isPoiCreator = this.auth.getUser()? !!this.auth.getUser().poiCreator: false;
    // this.auth.isLoggedIn.subscribe(res=>{
    //   if(!res){
    //     this.router.navigate(['/'])
    //   }
    // })
    // if(prod){
    //   if(route.url[0].path == 'createaccount'){
    //     // this.router.navigate(['/'])
    //     return true
    //   }else{
    //     if(token){
    //       return true
    //     }else{
    //       this.router.navigate(['/'])
    //       return false
    //     }
    //   }
    // }else{
    //   if(route.url[0].path == 'createaccount'){
    //     // this.router.navigate(['/'])
    //     return true
    //   }else{
    //     if(token){
    //       return true
    //     }else{
    //       this.router.navigate(['/'])
    //       return false
    //     }
    //   }
    // }
    if (route.url[0].path == 'createaccount') {
      return true
    } else {
      if (token) {
        const path = route.url[0].path;
        if(isPoiCreator){
          if(path == 'admin'){
            return true;
          }else{
            this.router.navigate(['admin', 'dashboard'])
            return false;
          }
        }else{
          if(path == 'admin'){
            this.router.navigate(['/'])
            return false;
          }else{
            return true
          }
        }
      } else {
        this.router.navigate(['/'])
        return false
      }
    }
  }
}

@Injectable()
export class AuthChildGaurd implements CanActivateChild {
  constructor(private authGaurd: AuthGaurd) { }
  canActivateChild(route: ActivatedRouteSnapshot): boolean {
    return this.authGaurd.canActivate(route)
  }
}

@Injectable()
export class AuthModuleGaurd implements CanLoad {
  constructor(private auth: AuthService) { }
  canLoad() {
    const token = this.auth.getToken();
    return token ? true : false
  }
}
