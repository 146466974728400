import { Injectable } from "@angular/core";
import { legendColor } from "../../icons";
import Axios from "axios";
import { Analysislayer } from "../module-utility.interface";
import * as Tiny from "tinygradient";

@Injectable({
  providedIn: "root",
})
export class GraphService {
  geoJsonLayerData;
  currentPlot;
  colors;

  constructor() {}

// <<<<<<< HEAD
  //  }

   generateColor(n){
    this.colors = [];
    legendColor.map(arr=>{
      const tc = new Tiny(arr).rgb(n).map(c=> c.toHexString());
      this.colors.push(tc);
    })
   }

//   graphTypeNumber(l:Analysislayer){
// =======
  graphTypeNumber(l: Analysislayer, colorArr) {
// >>>>>>> feature/geoprocessingHeliAi
    this.colors = [];
    console.log(legendColor);
    if (colorArr.length == 0) {
      legendColor.map((arr) => {
        const tc = new Tiny(arr).rgb(l.legendCount).map((c) => c.toHexString());
        this.colors.push(tc);
      });
    } else {
      colorArr.map((arr) => {
        const tc = new Tiny(arr).rgb(l.legendCount).map((c) => c.toHexString());
        this.colors.push(tc);
      });
    }

    if (l.type == "Geojson") {
      this.geoJsonLayerData.map((b) => {
        if (b.name == l.dataset) {
          this.prepareGeojsonPieChart(b, l);
        }
      });
    }
  }

  async prepareGeojsonPieChart(geojson, l) {
    if (l.graphType == "pie" || l.graphType == "pie-hole" || !l.graphType) {
      let plotdata = {
        values: l.chartData.values,
        labels: l.chartData.labels,
        type: "pie",
        hole: l.graphType == "pie-hole" ? 0.5 : 0,
        marker: {
          colors: l.legendColorIndex
            ? this.colors[l.legendColorIndex]
            : this.colors[0],
        },
      };
      this.currentPlot = plotdata;
    } else {
      console.log(l.graphType);
      let plotdata = {
        x: l.chartData.labels,
        y: l.chartData.values,
        type: l.graphType,
        marker: {
          colors: l.legendColorIndex
            ? this.colors[l.legendColorIndex]
            : this.colors[0],
        },
      };
      this.currentPlot = plotdata;
    }
  }

  graphTypeString(l: Analysislayer) {
    if (l.type == "Geojson") {
      this.geoJsonLayerData.map((b) => {
        console.log(b.name, l.dataset);
        if (b.name == l.dataset) {
          this.prepareGeojsonPieChartString(b, l);
        }
      });
    }
  }

  async prepareGeojsonPieChartString(geojson, l) {
    if (l.graphType == "pie" || l.graphType == "pie-hole" || !l.graphType) {
      let plotdata = {
        values: [],
        labels: [],
        type: "pie",
        hole: l.graphType == "pie-hole" ? 0.5 : 0,
        marker: {
          colors: [],
        },
      };

      const data = geojson.data
        ? geojson.data
        : (await Axios.get(geojson.url)).data;
      if (!l.textValues) {
        data.features.map((d) => {
          if (d.properties[l.column]) {
            let index = plotdata.labels.findIndex(
              (e) => e == d.properties[l.column].toLowerCase()
            );
            if (index == -1 && plotdata.labels.length < 10) {
              plotdata.labels.push(d.properties[l.column].toLowerCase());
              plotdata.values.push(1);
            } else if (index !== -1) {
              plotdata.values[index] += 1;
            }
          }
        });

        this.colors = [];
        legendColor.map((arr) => {
          const tc = new Tiny(arr)
            .rgb(plotdata.labels.length)
            .map((c) => c.toHexString());
          this.colors.push(tc);
        });

        plotdata.marker.colors = l.legendColorIndex
          ? this.colors[l.legendColorIndex]
          : this.colors[0];
      } else {
        plotdata.labels = l.textValues;
        l.textValues.map((t) => {
          plotdata.values.push(0);
        });
        data.features.map((d) => {
          if (d.properties[l.column]) {
            let index = plotdata.labels.findIndex(
              (e) => e == d.properties[l.column].toLowerCase()
            );
            if (index !== -1) {
              plotdata.values[index] += 1;
            }
          }
        });

        this.colors = [];
        legendColor.map((arr) => {
          const tc = new Tiny(arr)
            .rgb(plotdata.labels.length)
            .map((c) => c.toHexString());
          this.colors.push(tc);
        });

        plotdata.marker.colors = l.legendColorIndex
          ? this.colors[l.legendColorIndex]
          : this.colors[0];
      }
      this.currentPlot = plotdata;
    } else {
      console.log(l.graphType);
      let plotdata = {
        x: [],
        y: [],
        type: l.graphType,
        marker: {
          colors: [],
        },
      };
      // if(geojson.data){
      const data = geojson.data
        ? geojson.data
        : (await Axios.get(geojson.url)).data;

      if (!l.textValues) {
        data.features.map((d) => {
          if (d.properties[l.column]) {
            let index = plotdata.x.findIndex(
              (e) => e == d.properties[l.column].toLowerCase()
            );
            if (index == -1 && plotdata.x.length < 10) {
              plotdata.x.push(d.properties[l.column].toLowerCase());
              plotdata.y.push(1);
            } else if (index !== -1) {
              plotdata.y[index] += 1;
            }
          }
        });

        this.colors = [];
        legendColor.map((arr) => {
          const tc = new Tiny(arr)
            .rgb(plotdata.x.length)
            .map((c) => c.toHexString());
          this.colors.push(tc);
        });

        plotdata.marker.colors = l.legendColorIndex
          ? this.colors[l.legendColorIndex]
          : this.colors[0];
      } else {
        plotdata.x = l.textValues;
        l.textValues.map((t) => {
          plotdata.y.push(0);
        });
        data.features.map((d) => {
          if (d.properties[l.column]) {
            let index = plotdata.x.findIndex(
              (e) => e == d.properties[l.column].toLowerCase()
            );
            if (index !== -1) {
              plotdata.y[index] += 1;
            }
          }
        });

        this.colors = [];
        legendColor.map((arr) => {
          const tc = new Tiny(arr)
            .rgb(plotdata.x.length)
            .map((c) => c.toHexString());
          this.colors.push(tc);
        });

        plotdata.marker.colors = l.legendColorIndex
          ? this.colors[l.legendColorIndex]
          : this.colors[0];
      }

      this.currentPlot = plotdata;
    }
  }
}
