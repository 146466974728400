<h1 mat-dialog-title>Describe</h1>
<div mat-dialog-content>
  <mat-form-field>
    <input matInput placeholder="Name" [(ngModel)]="data.name">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Describe" [(ngModel)]="data.description">
  </mat-form-field>
  <mat-form-field>
    <input matInput type="number" placeholder="Height" [(ngModel)]="data.height">
  </mat-form-field>
  <!-- <mat-form-field>
    <input matInput placeholder="Layer" [(ngModel)]="data.layer">
  </mat-form-field> -->
  <!-- <mat-form-field> -->
  <div>
    <button (click)="file.click()" mat-stroked-button>Pick a Texture</button>
    <input type="file" (change)="onImage($event)" accept="image/png,image/jpeg" #file>
  </div>
  <img *ngIf="!!data.image" style="width: 200px; height: auto;" [src]="data.image">
</div>
<div mat-dialog-actions>
  <!-- <button mat-button (click)="onNoClick()">No Thanks</button> -->
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>
