<h1 mat-dialog-title>Describe</h1>
<div mat-dialog-content>
  <mat-form-field>
    <input matInput placeholder="Name" [(ngModel)]="data.name" autocomplete="false">
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="Describe" [(ngModel)]="data.description" autocomplete="false">
  </mat-form-field>

  <!-- <mat-form-field>
    <input matInput placeholder="Layer" [(ngModel)]="data.layer">
  </mat-form-field> -->
  <!-- <mat-form-field> -->
  <div>
    <button (click)="file.click()" mat-stroked-button>Pick an Image</button>
    <input type="file" (change)="onImage($event)" #file>
  </div>
  <!-- </mat-form-field>
    <mat-form-field>
    <mat-label>Choose Icon</mat-label>
    <mat-select formControlName="featureIcon">
       <mat-option *ngFor="let featureIcon of featureIcons" [value]="featureIcon">
          {{icon}}
       </mat-option>
    </mat-select><mat-icon>map</mat-icon>
  </mat-form-field> -->
  <!-- <br> -->
  <!-- <mat-form-field *ngIf="data.type === 'Point'">
    <mat-label >Choose Feature Type</mat-label>
    <mat-select [(ngModel)]="data.point">
      <mat-option matTooltip="Place" value="0"><mat-icon style="color:rgb(48, 48, 247);">place</mat-icon></mat-option>
      <mat-option matTooltip="Hotel" value="1"><mat-icon style="color:rgb(9, 255, 0);">hotel</mat-icon></mat-option>
      <mat-option matTooltip="Hospital" value="2"><mat-icon style="color:rgb(138, 138, 231);">local_hospital</mat-icon></mat-option>
      <mat-option matTooltip="Home" value="3"><mat-icon style="color:rgb(224, 113, 9);">home</mat-icon></mat-option>
      <mat-option matTooltip="Restaurant" value="4"><mat-icon style="color:rgb(233, 117, 223);">restaurant</mat-icon></mat-option>
      <mat-option matTooltip="School" value="5"><mat-icon style="color:rgb(241, 34, 34);">school</mat-icon></mat-option>
      <mat-option matTooltip="Subway" value="6"><mat-icon style="color:rgb(228, 225, 55);">subway</mat-icon></mat-option>
      <mat-option matTooltip="Phone" value="7"><mat-icon style="color:rgb(241, 198, 6);">phone</mat-icon></mat-option>
      <mat-option matTooltip="Post_Office" value="8"><mat-icon style="color:rgb(233, 16, 179);">local_post_office</mat-icon></mat-option>
      <mat-option matTooltip="City" value="9"><mat-icon style="color:rgb(28, 167, 231);">location_city</mat-icon></mat-option>
      <mat-option matTooltip="Parking" value="10"><mat-icon style="color:rgb(5, 228, 209);">local_parking</mat-icon></mat-option>
      <mat-option matTooltip="Flight" value="11"><mat-icon style="color:rgb(158, 108, 223);">flight</mat-icon></mat-option>
      <mat-option matTooltip="Public" value="12"><mat-icon style="color:rgb(30, 70, 61);">public</mat-icon></mat-option>
    </mat-select>
  </mat-form-field> -->
  <!-- <p>You selected: {{selected}}</p> [(value)]="selected" -->
</div>
<div mat-dialog-actions>
  <!-- <button mat-button (click)="onNoClick()">No Thanks</button> -->
  <button mat-button [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>
