import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { environment } from "src/environments/environment";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.authService.getToken();
    if (authToken) {
      if (
        req.url.includes(environment.apiURL) ||
        req.url.includes(environment.procApiUrl) ||
        req.url.includes(environment.aiApiUrl) ||
        req.url.includes(environment.aiApiUrlOld)
      ) {
        if (req.url.includes(environment.aiApiUrl) || req.url.includes(environment.aiApiUrlOld) 
        ) {
          const authRequest = req.clone({
            headers: req.headers.set("Authorization", "Bearer " + authToken),
          });
          return next.handle(authRequest);
        } else {
          const authRequest = req.clone({
            headers: req.headers.set("Authorization", authToken),
          });
          return next.handle(authRequest);
        }
      } else {
        return next.handle(req);
      }
    } else {
      return next.handle(req);
    }
  }
}
