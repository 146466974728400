import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
// import { PlotlyViaCDNModule } from 'angular-plotly.js';
import { PlotlyViaWindowModule } from 'angular-plotly.js';
import { TagComponent } from './tag/tag.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { ReviewsComponent } from './reviews/reviews.component';
import { GetInTouchComponent } from '../main-nav/get-in-touch/get-in-touch.component';
import { NotificationComponent } from './notification/notification.component';
import { ShareListComponent } from './share-list/share-list.component';
import { ConfirmComponent } from './confirm/confirm.component';
import { BuildingInfoComponent } from '../gis/building-info/building-info.component';
// import * as PlotlyJS from 'plotly.js-dist';
// import { PlotlyModule } from 'angular-plotly.js';

// PlotlyModule.plotlyjs = PlotlyJS;

// PlotlyViaCDNModule.setPlotlyVersion('1.55.2');
@NgModule({
  declarations: [
    TagComponent,
    ReviewsComponent,
    GetInTouchComponent, 
    ConfirmComponent, 
    NotificationComponent, 
    ShareListComponent,
    BuildingInfoComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatFormFieldModule,
    MatCardModule,
    MatInputModule,
    MatGridListModule,
    MatMenuModule,
    MatDialogModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    // PlotlyModule,
    // PlotlyViaCDNModule,
    PlotlyViaWindowModule,
    MatBadgeModule,
    MatTabsModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatButtonToggleModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    MatFormFieldModule,
    MatCardModule,
    MatInputModule,
    MatGridListModule,
    MatMenuModule,
    MatDialogModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatTooltipModule,
    MatExpansionModule,
    MatSnackBarModule,
    MatProgressSpinnerModule,
    MatCheckboxModule,
    MatTreeModule,
    MatRadioModule,
    MatSliderModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatProgressBarModule,
    // PlotlyViaCDNModule,
    // PlotlyModule,
    PlotlyViaWindowModule,
    MatBadgeModule,
    MatTabsModule,
    MatChipsModule,
    TagComponent,
    ReviewsComponent,
    GetInTouchComponent, 
    ConfirmComponent, 
    NotificationComponent, 
    ShareListComponent,
    BuildingInfoComponent,
    MatAutocompleteModule,
    MatButtonToggleModule
  ],
  entryComponents: [TagComponent, GetInTouchComponent, ConfirmComponent, NotificationComponent, ShareListComponent, BuildingInfoComponent]
})
export class SharedModule { }
