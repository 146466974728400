import { Component, OnInit, OnDestroy, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { User } from 'src/app/models/users.model';
import { projectData } from 'src/app/models/projectData.interface';
import { Dsm } from 'src/app/models/dsm.interface';
import { AuthService } from 'src/app/auth/auth.service';
import { MapService } from 'src/app/gis/map.service';
import { DsmService } from 'src/app/dsm/dsm.service';
import { Subscription } from 'rxjs';
import { modelConfig } from 'src/app/models/model-config.interface';
import { ModelConfigService } from 'src/app/model-configurator/model-config.service';
import { AiMapService } from 'src/app/heli-ai/map-ai-service';
import { aiprojectData } from 'src/app/models/aimap.interface';

@Component({
  selector: 'app-share-list',
  templateUrl: './share-list.component.html',
  styleUrls: ['./share-list.component.scss']
})
export class ShareListComponent implements OnInit, OnDestroy {
  usersForm: FormGroup;
  canViews: User[] = [];
  canEdits: User[] = [];
  collaborators: {
    id: string,
    email: string,
    username: string,
    type: 'canEdit'|'canView',
    owner: boolean
  }[] = [];

  // sms
  emailPhone: any = false;
  emailPhoneNo: any;

  submitting: boolean = false;
  dsmProjectSub: Subscription;
  gisProjectSub: Subscription;
  pseudodsmProjectSub: Subscription;
  pseudogisProjectSub: Subscription;
  modelConfigUpdatedSub: Subscription;
  pseudoModelConfigUpdatedSub: Subscription;
  constructor(public dialogRef: MatDialogRef<ShareListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {type: 'GIS' | 'DSM' | 'MCONFIG' | 'AI',project:projectData | Dsm | modelConfig | aiprojectData},
    private auth: AuthService,
    private snackbar: MatSnackBar,
    private mapService: MapService,
    private dsmService: DsmService,
    private aiService: AiMapService,
    private modelConfigurator: ModelConfigService) { }

  ngOnInit() {
    this.dsmProjectSub = this.dsmService.dsmProjectUpdated.subscribe(res=>{
      if(this.data.type == 'DSM'){
        this.data.project = res;
        this.collaborators = [];
        this.populateCollabs();
      }
    })
    this.pseudodsmProjectSub = this.dsmService.pseudodsmProjectUpdated.subscribe(res=>{
      if(this.data.type == 'DSM'){
        this.data.project = res;
        this.collaborators = [];
        this.populateCollabs();
      }
    })
    this.gisProjectSub = this.mapService.projectDataChanged.subscribe(res=>{
      if(this.data.type == 'GIS'){
        this.data.project = res;
        this.collaborators = [];
        this.populateCollabs();
      }
    })
    this.pseudogisProjectSub = this.mapService.pseudoprojectDataChanged.subscribe(res=>{
      if(this.data.type == 'GIS'){
        this.data.project = res;
        this.collaborators = [];
        this.populateCollabs();
      }
    })
    this.modelConfigUpdatedSub = this.modelConfigurator.modelConfigProjectUpdated.subscribe(res=>{
      if(this.data.type == 'MCONFIG'){
        this.data.project = res;
        this.collaborators = [];
        this.populateCollabs();
      }
    })
    this.pseudoModelConfigUpdatedSub = this.modelConfigurator.pseudoModelConfigProjectUpdated.subscribe(res=>{
      if(this.data.type == 'MCONFIG'){
        this.data.project = res;
        this.collaborators = [];
        this.populateCollabs();
      }
    })
    this.aiService.pseudoAiProjectUpdated.subscribe(res=>{
      if(this.data.type == 'AI'){
        this.data.project = res;
        this.collaborators = [];
        this.populateCollabs();
      }
    })
    this.aiService.projectdataChanged.subscribe(res=>{
      if(this.data.type == 'AI'){
        this.data.project = res;
        this.collaborators = [];
        this.populateCollabs();
      }
    })
    this.populateCollabs();
    this.usersForm = new FormGroup({
     // 'email': new FormControl(null, {validators: [Validators.required, Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]}),
     'email': new FormControl(null, {validators: [Validators.required, Validators.pattern(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)]}),
     'accessControl': new FormControl(null, {validators: [Validators.required]}),
      'message': new FormControl(null, {validators: [Validators.required]}),
      'phone': new FormControl(null, { validators: [Validators.pattern(/^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/)]}),
    })
  }

  populateCollabs(){
    if(this.data.project.canEdit.length>0){
      this.auth.findUsers(this.data.project.canEdit).subscribe(res=>{
        this.canEdits = res.users;
        this.canEdits.forEach(user=>{
          this.collaborators.push({
            ...user,
            type: 'canEdit',
            owner: this.data.project.creatorid == user.id?true:false
          })
        })
      })
    }
    if(this.data.project.canView.length>0){
      this.auth.findUsers(this.data.project.canView).subscribe(res=>{
        this.canViews = res.users;
        this.canViews.forEach(user=>{
          this.collaborators.push({
            ...user,
            type: 'canView',
            owner: this.data.project.creatorid == user.id?true:false
          })
        })
      })
    }
  }

  ngOnDestroy(){
    this.dsmProjectSub.unsubscribe();
    this.pseudodsmProjectSub.unsubscribe();
    this.gisProjectSub.unsubscribe();
    this.pseudogisProjectSub.unsubscribe();
    this.modelConfigUpdatedSub.unsubscribe();
    this.pseudoModelConfigUpdatedSub.unsubscribe();
  }

  onAdd(){
    this.submitting = true;
    this.auth.findUser(this.usersForm.value.email).subscribe(res=>{
      switch (this.data.type) {
        case 'GIS':
          this.mapService.shareProject({id: this.data.project.id, userid: res.user.id, accessControl: this.usersForm.value.accessControl, message: this.usersForm.value.message, phoneno:this.usersForm.value.phone});
          this.emailPhone = null;
          // this.mapService.shareProject({id: this.data.project.id, userid: res.user.id, accessControl: this.usersForm.value.accessControl, message: this.usersForm.value.message})
          break;
        case 'DSM':
          this.dsmService.shareProject({id: this.data.project.id, userid: res.user.id, accessControl: this.usersForm.value.accessControl, message: this.usersForm.value.message})
          break;
        case 'MCONFIG':
          this.modelConfigurator.shareProject({id: this.data.project.id, userid: res.user.id, accessControl: this.usersForm.value.accessControl, message: this.usersForm.value.message})
          break;
          case 'AI':
          this.aiService.shareProject({id: this.data.project.id, userid: res.user.id, accessControl: this.usersForm.value.accessControl, message: this.usersForm.value.message})
          break;
        default:
          break;
      }
      this.submitting = false;
      this.usersForm.reset();
    },err=>{
      this.submitting = false;
      this.snackbar.open('No such user exists with the provided email', 'ok',{
        duration: 3000
      })
      this.usersForm.reset();
    })
  }

  onDone(){
    this.dialogRef.close();
  }

  // onClose(){
  //   this.dialogRef.close();
  // }

  videoUploadNotify(){
    this.mapService.videoUpload({
      id: this.data.project.id, 
      userids: this.collaborators.map((collaborator)=>collaborator.id), 
      accessControl: 'canView', 
      message: this.usersForm.value.message, 
      type: 'GIS'})
  }

  removeUser(userid, accessControl){
    switch (this.data.type) {
      case 'GIS':
        this.mapService.unShareProject({id: this.data.project.id, userid, accessControl})
        break;
      case 'DSM':
        this.dsmService.unShareProject({id: this.data.project.id, userid, accessControl})
        break;
      case 'MCONFIG':
        this.modelConfigurator.unShareProject({id: this.data.project.id, userid, accessControl})
        break;
      case 'AI':
        this.aiService.unShareProject({id: this.data.project.id, userid, accessControl})
        break;
      default:
        break;
    }
  }

  checkEmailPhoneNo(){
    this.auth.findUser(this.usersForm.value.email).subscribe(res=>{
      this.emailPhoneNo = res.user.phone;
      this.emailPhone = true
      this.usersForm.patchValue({ phone: res.user.phone });
      if(!res.user.phone){
        this.snackbar.open('No phone number exists for this email', 'ok',{
            duration: 3000
        })
      }
    },err=>{
      this.submitting = false;
      // this.snackbar.open('No such user exists with the provided email', 'ok',{
      //   duration: 3000
      // })
      this.usersForm.reset();
    })
  }
}
