<form [formGroup]="commentForm">
  <mat-form-field>
    <textarea formControlName="content" matInput></textarea>
  </mat-form-field>
  <button color="warn" [disabled]="commentForm.invalid" (click)="onComment()" mat-raised-button>Comment</button>
</form>
<!-- <mat-grid-list cols="1">
  <mat-grid-tile *ngFor="let review of reviews">
    <mat-card>
      <mat-card-subtitle>{{review.username}}</mat-card-subtitle>
      <mat-card-content>
        <p>
          {{review.content}}
        </p>
      </mat-card-content>
      <mat-icon *ngIf="review.creatorid === currentuser.id" (click)="onDelete(review.id)" >delete</mat-icon>
    </mat-card>
  </mat-grid-tile>
</mat-grid-list> -->
<mat-list>
  <mat-list-item *ngFor="let review of reviews">
    <h2 matLine>{{review.content}}</h2>
    <p style="text-align: end; font-weight: bold" matLine>{{review.username}}</p>
    <mat-icon *ngIf="review.creatorid === currentuser.id" (click)="onDelete(review.id)" >delete</mat-icon>
  </mat-list-item>
</mat-list>
