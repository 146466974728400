import { Injectable } from "@angular/core";
import * as L from "leaflet";
// import * as PlotlyJS from "plotly.js/dist/plotly.js";
import { Subject } from "rxjs";
import { MapBoxAiService } from "./mapbox-ai.service";

const PlotlyJS = (window as any).PlotlyJS;

@Injectable({ providedIn: "root" })
// @Injectable()
export class PoiService {
  analysisLayer: L.LayerGroup;

  overpassData: {
    college: Array<{}>;
    cafe: Array<{}>;
    atm: Array<{}>;
    bank: Array<{}>;
    bar: Array<{}>;
    school: Array<{}>;
    bus_station: Array<{}>;
    taxi: Array<{}>;
    fuel: Array<{}>;
    pharmacy: Array<{}>;
    hospital: Array<{}>;
    clinic: Array<{}>;
    childcare: Array<{}>;
    police: Array<{}>;
    parking: Array<{}>;
  };

  radius: number = 5000;

  poiClickListner: Subject<any> = new Subject();

  constructor() {} // private mapboxAiService: MapBoxAiService

  poiAnalysis(value, query) {
    let plotChart = (data) => {
      // if(this.analysisLayer) this.analysisLayer.clearLayers();
      this.overpassData = {
        college: [],
        cafe: [],
        atm: [],
        bank: [],
        bar: [],
        school: [],
        bus_station: [],
        taxi: [],
        fuel: [],
        pharmacy: [],
        hospital: [],
        clinic: [],
        childcare: [],
        police: [],
        parking: [],
      };

      // this.mapboxAiService.displayPoi(data);

      this.chartDisplay();
    };

    let aminities = "";
    for (let i = 0; i < query.aminities.length; i++) {
      if (query.aminities[i].checked)
        aminities += query.aminities[i].name + "|";
    }

    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        plotChart(JSON.parse(this.response).elements);
      }
    };
    xhttp.open(
      "GET",
      "https://www.overpass-api.de/api/interpreter?" +
        "data=[out:json][timeout:60];" +
        "node(around:" +
        query.radius +
        "," +
        value.lngLat.lat +
        "," +
        value.lngLat.lng +
        ')["amenity"~"' +
        aminities.substr(0, aminities.length - 1) +
        '"];' +
        "out;",
      true
    );
    xhttp.send();
  }

  chartDisplay() {
    // Plot Bar Chart
    var graphData = [
      {
        x: [
          "College",
          "Cafe",
          "ATM",
          "Bank",
          "Bar",
          "School",
          "Bus Stop",
          "Taxi",
          "Fuel",
          "Pharmacy",
          "Hospital",
          "Clinic",
          "Childcare",
          "Police",
          "Parking",
        ],
        y: [
          this.overpassData.college.length,
          this.overpassData.cafe.length,
          this.overpassData.atm.length,
          this.overpassData.bank.length,
          this.overpassData.bar.length,
          this.overpassData.school.length,
          this.overpassData.bus_station.length,
          this.overpassData.taxi.length,
          this.overpassData.fuel.length,
          this.overpassData.pharmacy.length,
          this.overpassData.hospital.length,
          this.overpassData.clinic.length,
          this.overpassData.childcare.length,
          this.overpassData.police.length,
          this.overpassData.parking.length,
        ],
        type: "bar",
        width: [
          0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5, 0.5,
          0.5,
        ],
        marker: {
          color: [
            "#003f5c",
            "#2f4b7c",
            "#665191",
            "#a05195",
            "#d45087",
            "#f95d6a",
            "#ff7c43",
            "#ffa600",
            "#2EBD54",
            "#102fa8",
            "#086d89",
            "#9dc10b",
            "#490582",
            "#1e1310",
            "#A10035",
          ],
        },
      },
    ];
    const layout2 = { width: 300, margin: { l: 0, r: 0 } };

    PlotlyJS.newPlot(document.getElementById("chart"), graphData, layout2);
  }
}
