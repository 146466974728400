<div class="top">
  <h4>Notifications</h4>
  <ng-template [ngIf]="notifications.length>0">
    <mat-card (click)="read(notification.id)" [ngStyle]="{backgroundColor: notification.read?'white':'#6a99ae6e'}" *ngFor="let notification of notifications; let i=index">
      <mat-card-header>
        <mat-card-subtitle>
          {{notification.time}}
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <h4>{{notification.subject}}</h4>
        <p>
          <strong>
            Triggered by: {{notification.sender.name}}
          </strong>
        </p>
        <p>{{notification.message}}</p>
      </mat-card-content>
    </mat-card>
  </ng-template>
  <mat-card *ngIf="notifications.length==0">
    <mat-card-header>
      <mat-card-title>
        Currently there are no notifications
      </mat-card-title>
    </mat-card-header>
  </mat-card>
</div>