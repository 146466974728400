import { ElementRef, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AiMapService } from "./map-ai-service";
import { aiprojectData } from "../models/aimap.interface";
import { environment } from "src/environments/environment";
import { Subject } from "rxjs";
import { HexagonLayer } from "@deck.gl/aggregation-layers";
import { MapboxLayer } from "@deck.gl/mapbox";
import * as mapboxgl from "mapbox-gl";
import { ScatterplotLayer, GeoJsonLayer, IconLayer } from "@deck.gl/layers";
import { HeatmapLayer } from "@deck.gl/aggregation-layers";
import { Feature } from "@turf/helpers";
import { bbox, center } from "@turf/turf";
import { geoIcon, legendColor } from "./icons";
const backendURL = environment.apiURL;
import * as ColorConvert from "color-convert";
import { Analysislayer } from "./nav/module-utility.interface";
import * as normalize from "normalize-value";
import * as Tiny from "tinygradient";

@Injectable({ providedIn: "root" })
export class CreateMapService {
  constructor(
    private http: HttpClient,
    private snackbar: MatSnackBar,
    private mapService: AiMapService
  ) {}

  private project: aiprojectData;
  private geophyBulkData: any;
  userDataLayer: L.FeatureGroup;
  reportLayer: L.FeatureGroup;
  reportData: any;
  helibuildings;
  heat;
  pieChart: any;
  reportGraph: any;
  mapStyleData: Analysislayer;
  mapStyle:
    | "ColorCoded"
    | "ColorWithDot"
    | "ColoredCircle"
    | "SizedCircle"
    | "HeatMap"
    | "Clustering"
    | "3D" = "ColoredCircle";
  map: mapboxgl.Map;
  areaDataRecived: Subject<any> = new Subject();
  reportDataRecived: Subject<any> = new Subject();
  reportRecived: Subject<any> = new Subject();
  legendColor: Subject<any> = new Subject();
  readyData: any;
  popupDiv: any;
  legendColorsArr: Subject<any> = new Subject();
  geoIconsUrl = geoIcon;
  gradientColors;

  onInit(map: mapboxgl.Map) {
    this.map = map;
    this.project = this.mapService.project;
    console.log(this.project);
  }

  reportPopupDivInit(popupElement: ElementRef<HTMLDivElement>) {
    this.popupDiv = popupElement.nativeElement;
  }

  getSinglyAttributedData(attr, type?: string) {
    if (type == "Address") {
      let dataG = [];
      this.geophyBulkData["DataWithBoundary"].map((data) => {
        const d = {};
        d[attr.keys[0]] = data[attr.keys[0]];
        d["boundary"] = data.boundary_url;
        d["address"] = data["Address"];
        dataG.push(d);
      });
      return dataG;
    } else {
      return this.geophyBulkData;
    }
  }

// <<<<<<< HEAD
//   removeLayer(i){
//     if(this.map.getLayer('layer-'+i)){
//       this.map.removeLayer('layer-'+i);
// =======
  removeLayer(i) {
    console.log("layer-" + i);

    if (this.map.getLayer("layer-" + i)) {
      this.map.removeLayer("layer-" + i);
// >>>>>>> feature/geoprocessingHeliAi
    }
  }

  removeIsochronelayer(i) {
    if (this.map.getLayer("isochrone-layer-" + i)) {
      this.map.removeLayer("isochrone-layer-" + i);
      this.map.removeSource("isochrone" + i);
    }
  }

// <<<<<<< HEAD
//   toggleVisibility(layer,visible){
//     (this.map.getLayer(layer) as any).implementation.setProps({visible: visible})
// =======
  removePoiLayer(i) {
    if (this.map.getLayer("poi-layer-" + i)) {
      this.map.removeLayer("poi-layer-" + i);
      this.map.removeSource("poidata" + i);
    }
  }

  toggleVisibility(layer, visible) {
    // console.log((this.map.getLayer(layer) as any).implementation);
    (this.map.getLayer(layer) as any).implementation.setProps({
      visible: visible,
    });
// >>>>>>> feature/geoprocessingHeliAi
  }

  setTooltip(object, x, y, info?: any) {
    if (object && object.properties) {
      this.popupDiv.innerHTML = "";
      let keys = Object.keys(object.properties);
      keys.map((e) => {
        this.popupDiv.innerHTML += `${e} :  ${object.properties[e]} <br>`;
      });
      this.popupDiv.style.display = "block";
      this.popupDiv.style.left = x + "px";
      this.popupDiv.style.top = y + "px";
      this.popupDiv.style.padding = "15px";
      this.popupDiv.style.fontSize = "12px";

      this.popupDiv.style.backgroundColor = "#eeeeee";
      this.popupDiv.style.border = "1px solid #000";
    } else {
      this.popupDiv.style.display = "none";
    }
  }

  setTooltipanalysis(object, x, y) {
    if (object) {
      this.popupDiv.innerHTML = "";
      let keys = Object.keys(object.properties);
      keys.map((e) => {
        if (e !== "latitude" && e !== "longitude" && e !== "fillColor")
          this.popupDiv.innerHTML += `${e} :  ${object.properties[e]} <br>`;
      });
      this.popupDiv.style.display = "block";
      this.popupDiv.style.left = x + "px";
      this.popupDiv.style.top = y + "px";
      this.popupDiv.style.padding = "15px";
      this.popupDiv.style.fontSize = "12px";

      this.popupDiv.style.backgroundColor = "#eeeeee";
      this.popupDiv.style.border = "1px solid #000";
    } else {
      this.popupDiv.style.display = "none";
    }
  }
  //asyncStyle(layerDtaas)
  async applyStyle(
    layerData?: any,
    data?: any,
    el?: any,
    index?: string,
    legendCol?: any
  ) {
    console.log(layerData);
    console.log(legendCol);

// <<<<<<< HEAD
  // async applyStyle(layerData?:any,data?:any, el?:any,index?:string) {
  //   if(el) this.popupDiv = el;
// =======
    if (el) this.popupDiv = el;
// >>>>>>> feature/geoprocessingHeliAi
    this.mapStyleData = layerData;

    this.readyData = data;
// <<<<<<< HEAD
// =======
    // this.gradientColors = new Tiny(legendColor[0]).rgb(layerData.legendCount);
    // this.gradientColors.map((c,i)=>{
    //   this.gradientColors[i] = c.toHexString();
    // })
    // console.log(this.gradientColors)
// >>>>>>> feature/geoprocessingHeliAi

    this.gradientColors = [];
    if (legendCol == undefined) {
      legendColor.map((arr) => {
        const tc = new Tiny(arr)
          .rgb(layerData.legendCount)
          .map((c) => c.toHexString());
        this.gradientColors.push(tc);
      });
    } else {
      if (legendCol.length == 0) {
        legendColor.map((arr) => {
          const tc = new Tiny(arr)
            .rgb(layerData.legendCount)
            .map((c) => c.toHexString());
          this.gradientColors.push(tc);
        });
      } else {
        legendCol.map((arr) => {
          const tc = new Tiny(arr)
            .rgb(layerData.legendCount)
            .map((c) => c.toHexString());
          this.gradientColors.push(tc);
        });
      }
    }
    if (layerData.type == "Geojson") {
      data.features.map((v) => {
        v.properties["fillColor"] = this.getColorSingle(
          v.properties[layerData.column],
          layerData.legendColorIndex ? layerData.legendColorIndex : 0
        );
      });
    } else {
      data.map((v) => {
        v["fillColor"] = this.getColorSingle(
          v[layerData.column],
          layerData.legendColorIndex ? layerData.legendColorIndex : 0
        );
      });
    }

    if (this.map?.getLayer("layer-" + (index ? index : layerData.index)))
      this.map.removeLayer("layer-" + (index ? index : layerData.index));
    let userD: any = { type: "FeatureCollection", features: [] };
    let keys = [];
    console.log(layerData);

    switch (layerData.style) {
      case "ColorCoded":
        break;
      case "ColoredCircle":
// <<<<<<< HEAD
//         if(layerData.type == 'Geojson'){
// =======
        if (layerData.type == "Geojson") {
          console.log("cfbgfdhdthdtyhtyd");
          console.log(layerData.active);

// >>>>>>> feature/geoprocessingHeliAi
          let bulkLayer = new MapboxLayer({
            id: "layer-" + (index ? index : layerData.index),
            filter: ["==", "extrude", "true"],
            type: ScatterplotLayer,
            data: this.readyData.features,
            opacity: 0.8,
            // radiusScale: 100,
            radiusMinPixels: layerData.unit ? layerData.unit : 10,
            // wrapLongitude: true,
// <<<<<<< HEAD
//             pickable: layerData.active? true : false,
//             getPosition: d => getPosition(d),
//             getRadius: d => 2,
//             getFillColor: d => d.properties.fillColor,
//             onHover: info => this.setTooltipanalysis (info.object, info.x, info.y)
//           })
// =======
// >>>>>>> feature/geoprocessingHeliAi

            pickable: layerData.active ? true : false,
            getPosition: (d) => getPosition(d),
            getRadius: (d) => 2,
            getFillColor: (d) => d.properties.fillColor,
            onHover: (info) =>
              this.setTooltipanalysis(info.object, info.x, info.y),
          });

          let getPosition = (d: Feature) => {
            if (d.geometry.type == "Point") {
              return d.geometry.coordinates;
            } else {
              return center(d).geometry.coordinates;
            }
          };

          let bb: any = bbox(this.readyData);
          this.map.fitBounds(bb);
          this.map.addLayer(bulkLayer);
        }
        break;
      case "SizedCircle":
        console.log(layerData.active);
        let bulkLayer2 = new MapboxLayer({
          id: "layer-" + (index ? index : layerData.index),
          filter: ["==", "extrude", "true"],
          type: ScatterplotLayer,
          data:
            layerData.type == "Geojson"
              ? this.readyData.features
              : this.readyData,
          opacity: 0.8,
          radiusScale: 1,
          radiusUnits: "pixels",
          wrapLongitude: true,
          pickable: layerData.active ? true : false,
          getPosition: (d) =>
            layerData.type == "Geojson"
              ? center(d).geometry.coordinates
              : [
                  d[layerData.locationkeys.longitude],
                  d[layerData.locationkeys.latitude],
                  0,
                ],
          getRadius: (d) =>
            layerData.type == "Geojson"
              ? getRadiusSize(d.properties[this.mapStyleData.column], 20)
              : getRadiusSize(d[this.mapStyleData.column]),
          getFillColor: (d) =>
            layerData.type == "Geojson" ? d.properties.fillColor : d.fillColor,
          onHover: (info) =>
            this.setTooltipanalysis(info.object, info.x, info.y),
        });

        let getRadiusSize = (d, i?: any) => {
          const nr = normalize(d, [
            { value: layerData.min, norm: 5 },
            { value: layerData.max, norm: 15 },
          ]);
          return nr;
        };

        let bbs: any = bbox(this.readyData);
        this.map.fitBounds(bbs);
        this.map.addLayer(bulkLayer2);

        break;
      case "HeatMap":
// <<<<<<< HEAD
//       let bulkLayer1 = new MapboxLayer({
//         id: 'layer-'+(index?index:layerData.index),
//         type: HeatmapLayer,
//         data: layerData.type == 'Geojson'? this.readyData.features:this.readyData,
//         pickable: layerData.active? true : false,
//         getPosition: d => layerData.type == 'Geojson'? center(d).geometry.coordinates : [d[layerData.locationkeys.longitude], d[layerData.locationkeys.latitude]],
//         getWeight: d => layerData.type == 'Geojson'?getRadiusSizeH(d.properties[this.mapStyleData.column],20): getRadiusSize(d[this.mapStyleData.column]),
//         radiusPixels:30,
//         intensity:1,
//         threshold:0.5        
//       })
// =======
        let bulkLayer1 = new MapboxLayer({
          id: "layer-" + (index ? index : layerData.index),
          type: HeatmapLayer,
          data:
            layerData.type == "Geojson"
              ? this.readyData.features
              : this.readyData,
          pickable: false,
          getPosition: (d) =>
            layerData.type == "Geojson"
              ? center(d).geometry.coordinates
              : [
                  d[layerData.locationkeys.longitude],
                  d[layerData.locationkeys.latitude],
                ],
          getWeight: (d) =>
            layerData.type == "Geojson"
              ? getRadiusSizeH(d.properties[this.mapStyleData.column], 20)
              : getRadiusSize(d[this.mapStyleData.column]),
          radiusPixels: 30,
          intensity: 1,
          threshold: 0.5,
        });
// >>>>>>> feature/geoprocessingHeliAi

        let getRadiusSizeH = (d, i?: any) => {
          const nr = normalize(d, [
            { value: layerData.min, norm: 5 },
            { value: layerData.max, norm: 15 },
          ]);
          return nr;
        };

        this.map.addLayer(bulkLayer1);
        break;
      case "Clustering":
        if (this.map.getSource("clusterSource"))
          this.map.removeSource("clusterSource");

        for (let i = 0; i < this.readyData.length; i++) {
          let content = {
            type: "Feature",
            properties: this.readyData[i],
            geometry: {
              type: "Point",
              coordinates: [
                this.readyData[i][layerData.locationkeys.longitude],
                this.readyData[i][layerData.locationkeys.latitude],
              ],
            },
          };
          userD.features.push(content);
        }
        this.map.addSource("clusterSource", {
          type: "geojson",
          data: layerData.type == "Geojson" ? this.readyData : userD,
          cluster: true,
          clusterMaxZoom: 12, // Max zoom to cluster points on
          clusterRadius: 10,
        });

        this.map.addLayer({
          id: "layer-" + layerData.index,
          type: "circle",
          source: "clusterSource",
          paint: {
            "circle-color": ["get", "fillColor"],
            "circle-radius": [
              "step",
              ["get", this.mapStyleData.column],
              20,
              50,
              30,
              100,
              40,
            ],
          },
        });

        let bbe: any = bbox(this.readyData);
        this.map.fitBounds(bbe);
        break;

      case "3D":
        const COLOR_RANGE = [
          [1, 152, 189],
          [73, 227, 206],
          [216, 254, 181],
          [254, 237, 177],
          [254, 173, 84],
          [209, 55, 78],
        ];

        // const COLOR_RANGE = legendColor[layerData.legendColorIndex?layerData.legendColorIndex:0];

        COLOR_RANGE.map((c, i) => {
          let h = this.hexTorgbCovert(c);
          COLOR_RANGE[i] = h;
          // this.hexTorgbCovert(c);
        });
        // COLOR_RANGE.push(COLOR_RANGE[0])
// <<<<<<< HEAD
// =======
        console.log(COLOR_RANGE);
        // console.log(this.readyData)
// >>>>>>> feature/geoprocessingHeliAi

        const hexagonLayer = new MapboxLayer({
          id: "layer-" + (index ? index : layerData.index),
          type: HexagonLayer,
          data:
            layerData.type == "Geojson"
              ? this.readyData.features
              : this.readyData,
          getPosition: (d) =>
            layerData.type == "Geojson"
              ? center(d).geometry.coordinates
              : [
                  d[layerData.locationkeys.longitude],
                  d[layerData.locationkeys.latitude],
                ],
          // getColorValue: d => d.fillColor,
          radius: 100,
          // radiusScale: 2,
          radiusMinPixels: 10,
          pickable: layerData.active ? true : false,
          extruded: true,
          upperPercentile: 100,
          colorRange: COLOR_RANGE,
          elevationRange: [0, 2000],
          elevationScale: 1,
          getElevationValue: (points) => {
            if (layerData.type == "Geojson") {
              const nr = normalize(
                points[0].properties[this.mapStyleData.column],
                [
                  { value: layerData.min, norm: 50 },
                  { value: layerData.max, norm: 200 },
                ]
              );
              return nr;
            }
          },
          onHover: (info) =>
            this.setTooltipanalysis(info.object, info.x, info.y),
        });
        let bbes: any = bbox(this.readyData);
        this.map.fitBounds(bbes);
        this.map.addLayer(hexagonLayer);
        break;
      case "3D-Geojson":
        let geoLayer = new MapboxLayer({
          id: "layer-" + (index ? index : layerData.index),
          type: GeoJsonLayer,
          data: this.readyData,
// <<<<<<< HEAD
//           pointRadiusMinPixels:2,
// =======
          pointRadiusMinPixels: 5,
// >>>>>>> feature/geoprocessingHeliAi
          // autoHighlight: true,
          sizeUnits: "pixels",
          // highlightColor: [0, 128, 255],
          // lineWidthScale: 7,
          lineWidthMinPixels: 1,
          // lineWidthMaxPixels:2,
          lineJointRounded: true,
          stroked: true,
          filled: true,
          lineBillboard: true,
          // extruded: true,
          // wireframe: true,
          // outline: true,
          // getElevation: d=> d.properties.height?d.properties.height:0,
          // getElevation: d=> d.properties[this.mapStyleData.column]?10*(d.properties[this.mapStyleData.column]):0,
          getFillColor: (d) =>
            layerData.type == "Geojson" ? d.properties.fillColor : d.fillColor,
          getLineColor: layerData.strokeColor
            ? this.hexTorgbCovert(layerData.strokeColor)
            : [57, 57, 57, 255],
          getLineWidth: 1,
          getRadius: 2,
// <<<<<<< HEAD
//           pickable: layerData.active? true : false,
//           onHover: info => this.setTooltip(info.object, info.x, info.y,info)
//         })
    
//         let bb:any =  bbox(this.readyData);
// =======
          pickable: layerData.active ? true : false,
          onHover: (info) => this.setTooltip(info.object, info.x, info.y, info),
        });

        // console.log(data)
        let bb: any = bbox(this.readyData);
// >>>>>>> feature/geoprocessingHeliAi
        this.map.addLayer(geoLayer);
        this.map.fitBounds(bb);
        break;
      case "Icon":
        let textLayer = new MapboxLayer({
          id: "layer-" + (index ? index : layerData.index),
          type: IconLayer,
          data: this.readyData.features,
          sizeUnits: "pixels",
          pickable: layerData.active ? true : false,
          getPosition: (d) => [...center(d).geometry.coordinates, 2],
          getIcon: (d) => ({
            url: this.geoIconsUrl[layerData.iconIndex],
            width: 128,
            height: 128,
            anchorY: 128,
          }),
          sizeScale: 15,
          // billboard: false,
          getSize: (d) => geticonSize(d),
          getTextAnchor: "middle",
          getAlignmentBaseline: "center",
          onHover: (info) =>
            this.setTooltipanalysis(info.object, info.x, info.y),
        });

        let geticonSize = (d) => {
          const nr = normalize(d.properties[layerData.column], [
            { value: layerData.min, norm: 0.5 },
            { value: layerData.max, norm: 2.5 },
          ]);
          return nr;
        };

        if (this.map.getLayer("layer-" + layerData.index))
          this.map.removeLayer("layer-" + layerData.index);
        let bbei: any = bbox(this.readyData);
        this.map.fitBounds(bbei);
        this.map.addLayer(textLayer);
        break;

      default:
    }
    // this.genrateReport(this.pieChart)
  }

// <<<<<<< HEAD
//   async applyStyleString(layerData?:any,data?:any, el?:any, index?:string){
//     if(el) this.popupDiv = el;
// =======
  async applyStyleString(
    layerData?: any,
    data?: any,
    el?: any,
    index?: string,
    colorLegend?: any
  ) {
    console.log(
      layerData,
      data,
      el,
      "layer-" + index,
      "layer-" + (index ? index : layerData.index)
    );
    if (el) this.popupDiv = el;
// >>>>>>> feature/geoprocessingHeliAi
    this.mapStyleData = layerData;
    this.readyData = data;
    this.gradientColors = [];
    if (colorLegend == undefined) {
      legendColor.map((arr) => {
        const tc = new Tiny(arr)
          .rgb(layerData.textValues.length)
          .map((c) => c.toHexString());
        this.gradientColors.push(tc);
      });
    } else {
      if (colorLegend.length == 0) {
        legendColor.map((arr) => {
          const tc = new Tiny(arr)
            .rgb(layerData.textValues.length)
            .map((c) => c.toHexString());
          this.gradientColors.push(tc);
        });
      } else {
        colorLegend.map((arr) => {
          const tc = new Tiny(arr)
            .rgb(layerData.textValues.length)
            .map((c) => c.toHexString());
          this.gradientColors.push(tc);
        });
      }
    }

    if (layerData.type == "Geojson") {
      data.features.map((v) => {
        v.properties["fillColor"] = this.getColorSingleString(
          v.properties[layerData.column],
          layerData.legendColorIndex ? layerData.legendColorIndex : 0
        );
      });
    } else {
      data.map((v) => {
        v["fillColor"] = this.getColorSingleString(
          v[layerData.column],
          layerData.legendColorIndex ? layerData.legendColorIndex : 0
        );
      });
    }

    if (this.map?.getLayer("layer-" + layerData.index))
      this.map.removeLayer("layer-" + layerData.index);
    let userD: any = { type: "FeatureCollection", features: [] };
    let keys = [];
    switch (layerData.style) {
      case "ColorCoded":
        break;
      case "ColoredCircle":
// <<<<<<< HEAD
//         if(layerData.type == 'Geojson'){
// =======
        if (layerData.type == "Geojson") {
          console.log(layerData.active);
          // console.log(this.readyData)
// >>>>>>> feature/geoprocessingHeliAi
          let bulkLayer = new MapboxLayer({
            id: "layer-" + (index ? index : layerData.index),
            filter: ["==", "extrude", "true"],
            type: ScatterplotLayer,
            data: this.readyData.features,
            opacity: 0.8,
            // radiusScale: 100,
            radiusMinPixels: layerData.unit ? layerData.unit : 4,
            // wrapLongitude: true,
            pickable: layerData.active ? true : false,
            getPosition: (d) => getPosition(d),
            getRadius: (d) => 2,
            getFillColor: (d) => d.properties.fillColor,
            onHover: (info) => setTooltip(info.object, info.x, info.y),
          });

          let getPosition = (d: Feature) => {
            if (d.geometry.type == "Point") {
              return d.geometry.coordinates;
            } else {
              return center(d).geometry.coordinates;
            }
          };

          let setTooltip = (object, x, y) => {
            if (object) {
              this.popupDiv.innerHTML = "";
              let keys = Object.keys(object.properties);
              keys.map((e) => {
                if (e !== "latitude" && e !== "longitude" && e !== "fillColor")
                  this.popupDiv.innerHTML += `${e} :  ${object.properties[e]} <br>`;
              });
              this.popupDiv.style.display = "block";
              this.popupDiv.style.fontSize = "12px";

              this.popupDiv.style.left = x + "px";
              this.popupDiv.style.top = y + "px";
              this.popupDiv.style.padding = "15px";
              this.popupDiv.style.backgroundColor = "#eeeeee";
              this.popupDiv.style.border = "1px solid #000";
            } else {
              this.popupDiv.style.display = "none";
            }
          };
          let bb: any = bbox(this.readyData);
          this.map.fitBounds(bb);
          this.map.addLayer(bulkLayer);
        }
        break;
      case "SizedCircle":
        console.log(layerData.active);
        let bulkLayer2 = new MapboxLayer({
          id: "layer-" + layerData.index,
          filter: ["==", "extrude", "true"],
          type: ScatterplotLayer,
          data:
            layerData.type == "Geojson"
              ? this.readyData.features
              : this.readyData,
          opacity: 0.8,
          radiusScale: 2,
          // radiusMinPixels: 1,
          radiusMinPixels: layerData.unit ? layerData.unit : 4,

          wrapLongitude: true,
          pickable: true,
          getPosition: (d) =>
            layerData.type == "Geojson"
              ? center(d).geometry.coordinates
              : [
                  d[layerData.locationkeys.longitude],
                  d[layerData.locationkeys.latitude],
                  0,
                ],
          getRadius: (d) =>
            layerData.type == "Geojson"
              ? getRadiusSize(d.properties[this.mapStyleData.column], 20)
              : getRadiusSize(d[this.mapStyleData.column]),
          getFillColor: (d) =>
            layerData.type == "Geojson" ? d.properties.fillColor : d.fillColor,
          // getColor: d => d.fillColor,
          onHover: (info) => setTooltip2(info.object, info.x, info.y),
        });

        let getRadiusSize = (d, i?: any) => {
          if (this.mapStyleData.max - this.mapStyleData.min < 1000) {
            return (+((d - this.mapStyleData.min) as any) * i) / 10;
          } else if (this.mapStyleData.max - this.mapStyleData.min < 10000) {
            return (+((d - this.mapStyleData.min) as any) * i) / 100;
          } else if (this.mapStyleData.max - this.mapStyleData.min < 100000) {
            return (+((d - this.mapStyleData.min) as any) * i) / 1000;
          } else if (this.mapStyleData.max - this.mapStyleData.min < 1000000) {
            return (+((d - this.mapStyleData.min) as any) * i) / 10000;
          } else if (this.mapStyleData.max - this.mapStyleData.min > 1000000) {
            return (+((d - this.mapStyleData.min) as any) * i) / 100000;
          }
        };

        let setTooltip2 = (object, x, y) => {
          if (object) {
            this.popupDiv.innerHTML = "";
            let keys = Object.keys(object);
            keys.map((e) => {
              if (e !== "latitude" && e !== "longitude" && e !== "fillColor")
                this.popupDiv.innerHTML += `${e} :  ${object[e]} <br>`;
            });
            this.popupDiv.style.display = "block";
            this.popupDiv.style.left = x + "px";
            this.popupDiv.style.top = y + "px";
            this.popupDiv.style.padding = "15px";
            this.popupDiv.style.fontSize = "12px";

            this.popupDiv.style.backgroundColor = "#eeeeee";
            this.popupDiv.style.border = "1px solid #000";
          } else {
            this.popupDiv.style.display = "none";
          }
        };

        this.map.addLayer(bulkLayer2);

        break;
      case "HeatMap":
        let bulkLayer1 = new MapboxLayer({
          id: "layer-" + layerData.index,
          type: HeatmapLayer,
          data:
            layerData.type == "Geojson"
              ? this.readyData.features
              : this.readyData,
          pickable: false,
          getPosition: (d) =>
            layerData.type == "Geojson"
              ? center(d).geometry.coordinates
              : [
                  d[layerData.locationkeys.longitude],
                  d[layerData.locationkeys.latitude],
                ],
          getWeight: (d) =>
            layerData.type == "Geojson"
              ? getRadiusSizeH(d.properties[this.mapStyleData.column], 20)
              : getRadiusSize(d[this.mapStyleData.column]),
          radiusPixels: 30,
          intensity: 1,
          threshold: 0.5,
        });

        let getRadiusSizeH = (d, i?: any) => {
          if (this.mapStyleData.max - this.mapStyleData.min < 1000) {
            return (+((d - this.mapStyleData.min) as any) * i) / 10;
          } else if (this.mapStyleData.max - this.mapStyleData.min < 10000) {
            return (+((d - this.mapStyleData.min) as any) * i) / 100;
          } else if (this.mapStyleData.max - this.mapStyleData.min < 100000) {
            return (+((d - this.mapStyleData.min) as any) * i) / 1000;
          } else if (this.mapStyleData.max - this.mapStyleData.min < 1000000) {
            return (+((d - this.mapStyleData.min) as any) * i) / 10000;
          } else if (this.mapStyleData.max - this.mapStyleData.min > 1000000) {
            return (+((d - this.mapStyleData.min) as any) * i) / 100000;
          }
        };

        this.map.addLayer(bulkLayer1);
        break;
      case "Clustering":
        if (this.map.getSource("clusterSource"))
          this.map.removeSource("clusterSource");

        for (let i = 0; i < this.readyData.length; i++) {
          let content = {
            type: "Feature",
            properties: this.readyData[i],
            geometry: {
              type: "Point",
              coordinates: [
                this.readyData[i][layerData.locationkeys.longitude],
                this.readyData[i][layerData.locationkeys.latitude],
              ],
            },
          };
          userD.features.push(content);
        }
        this.map.addSource("clusterSource", {
          type: "geojson",
          data: layerData.type == "Geojson" ? this.readyData : userD,
          cluster: true,
          clusterMaxZoom: 12, // Max zoom to cluster points on
          clusterRadius: 10,
        });

        this.map.addLayer({
          id: "layer-" + layerData.index,
          type: "circle",
          source: "clusterSource",
          paint: {
            "circle-color": ["get", "fillColor"],
            "circle-radius": [
              "step",
              ["get", this.mapStyleData.column],
              20,
              50,
              30,
              100,
              40,
            ],
          },
        });

        break;

      case "3D":
        const COLOR_RANGE = [
          [1, 152, 189],
          [73, 227, 206],
          [216, 254, 181],
          [254, 237, 177],
          [254, 173, 84],
          [209, 55, 78],
        ];

        // const COLOR_RANGE = legendColor[layerData.legendColorIndex?layerData.legendColorIndex:0];

        COLOR_RANGE.map((c, i) => {
          let h = this.hexTorgbCovert(c);
          COLOR_RANGE[i] = h;
          // this.hexTorgbCovert(c);
        });
        // COLOR_RANGE.push(COLOR_RANGE[0])
// <<<<<<< HEAD
// =======
        console.log(COLOR_RANGE);
        // console.log(this.readyData)

// >>>>>>> feature/geoprocessingHeliAi
        const hexagonLayer = new MapboxLayer({
          id: "layer-" + layerData.index,
          type: HexagonLayer,
          data:
            layerData.type == "Geojson"
              ? this.readyData.features
              : this.readyData,
          getPosition: (d) =>
            layerData.type == "Geojson"
              ? center(d).geometry.coordinates
              : [
                  d[layerData.locationkeys.longitude],
                  d[layerData.locationkeys.latitude],
                ],
          // getColorValue: d => d.fillColor,
          radius: 30,
          // radiusScale: 2,
          // radiusMinPixels: 1,
          pickable: true,
          extruded: true,
          upperPercentile: 100,
          colorRange: COLOR_RANGE,
          elevationRange: [0, 2000],
          elevationScale: 1,
          getElevationValue: (points) => {
            if (layerData.type == "Geojson") {
              if (this.mapStyleData.max - this.mapStyleData.min < 1000) {
                return points[0].properties[this.mapStyleData.column] / 100;
              } else if (
                this.mapStyleData.max - this.mapStyleData.min <
                10000
              ) {
                return points[0].properties[this.mapStyleData.column] / 1000;
              } else if (
                this.mapStyleData.max - this.mapStyleData.min <
                100000
              ) {
                return points[0].properties[this.mapStyleData.column] / 10000;
              } else if (
                this.mapStyleData.max - this.mapStyleData.min <
                1000000
              ) {
                return points[0].properties[this.mapStyleData.column] / 100000;
              } else if (
                this.mapStyleData.max - this.mapStyleData.min >
                1000000
              ) {
                return (
                  points[0].properties[this.mapStyleData.column] / 10000000
                );
              }
            } else {
              if (this.mapStyleData.max - this.mapStyleData.min < 1000) {
                return (
                  (points[0][this.mapStyleData.column] -
                    this.mapStyleData.min) /
                  50
                );
              } else if (
                this.mapStyleData.max - this.mapStyleData.min <
                10000
              ) {
                return (
                  (points[0][this.mapStyleData.column] -
                    this.mapStyleData.min) /
                  100
                );
              } else if (
                this.mapStyleData.max - this.mapStyleData.min <
                100000
              ) {
                return (
                  (points[0][this.mapStyleData.column] -
                    this.mapStyleData.min) /
                  1000
                );
              } else if (
                this.mapStyleData.max - this.mapStyleData.min <
                1000000
              ) {
                return (
                  (points[0][this.mapStyleData.column] -
                    this.mapStyleData.min) /
                  10000
                );
              } else if (
                this.mapStyleData.max - this.mapStyleData.min >
                1000000
              ) {
                return (
                  (points[0][this.mapStyleData.column] -
                    this.mapStyleData.min) /
                  100000
                );
              }
            }
          },
          onHover: (info) => setTooltip1(info.object, info.x, info.y),
        });

        let setTooltip1 = (object, x, y) => {
          if (object) {
            this.popupDiv.innerHTML = "";
            let keys = Object.keys(object.points[0].source);
            keys.map((e) => {
              if (e !== "latitude" && e !== "longitude" && e !== "fillColor")
                this.popupDiv.innerHTML += `${e} :  ${object.points[0].source[e]} <br>`;
            });
            this.popupDiv.style.display = "block";
            this.popupDiv.style.left = x + "px";
            this.popupDiv.style.top = y + "px";
            this.popupDiv.style.padding = "15px";
            this.popupDiv.style.fontSize = "11px";

            this.popupDiv.style.backgroundColor = "#eeeeee";
            this.popupDiv.style.border = "1px solid #000";
          } else {
            this.popupDiv.style.display = "none";
          }
        };
        this.map.addLayer(hexagonLayer);
        break;
      case "3D-Geojson":
        let geoLayer = new MapboxLayer({
          id: "layer-" + (index ? index : layerData.index),
          type: GeoJsonLayer,
          data: this.readyData,
          pointRadiusMinPixels: 5,
          // autoHighlight: true,
          sizeUnits: "pixels",
          // highlightColor: [0, 128, 255],
          stroked: true,
          filled: true,
          // extruded: true,
          // wireframe: true,
          outline: true,
          // getElevation: d=> d.properties.height?d.properties.height:0,
          // getElevation: d=> d.properties[this.mapStyleData.column]?10*(d.properties[this.mapStyleData.column]):0,
          getFillColor: (d) =>
            layerData.type == "Geojson" ? d.properties.fillColor : d.fillColor,
          getLineColor: layerData.strokeColor
            ? this.hexTorgbCovert(layerData.strokeColor)
            : [57, 57, 57, 255],
          getLineWidth: 50,
          // getLineWidth: geojson.strokeWidth?geojson.strokeWidth:2,
          // lineWidthMinPixels: geojson.strokeWidth?geojson.strokeWidth:2,
          getRadius: 2,
          pickable: true,
// <<<<<<< HEAD
//           onHover: info => this.setTooltip(info.object, info.x, info.y,info)
//         })
    
//         let bb:any =  bbox(this.readyData);
// =======
          onHover: (info) => this.setTooltip(info.object, info.x, info.y, info),
        });

        // console.log(data)
        let bb: any = bbox(this.readyData);
// >>>>>>> feature/geoprocessingHeliAi
        this.map.addLayer(geoLayer);
        this.map.fitBounds(bb);
        break;
      case "Icon":
        let textLayer = new MapboxLayer({
          id: "layer-" + (index ? index : layerData.index),
          type: IconLayer,
          data: this.readyData.features,
          sizeUnits: "pixels",
          getPosition: (d) => geticonpos(d),
          getIcon: (d) => ({
            url: this.geoIconsUrl[layerData.iconIndex],
            width: 128,
            height: 128,
            anchorY: 128,
          }),
          sizeScale: 15,
          getSize: (d) => geticonSize(d),
          getTextAnchor: "middle",
          getAlignmentBaseline: "center",
          pickable: true,
          onHover: (info) => this.setTooltip(info.object, info.x, info.y, info),
        });

// <<<<<<< HEAD
//           let textLayer = new MapboxLayer({
//             id: 'layer-'+(index?index:layerData.index),
//             type:IconLayer,
//             data: this.readyData.features,
//             sizeUnits: "pixels",
//             getPosition: d => geticonpos(d),
//             getIcon: d => ({
//               url: this.geoIconsUrl[layerData.iconIndex],
//               width: 128,
//               height: 128,
//               anchorY: 128
//             }),      
//             sizeScale: 15,
//             getSize:d => geticonSize(d),
//             getTextAnchor: 'middle',
//             getAlignmentBaseline: 'center',
//             pickable: true,
//             onHover: info => this.setTooltip(info.object, info.x, info.y,info)
//           })
      
//           let geticonSize = (f)=>{
//             let index = this.mapStyleData.textValues.findIndex(t=> t == (f.properties[this.mapStyleData.column]).toLowerCase()) ;
//             if(index !== -1){
//               return this.mapStyleData.iconValues[index];
//             }
//           }
      
//           let geticonpos = (f)=>{
//             let index = this.mapStyleData.textValues.findIndex(t=> t == f.properties[this.mapStyleData.column].toLowerCase()) ;
//             if(index !== -1){
//               return [...center(f).geometry.coordinates,5];
//             }
// =======
        let geticonSize = (f) => {
          let index = this.mapStyleData.textValues.findIndex(
            (t) => t == f.properties[this.mapStyleData.column].toLowerCase()
          );
          console.log(index);
          if (index !== -1) {
            console.log(this.mapStyleData.iconValues[index]);
            return this.mapStyleData.iconValues[index];
          }
        };

        let geticonpos = (f) => {
          let index = this.mapStyleData.textValues.findIndex(
            (t) => t == f.properties[this.mapStyleData.column].toLowerCase()
          );
          if (index !== -1) {
            console.log([...center(f).geometry.coordinates, 5]);
            return [...center(f).geometry.coordinates, 5];
// >>>>>>> feature/geoprocessingHeliAi
          }
        };

        if (this.map.getLayer("layer-" + layerData.index))
          this.map.removeLayer("layer-" + layerData.index);
        let bbe: any = bbox(this.readyData);
        this.map.fitBounds(bbe);
        this.map.addLayer(textLayer);

        break;

      default:
    }
  }

// <<<<<<< HEAD
//   getColorSingle(attr,legendIndex?:number,range?:boolean,){
//     for(let i = 0 ; i < this.mapStyleData.chartData.ranges.length ; i=i+2){
//       if(this.mapStyleData.chartData.ranges[i]?.toString() && this.mapStyleData.chartData.ranges[i+1]?.toString()){
//         if(attr > this.mapStyleData.chartData.ranges[i] - 0.000001 && attr <= this.mapStyleData.chartData.ranges[i+1]) 
//         return [...this.hexTorgbCovert(this.gradientColors[legendIndex][i/2]),this.mapStyleData.opacity?this.mapStyleData.opacity:255]
//       }
// =======
  getColorSingle(attr, legendIndex?: number, range?: boolean) {
    // console.log(attr, "hjgcud", legendIndex);
// >>>>>>> feature/geoprocessingHeliAi

    for (let i = 0; i < this.mapStyleData.chartData.ranges.length; i = i + 2) {
      if (
        this.mapStyleData.chartData.ranges[i]?.toString() &&
        this.mapStyleData.chartData.ranges[i + 1]?.toString()
      ) {
        // console.log(
        //   attr > this.mapStyleData.chartData.ranges[i] - 0.000001 &&
        //     attr <= this.mapStyleData.chartData.ranges[i + 1]
        // );
        if (
          attr > this.mapStyleData.chartData.ranges[i] - 0.000001 &&
          attr <= this.mapStyleData.chartData.ranges[i + 1]
        )
          return [
            ...this.hexTorgbCovert(this.gradientColors[legendIndex][i / 2]),
            this.mapStyleData.opacity ? this.mapStyleData.opacity : 255,
          ];
      }
    }
  }

// <<<<<<< HEAD
//   getColorSingleString(attr,legendIndex?:number){
//     let index = this.mapStyleData.textValues.findIndex(e=> e == attr.toLowerCase());
//     if(index != -1){
// =======
  getColorSingleString(attr, legendIndex?: number) {
    let index = this.mapStyleData.textValues.findIndex(
      (e) => e == attr.toLowerCase()
    );
    if (index != -1) {
// >>>>>>> feature/geoprocessingHeliAi
      return this.hexTorgbCovert(this.gradientColors[legendIndex][index]);
    } else {
      return this.hexTorgbCovert("#ffffff");
    }
  }

  hexTorgbCovert(color) {
    return ColorConvert.hex.rgb(color.split("#")[1]);
  }

  getReportPublic(reportId) {
    this.http
      .get<any>(backendURL + "/aimap/report/public/" + reportId)
      .subscribe((report) => {
        console.log(report);
        if (report) {
          console.log(report);

// <<<<<<< HEAD
//   getReportPublic(reportId){
//     this.http.get<any>(backendURL+'/aimap/report/public/'+reportId).subscribe(report =>{
//       if(report){
//         this.reportData = report.report;
//       this.reportRecived.next({...report.report,note:report.note,center:report.center});
//     }
//     })
//   }

//   getReportPrivate(reportId,index){
//     this.http.get<any>(backendURL+'/aimap/report/private/'+reportId).subscribe(report =>{
//       if(report){
//         this.reportData = report;
//       this.reportRecived.next({report: report, index: index});
//     }
//     })
// =======
          this.reportData = report.report;
          this.reportRecived.next({
            ...report.report,
            note: report.note,
            center: report.center,
          });
        }
      });
  }

  getLegendColors(projectId) {
    console.log(projectId);

    this.http
      .get<any>(backendURL + "/aimap/legendColor/" + projectId)
      .subscribe(async (data) => {
        console.log("data", data);
        this.legendColorsArr.next(data);
      });
// >>>>>>> feature/geoprocessingHeliAi
  }

  getReportPrivate(reportId, index) {
    console.log("repid", reportId);
    this.http
      .get<any>(backendURL + "/aimap/report/private/" + reportId)
      .subscribe((report) => {
        console.log(report);
        if (report) {
          console.log(report);

          this.reportData = report;
          this.reportRecived.next({ report: report, index: index });
        }
      });
  }

  getReport(reportId) {
    return this.http
      .get<any>(backendURL + "/aimap/report/private/" + reportId)
      .toPromise();
  }

  deleteReport(id, name) {
    console.log("id-" + id, "name-" + name);

    this.http
      .delete<any>(backendURL + "/aimap/report/" + id)
      .subscribe((res) => {
        console.log(res);

        // const project =  this.mapService.project;
        // project.report.map((rep,i)=>{
        //   if(rep == name) project.report.splice(i,1);
        // })
        // this.mapService.projectdataChanged.next(project);
      });
  }
}
