import { Injectable } from '@angular/core';
import { Site } from '../../models/sites.model';
import { HttpClient } from '@angular/common/http';
import { Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ServerService } from 'src/app/server.service';
import { MatSnackBar } from '@angular/material/snack-bar';


const BACKENDURL = environment.apiURL

@Injectable({
  providedIn: 'root'
})
export class SitesService {
  private Site: Site;
  // proData : Site;
  sites: Site[];
  sitesUpdated: Subject<Site[]>= new Subject()
  recentSiteCreated: Subject<Site> = new Subject();
  sitesChanged: Subject<Site> = new Subject();
  onceProjectChanged: Subject<Site> = new Subject();
  selectedSite: Subject<any> = new Subject()
  getSiteId: any;

  constructor(private server:ServerService, private http: HttpClient,private snackBar : MatSnackBar ) { }

  createSite(site: Site){
    this.http.post<{message: string, id: string}>(BACKENDURL+'/sites', site).subscribe(res=>{
      site.id = res.id;
      console.log(res)
      this.sites.push(site)
      this.recentSiteCreated.next(site)
      this.sitesUpdated.next(this.sites)
      this.snackBar.open(`Site Added Successfully!`, 'OK', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: ['green-snackbar', 'custom-snackbar']
      });
    },err=>{
    })
  }

  getProject( id: string){
    this.server.getSiteDatafromServer(id).subscribe(data=>{
      this.Site = data.Site;
      // this.proData = data.Site
      this.sitesChanged.next(this.Site)
      this.onceProjectChanged.next(this.Site)
    },err=>{
      console.log(err)
    })
  }

  updateProject(Site: Site, id: string){
    console.log(Site, id);
    this.server.updateSiteDataToServer(Site, id).subscribe(data=>{
      this.Site =Site;
      this.sitesChanged.next(this.Site);
      this.snackBar.open(`Site Updated Successfully!`, 'OK', {
        duration: 3000,
        verticalPosition: 'top',
        horizontalPosition: 'center',
        panelClass: ['green-snackbar', 'custom-snackbar']
      });
    },err=>{
    })
  }

  fetchSites(creatorid: string){
    this.http.get<Site[]>(BACKENDURL+'/sites/'+creatorid)
    .subscribe(res=>{
      console.log("iamfetchsites",res);
      this.sites = res
      this.sitesUpdated.next(this.sites)
    },err=>{
    })
  }

  fetchSitesData(creatorid: string){
    return this.http.get<Site[]>(BACKENDURL+'/sites/'+creatorid)
  }
  

  fetchSitesMCG(data){
    return this.http.post<any>(BACKENDURL+'/sites/mcg-user/' + data.id,data);
    // .subscribe(res=>{
    //   this.sites = res
    //   //this.sitesUpdated.next(this.sites)
    // },err=>{
    // })
  }


  uploadIMG(data: FormData){
    return this.server.uploadIMG(data)
  }



  uploadICONIMG(data: FormData){
    return this.server.uploadICONIMG(data)
  }

  uploadDoc(data: FormData){
    return this.server.uploadDocs(data)
  }
  // updateProject(projectData: projectData, id: string){
  //   this.server.updateGISDataToServer(projectData, id).subscribe(data=>{
  //     this.projectData =projectData;
  //     this.projectDataChanged.next(this.projectData);
  //   },err=>{
  //   })
  // }

  getSites(){
    console.log("iamsites.service 108",this.sites);
    return this.sites
  }

  getSite(id){
    return this.http.get<{message: string, site: Site}>(BACKENDURL+'/sites/single/'+id)
  }

  chartdata (data: any){
    console.log(data,"===>rrrr")
    return this.http.post<any>(BACKENDURL+'/gis/tempKmlData',data);
    // return this.http.post(`${BACKENDURL}/gis/tempKmlData`,data);
  }
}
