import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";
import { projectData } from "./models/projectData.interface";
import { Review } from "./models/review.model";
import { Dsm } from "./models/dsm.interface";
import { environment } from "src/environments/environment";
import { Pan } from "./models/pan.interface";
import { ProcProject } from "./models/proc-project.model";
import { WalkPan } from "./models/walk-pan.interface";
import { User } from "./models/users.model";
import { modelConfig } from "./models/model-config.interface";
import { aiprojectData } from "./models/aimap.interface";
import { Site } from "./models/sites.model";
import { StandardData } from "./models/standard-data.interface";
import { FeatureCollection } from "@turf/turf";

const BACKENDURL = environment.apiURL;
const AIBACKENDURL = environment.aiApiUrl;
const AIBACKENDURLOLD = environment.aiApiUrlOld;

@Injectable({
  providedIn: "root",
})
export class ServerService {
  getDroneData(data: any) {
    throw new Error('Method not implemented.');
  }

  constructor(private http: HttpClient) {}

  sendFlyUser(email: string) {
    return this.http.post<{ message: string }>(`${BACKENDURL}/fly`, { email });
  }
  //site

  getSiteDatafromServer(id: string) {
    return this.http.get<{ message: string; Site: Site }>(
      BACKENDURL + "/sites/" + id
    );
  }

  updateSiteDataToServer(Site: Site, id: string) {
    return this.http.put<{ message: string }>(
      BACKENDURL + "/sites/" + id,
      Site
    );
  }

  // GIS
  getGISDatafromServer(id: string) {
    return this.http.get<{ message: string; projectData: projectData }>(
      BACKENDURL + "/gis/" + id
    );
  }

  getGISProjects(siteid: string) {
    console.log(siteid,'siteid==========')
    return this.http.get<{
      [x: string]: string; id: string; image: string; item: string 
}[]>(
      BACKENDURL + "/gis/projects/" + siteid
    );
  }

  saveGISDatatoServer(projectData: projectData) {
    return this.http.post<projectData>(BACKENDURL + "/gis", projectData);
  }

  updateGISDataToServer(projectData: projectData, id: string) {
    return this.http.put<{ message: string; image: string }>(
      BACKENDURL + "/gis/" + id,
      projectData
    );
  }

  shareGISProject(data: {
    id: string;
    userid: string;
    accessControl: "canEdit" | "canView";
    message: string;
  }) {
    return this.http.post<{ message: string }>(`${BACKENDURL}/gis/share`, data);
  }

  unShareGISProject(data: {
    id: string;
    userid: string;
    accessControl: "canEdit" | "canView";
  }) {
    return this.http.post<{ message: string }>(
      `${BACKENDURL}/gis/unshare`,
      data
    );
  }

  getGISfromUserId(userid: string) {
    return this.http.post<{
      message: string;
      projects: { id: string; item: string }[];
    }>(`${BACKENDURL}/gis/projects/user`, { userid });
  }

  deleteGIS(id) {
    return this.http.delete<{ message: string }>(`${BACKENDURL}/gis/${id}`);
  }

  uploadKML(data: FormData) {
    return this.http.post<{ message: string; kmlurl: string }>(
      BACKENDURL + "/gis/kml",
      data
    );
  }

  uploadIMG(data: FormData) {
    console.log(data,'dataicon')
    return this.http.post<{ message: string; imgurl: string }>(
      BACKENDURL + "/gis/img",
      data
    );
  }

  uploadIMGLogo(data: FormData) {
    console.log(data,'dataicon')
    return this.http.post<{ message: string; imgurl: string }>(
      BACKENDURL + "/gis/img-logo",
      data
    );
  }


  uploadICONIMG(data: FormData) {
    console.log(data,'dataicons')
    return this.http.post<{ message: string; imgurl: string }>(
      BACKENDURL + "/gis/icon",
      data
    );

  }

  
  uploadDocs(data: FormData) {
    return this.http.post<{ message: string; imgurl: string }>(
      BACKENDURL + "/gis/docs",
      data
    );
  }

  uploadElevationJSON(file: File) {
    let data = new FormData();
    data.append("json", file);
    return this.http.post<{ message: string; jsonurl: string }>(
      BACKENDURL + "/gis/jsonelevation",
      data
    );
  }

  uploadBulkDataJSON(file: File) {
    let data = new FormData();
    data.append("json", file);
    return this.http.post<{ message: string; jsonurl: string }>(
      BACKENDURL + "/gis/jsonbulkdata",
      data
    );
  }

  // uploadSHP(data: FormData){
  //   return this.http.post<{message: string, shpurl: string}>(BACKENDURL+'/gis/shp', data)
  // }

  KMLGISExport(data) {
    return this.http.post<{ message: string; kml: string }>(
      `${BACKENDURL}/gis/tokml`,
      data
    );
  }

  // Reviews
  getReviews(projectid: string) {
    return this.http.get<{ message: string; reviews: Array<Review> }>(
      BACKENDURL + "/reviews/" + projectid
    );
  }

  createReview(review: Review) {
    return this.http.post<{ message: string; id: string }>(
      BACKENDURL + "/reviews",
      review
    );
  }

  delteteReview(id: string) {
    return this.http.delete<{ message: string }>(BACKENDURL + "/reviews/" + id);
  }

  uploadVideoSnapShot(data: FormData){
    return this.http.post(BACKENDURL+'/gis/videoSnapshot',data)
  }

  uploadExcel(data: FormData){
    return this.http.post(BACKENDURL+'/gis/excelUpload', data)
  }


  deleteVideoSnapShot(projectId:string, snapType:string, snapId: string){
    return this.http.delete(BACKENDURL+`/gis/videoSnapshot/${projectId}/${snapType}/${snapId}`)
  }

  getGroundVideos(projectId: string, layer: string) {
    return this.http.get(BACKENDURL+'/gis/groundvideos/'+ projectId + '?layer=' + layer)
  }

  // DSM
  createDSMProject(dsm: Dsm, mode: "MESH" | "PTS") {
    if (mode == "MESH")
      return this.http.post<{ message: string; id?: string }>(
        BACKENDURL + "/dsm/projects",
        dsm
      );
    if (mode == "PTS") {
      const formData = new FormData();
      Object.keys(dsm).forEach((k) => {
        // if(typeof dsm[k] == 'string'){
        //   formData.append(k, dsm[k])
        // }else{
        formData.append(k, JSON.stringify(dsm[k]));
        // }
      });
      return this.http.post<{ message: string; id?: string }>(
        AIBACKENDURLOLD + "/create_grid/",
        formData
      );
    }
  }

  // getDSMProjects(siteid: string){
  //   return this.http.get<Array<{id?: string, item: string, siteid: string, creatorid: string}>>(BACKENDURL+'/dsm/projects/'+siteid)
  //   return this.http.post<{message: string, id: string, image:string}>(BACKENDURL+'/dsm/projects', dsm)
  // }

  getDSMProjects(siteid: string) {
    return this.http.get<
      Array<{
        id: string;
        image: string;
        item: string;
        siteid: string;
        creatorid: string;
        processing?: boolean;
      }>
    >(BACKENDURL + "/dsm/projects/" + siteid);
  }

  fetchDSMProject(id: string) {
    return this.http.get<{ message: string; project: Dsm }>(
      BACKENDURL + "/dsm/projects/single/" + id
    );
  }

  updateDSMDataToServer(projectData: Dsm, id: string) {
    console.log(projectData);
    return this.http.put<{ message: string; image: string }>(
      BACKENDURL + "/dsm/edit/projects/" + id,
      projectData
    );
  }

  updateDSMProjects(dsm: Dsm) {
    return this.http.put<{ message: string; image: string }>(
      BACKENDURL + "/dsm/projects",
      dsm
    );
  }

  uploadFeatureDSM(f) {
    return this.http.put<{ message: string; image: string }>(
      BACKENDURL + "/dsm/addfeature",
      f
    );
  }

  // updateDSMProjects(dsm: Dsm, id:string){
  //   return this.http.put<{message: string, image: string}>(BACKENDURL+'/dsm/projects'+id, dsm)
  // }

  shareDSMProject(data: {
    id: string;
    userid: string;
    accessControl: "canEdit" | "canView";
    message: string;
  }) {
    return this.http.post<{ message: string }>(
      `${BACKENDURL}/dsm/projects/share`,
      data
    );
  }

  unShareDSMProject(data: {
    id: string;
    userid: string;
    accessControl: "canEdit" | "canView";
  }) {
    return this.http.post<{ message: string }>(
      `${BACKENDURL}/dsm/projects/unshare`,
      data
    );
  }

  getDSMfromUserId(userid: string) {
    return this.http.post<{
      message: string;
      projects: { id: string; image: string; item: string }[];
    }>(`${BACKENDURL}/dsm/projects/user`, { userid });
  }

  deleteDSM(id) {
    return this.http.delete<{ message: string }>(`${BACKENDURL}/dsm/${id}`);
  }

  // Model Config
  createModelConfigProject(modelConfig: modelConfig) {
    return this.http.post<{ message: string; image: string; id: string }>(
      BACKENDURL + "/modelconfig/projects",
      modelConfig
    );
  }

  getModelConfigProjects(siteid: string) {
    return this.http.get<
      Array<{
        id: string;
        image: string;
        item: string;
        siteid: string;
        creatorid: string;
      }>
    >(BACKENDURL + "/modelconfig/projects/" + siteid);
  }

  fetchModelConfigProject(id: string) {
    return this.http.get<{ message: string; project: modelConfig }>(
      BACKENDURL + "/modelconfig/projects/single/" + id
    );
  }

  updateModelConfigProjects(modelconfig: modelConfig) {
    return this.http.put<{ message: string; image: string }>(
      BACKENDURL + "/modelconfig/projects",
      modelconfig
    );
  }

  shareModelConfigProject(data: {
    id: string;
    userid: string;
    accessControl: "canEdit" | "canView";
    message: string;
  }) {
    return this.http.post<{ message: string }>(
      `${BACKENDURL}/modelconfig/projects/share`,
      data
    );
  }

  unShareModelConfigProject(data: {
    id: string;
    userid: string;
    accessControl: "canEdit" | "canView";
  }) {
    return this.http.post<{ message: string }>(
      `${BACKENDURL}/modelconfig/projects/unshare`,
      data
    );
  }

  getModelConfigfromUserId(userid: string) {
    return this.http.post<{
      message: string;
      projects: { id: string; item: string; image: string }[];
    }>(`${BACKENDURL}/modelconfig/projects/user`, { userid });
  }

  deleteModelConfig(id) {
    return this.http.delete<{ message: string }>(
      `${BACKENDURL}/modelconfig/${id}`
    );
  }

  uploadTexture(data: FormData) {
    return this.http.post<{ message: string; imgurl: string }>(
      `${BACKENDURL}/modelconfig/projects/texture`,
      data
    );
  }

  deleteTexture(id: string, key: string) {
    return this.http.delete<{ message: string }>(
      `${BACKENDURL}/modelconfig/projects/texture/${id}?key=${key}`
    );
  }

  // PAN
  createPANProject(pan: Pan) {
    return this.http.post<{ message: string; id: string; image: string }>(
      BACKENDURL + "/pan/projects",
      pan
    );
  }

  getPANProjects(siteid: string) {
    return this.http.get<Array<Pan>>(BACKENDURL + "/pan/projects/" + siteid);
  }

  getPanProject(id) {
    return this.http.get<{ project: Pan; image: string; message: string }>(
      BACKENDURL + "/pan/project/" + id
    );
  }

  updatePANProjects(pan: Pan) {
    return this.http.put<{ message: string; image: string }>(
      BACKENDURL + "/pan/projects",
      pan
    );
  }

  deletePAN(id) {
    return this.http.delete<{ message: string }>(`${BACKENDURL}/pan/${id}`);
  }

  // Walk PAN
  createWalkPanProject(walkPan: WalkPan) {
    return this.http.post<{ message: string; image: string; id }>(
      `${BACKENDURL}/walkpan/projects`,
      walkPan
    );
  }

  getWalkPanProjects(siteid) {
    return this.http.get<{
      message: string;
      projects: WalkPan[];
      image: string;
    }>(`${BACKENDURL}/walkpan/projects/${siteid}`);
  }

  getWalkPanProject(id) {
    return this.http.get<{ message: string; project: WalkPan; image: string }>(
      `${BACKENDURL}/walkpan/project/${id}`
    );
  }

  deleteWalkPan(id) {
    return this.http.delete<{ message: string }>(`${BACKENDURL}/walkpan/${id}`);
  }

  // Processing Project
  createProcProject(procProject: ProcProject) {
    return this.http.post<{ id: string; message: string }>(
      `${BACKENDURL}/procproject`,
      procProject
    );
  }

  getProcProjects(siteid: string) {
    return this.http.get<ProcProject[]>(`${BACKENDURL}/procproject/${siteid}`);
  }

  getProcProject(id: string) {
    return this.http.get<ProcProject>(`${BACKENDURL}/procproject/single/${id}`);
  }

  updateProcProject(procProject: ProcProject) {
    return this.http.put<{ message: string }>(
      `${BACKENDURL}/procproject/update`,
      procProject
    );
  }

  deleteProcProject(id: string) {
    return this.http.delete<{ message: string }>(
      `${BACKENDURL}/procproject/${id}`
    );
  }

  // OTHERs

  fetchAccessKey() {
    return this.http.get<{ key: string }>(`${BACKENDURL}/s3handler`);
  }

  emailUserDetail(user: User) {
    return this.http.post(`${BACKENDURL}/users/approach`, user);
  }

  getDemoIds() {
    return this.http.get<{ ids: string[] }>(`${BACKENDURL}/users/demoIds`);
  }

  deleteSite(id) {
    return this.http.delete<{ message: string }>(`${BACKENDURL}/sites/${id}`);
  }

  addGisAttribute(data) {
    return this.http.post<{ message: string }>(
      BACKENDURL + "/gis/attribute",
      data
    );
  }

  // MAP-AI

  getAiMapProjects(siteid: string) {
    return this.http.get<Array<{ id: string; item: string; image: string }>>(
      BACKENDURL + "/aimap/projects/" + siteid
    );
  }

  createAiMapProject(projectData: aiprojectData) {
    return this.http.post<{ message: string; id: string }>(
      BACKENDURL + "/aimap",
      projectData
    );
  }

  deleteAiMapProject(id: string) {
    return this.http.delete<{ message: string }>(`${BACKENDURL}/aimap/${id}`);
  }

  getAiMapProject(id: string) {
    return this.http.get<{ message: string; projectData: aiprojectData }>(
      BACKENDURL + "/aimap/" + id
    );
  }

  getAiMapProjectReport(id: string) {
    return this.http.get<{ message: string; projectData: aiprojectData }>(
      BACKENDURL + "/aimap/public/" + id
    );
  }

  updateAiMapProject(projectData: aiprojectData, id: string) {
    return this.http.put<{ message: string }>(
      BACKENDURL + "/aimap/" + id,
      projectData
    );
  }

  addAttribute(data) {
    return this.http.post<{ message: string }>(
      BACKENDURL + "/aimap/attribute",
      data
    );
  }

  getStandardData() {
    return this.http.get<{ standardData: StandardData }>(
      BACKENDURL + "/mapdata/"
    );
  }

  shareAIProject(data: {
    id: string;
    userid: string;
    accessControl: "canEdit" | "canView";
    message: string;
  }) {
    return this.http.post<{ message: string }>(
      `${BACKENDURL}/aimap/projects/share`,
      data
    );
  }

  unShareAIProject(data: {
    id: string;
    userid: string;
    accessControl: "canEdit" | "canView";
  }) {
    return this.http.post<{ message: string }>(
      `${BACKENDURL}/aimap/projects/unshare`,
      data
    );
  }

  saveReport(data) {
    console.log(data);
    return this.http.post<{ name: String; id: String; active: boolean }>(
      `${BACKENDURL}/aimap/report`,
      data
    );
  }

  // saveReportLayer(data: {projectId:String,name:String,data:FeatureCollection}){
  //   return this.http.post<any>(`${BACKENDURL}/aimap/creatreportlayer`, data);
  // }

  updateReport(data) {
    return this.http.post<{ name: String; id: String; active: boolean }>(
      `${BACKENDURL}/aimap/report/update`,
      data
    );
  }

  deleteReport(id) {
    return this.http.delete<{ name: String; id: String }>(
      `${BACKENDURL}/aimap/report/` + id
    );
  }

  sendPublicReport(data) {
    console.log(data);

    return this.http.post<{ message: string }>(
      `${BACKENDURL}/aimap/sendreport`,
      data
    );
  }

  getReportData(id) {
    return this.http.get<any>(`${BACKENDURL}/report/` + id);
  }

  // uploadBulkData(file: File){
  //   let data = new FormData()
  //   data.append('file', file)
  //   return this.http.post<any>(`${BACKENDURL}/aimap/bulkdata/`, data);
  // }

  uploadbulkData(data) {
    return this.http.post<any>(
      `https://ai.heliware.co.in/postgre/find_cols/`,
      data
    );
  }

  // updatebulkData(data){
  //   return this.http.post<any>(`http://143.110.180.66/csv_excel_to_point/`, data);
  // }

  updatebulkData(data) {
    return this.http.post<any>(
      `https://ai.heliware.co.in/postgre/xlsx_csv_upload/`,
      data
    );
  }

  

  savePortalDetection(data){
    const formData = new FormData();
    formData.append("pothole_input", data.file[0]);
    
    return this.http.post<any>(
      `https://ai.heliware.co.in/api/pothole_detection/`,
      formData
    )
  }

  saveStreetLightDetection(data){
    const formData = new FormData();
    formData.append("street_light_input", data.file[0]);
    
    return this.http.post<any>(
      `https://ai.heliware.co.in/api/street_light_detection/`,
      formData
    )
  }

 

  getApi(id) {
    return this.http.get<any>(BACKENDURL + "/apikey/" + id).toPromise();
  }

  getIsochrone(req) {
    const params = new HttpParams()
      .set("apikey", environment.heligeo_key)
      .set("lat", req.lat)
      .set("long", req.long)
      .set("transport-mode", req["transport-mode"]);
    return this.http.get<any>(`https://ai.heliware.co.in/postgre/ors_iso/`, {
      params,
    });
  }

  // PTS
  canUploadPTS() {
    return this.http
      .get<{ EMPTY: "TRUE" | "FALSE" }>(
        `${AIBACKENDURLOLD}/check_folder_status/`
      )
      .toPromise();
  }

  isPTSProcessing(id: string) {
    return this.http
      .get<{ processing: boolean }>(
        `${AIBACKENDURLOLD}/check_process_status?id=${id}`
      )
      .toPromise();
  }


  ////videomultiplemail
  videoUploadNotification(data: {id: string, userids: string[], accessControl: 'canEdit'| 'canView', type: 'GIS'}){
    return this.http.post<{message: string}>(`${BACKENDURL}/gis/videonotification`, data);
  }


  //Layer video
  addLayerVideoData(gisId:string, data:any){
    return this.http.post(BACKENDURL+`/gis/trackingvideo/${gisId}`, data)
  }

  removeLayerVideoData(gisId:string, trackId:string){
    return this.http.delete(`${BACKENDURL}/gis/trackingvideo/${gisId}/${trackId}`);
  }
  
  updateLayerVideoData(trackId:string,data:any){
    return this.http.patch(`${BACKENDURL}/gis/trackingvideo/${trackId}`,data);
  }

  getAllUserLayers(){
    return this.http.get(`${BACKENDURL}/gis/getSubLayer/admin`).toPromise();
  }

  //  FOR MCG IDS
  createMCGUser(data:any){
    return this.http.post(`${BACKENDURL}/users/create-mcg-user`, data)
  }

  getMCGUser(data){
    return this.http.post(`${BACKENDURL}/users/get-mcg-user`, data)
  }

  updateRole(data){
    console.log(data,"data aa gya ====>")
    return this.http.post(`${BACKENDURL}/users/update-mcg-role`,data)
  }

  getUserDashboardData(){
    return this.http.post(`${BACKENDURL}/users/user-data-dashboard`, {})
  }

  getProjectDashboardData(data:any){
    return this.http.post(`${BACKENDURL}/gis/projects-dashboard`, data)
  }

  deleteMCGUser(data){
    return this.http.post(`${BACKENDURL}/users/delete-mcg-user`, data)
  }

  getMCGUserById(id){
    return this.http.post(`${BACKENDURL}/users/get-mcg-user-byid`, {id : id})
  }

  getAssignedFetchSites(data){
    // return this.http.post(`${BACKENDURL}/users/get-mcg-user-byid`, {id : id})
    return this.http.post(`${BACKENDURL}/users/get-assigned-sites`, data)
  }

  updateMCGUser(data){
    return this.http.post(`${BACKENDURL}/users/update-mcg-user`, data)
  }
  assignUser(data){
    return this.http.post(`${BACKENDURL}/users/assign-project-mcg-user`, data)
  }
  assignMcgProjectToAdmin(projectId){
    return this.http.post(`${BACKENDURL}/users/assign-mcg-project-to-admin`, {projectId})
  }

  ///////////// panaroma tagging /////////////

  addPanTag(gisId:string, data:any){
    return this.http.post(`${BACKENDURL}/gis/addPanTag/${gisId}`,data);
  }

  deletePanTag(gisId:string, tagDataId:string){
    return this.http.delete(`${BACKENDURL}/gis/deletePanTag/${gisId}/${tagDataId}`);
  }

  ///////////// end of panaroma tagging /////////////
  /// get ALL mcg projects ///
  getAllMcgProjects(){
    return this.http.get(`${BACKENDURL}/gis/getAllMcgProjects/admin`,{}).toPromise()
  }

    saveWMSLayerServer(data:any) {
      return this.http.post<projectData>(BACKENDURL + "/gis/create-wms-layer", data);
    }

  savePointCloudLayerServer(data:any) {
    return this.http.post<projectData>(BACKENDURL + "/gis/create-point-cloud-layer", data);
  }

  addPotHoleDetectionData(data){
    return this.http.post<projectData>(BACKENDURL + "/gis/create-pot-hole-detection", data);
  }

  addStreetLightDetectionData(data){
    return this.http.post<projectData>(BACKENDURL + "/gis/create-street-light-detection", data);
  }

  ////////get All projhects detection dashboard /////////
  getAllProjects(reqObj:any){
    return this.http.post(`${BACKENDURL}/dashboard/mcgDashboard`,reqObj)
  }

  addItemExcelData(data){
    return this.http.post(`${BACKENDURL}/dashboard/add-item`,data);
  }

  deleteAllItem(id:any) {
    console.log(id,"localStorage.deleteItem('id') =================>>>");
    return this.http.post(`${BACKENDURL}/dashboard/delete-item`, {id : id});
  }

  getAllItem(){
    return this.http.post(`${BACKENDURL}/dashboard/get-item`,{createdId: localStorage.getItem('id')});
  }

  getExcelItem(){
    return this.http.post(`${BACKENDURL}/dashboard/get-table-data`,{createdId: localStorage.getItem('id')});
  }

  getSelectedItem(data){
    const url = `${BACKENDURL}/dashboard/get-selected-item?projectId=${data.projectId}&siteId=${data.siteId}`;

    return this.http.get(url);
  }
  getSingleSite(id){
    return this.http.get(`${BACKENDURL}/sites/single/${id}`);
  }

  getKmlUploadedUrl(data){
    console.log(data);
    return this.http.post(`${BACKENDURL}/gis/geojson`,data);
  }

  getWMSLayer(data){
    return  this.http.post(`${BACKENDURL}/gis/editWMSlayer`,data);
  }
  getDeleteWms(data){
    return  this.http.post(`${BACKENDURL}/gis/deletewmsLayer`,data);
  }
  
  getEditItem(data){
    return  this.http.post(`${BACKENDURL}/gis/editPointCloud`,data);
  }
  getdeleteItem(data){
    return  this.http.post(`${BACKENDURL}/gis/deletePointCloud`,data);
  }

  getPanItem(data){
    return  this.http.post(`${BACKENDURL}/gis/editPanImage`,data);
  }
  getPanName(data){
    return  this.http.post(`${BACKENDURL}/gis/deletePan`,data);
  }

  getEditedImg(data){
    return this.http.post(`${BACKENDURL}/gis/editGroundImg`,data);
  }

  getGroundName(data){
    return this.http.post(`${BACKENDURL}/gis/deleteground-image`,data);
  }

  getDroneName(data){
    return  this.http.post(`${BACKENDURL}/gis/editDrone`,data);
  }

  userComments(data:any) {
    return  this.http.post(`${BACKENDURL}/dashboard/user-comments`, data);
  }

  updateUserComments(data:any) {
    return  this.http.put(`${BACKENDURL}/dashboard/user-comments`, data);
  }

  getUserComments(id) {
    return  this.http.get(`${BACKENDURL}/dashboard/user-comments/${id}`);
  }

  deleteUserComments(id: string) {
    return this.http.delete(`${BACKENDURL}/dashboard/user-comments/${id}`);
  }

  getallContractor(data) {
    return this.http.post(`${BACKENDURL}/contractor/contractuser`,data);
  }

  editContract(data){
    return  this.http.post(`${BACKENDURL}/contractor/editcontractor`,data);
  }

  deleteContractor(data: any){
    return  this.http.post(`${BACKENDURL}/contractor/deleteContract`,data);
  }

  contractorUser(data:any){
  return this.http.post(`${BACKENDURL}/contractor/admin`,data);
  }

  savePortalGeoTiff(data:any){
    console.log(data,'data----====')
    return this.http.post(`${BACKENDURL}/gis/portalTiff`,data);
  }

  // getMcgProjects(){
  //   // console.log(data,'data----====')
  //   return this.http.get(`${BACKENDURL}/gis/mcg-projects`);
  // }

  fetchUserEmail(userid){
    return this.http.get(`${BACKENDURL}/gis/getUsers/${userid}`);
  }

  getMcgProjects(id:any){
    return this.http.get(`${BACKENDURL}/gis/mcg-projects/${id}`);
  }

  getMcgAllProjects(){
    // console.log(data,'data----====')
    return this.http.get(`${BACKENDURL}/gis/mcg-projects`);
  }

  addExcelColumnData(data){
    const formData:any = new FormData();
    formData.append("tablename", data.tablename);
    formData.append("columns_info", JSON.stringify(data.columns));

    return this.http.post<any>(
      `https://ai.heliware.co.in/postgre/create_table_xlsx/`,
      formData
    )
  }


  getExcelColumnData(formData){
    return this.http.post<any>(
      `https://ai.heliware.co.in/postgre/fetch_data_xlsx/`,
      formData
    )
  }

  addExcelRowData(formData){
    return this.http.post<any>(
      `https://ai.heliware.co.in/postgre/insert_data_xlsx/`,
      formData
    )
  }


  addExcelData(data){
    return this.http.post(`${BACKENDURL}/dashboard/table-data`,data);
  }


  
  addChangeDetection(data){
    return this.http.post(`${BACKENDURL}/gis/change-detection`,data);
  }
  
  saveGeoTiff(data){
    return this.http.post(`${BACKENDURL}/gis/create-geo-tif-layer`,data);
  }

  getAssignedProject(data){
    return this.http.post(`${BACKENDURL}/users/assigned-project`,data);
  }

  categoryUrl(data){
    console.log(data,"data===>")
    return this.http.post(`${BACKENDURL}/gis/categoryIcon`,data);
  }

  

  // updatePdfCount(data){
  //   console.log(data,'serverr')
  //   return this.http.put(`${BACKENDURL}/gis/updatepdfcount`,data);
  // }
}