<h1 mat-dialog-title>Create Tag</h1>
<form mat-dialog-content [formGroup]="tagForm">
  <br>
  <mat-form-field style="display: block">
    <input formControlName="name" placeholder="Name" matInput>
    <mat-hint  style="align-items:end">Max 7 characters</mat-hint>
  </mat-form-field>
  <mat-form-field style="display: block">
    <textarea formControlName="content" rows="5" cols="10" placeholder="Tag Description" matInput></textarea>
  </mat-form-field>
  <div mat-dialog-actions>
    <button mat-button cdkFocusInitial (click)="onCancel()">Cancel</button>
    <button mat-button [disabled]="tagForm.invalid && !canSubmit" [mat-dialog-close]="data">Submit</button>
  </div>
</form>