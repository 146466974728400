import { Component, OnInit, Input, HostBinding } from '@angular/core';

@Component({
  selector: 'app-panhover-tagging',
  templateUrl: './panhover-tagging.component.html',
  styleUrls: ['./panhover-tagging.component.scss']
})
export class PanhoverTaggingComponent implements OnInit {
  @Input() data: {
    event:{
      top: string,
      left: string
    },
    display?: string,
    content: string
    x:number, 
    y:number,
    z:number
  }
  style = {
    position: 'absolute'
  }
  constructor() { }

  ngOnInit() { }

  @HostBinding('style.position') get position(){
    return 'absolute'
  }

  @HostBinding('style.zIndex') get zIndex(){
    return '10000'
  }

  @HostBinding('style.top') get styleTop(){
    if(this.data){
      let {event} = this.data;
      return event.top
    }
  }

  @HostBinding('style.left') get styleLeft(){
    if(this.data){
      let {event} = this.data;
      return event.left
    }
  }

  @HostBinding('style.display') get display(){
    if(this.data){
      return this.data.display
    }
  }
}