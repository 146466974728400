import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';
const BACKENDURL = environment.apiURL;

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  noOfNotification: Subject<number> = new Subject();

  constructor(private http: HttpClient) { }

  getNotifications(recipientid: string){
    return this.http.get<{message: string, notifications: Array<Notification>}>(`${BACKENDURL}/notification/recipient/${recipientid}`);
  } 

  deleteNotification(id: string){
    return this.http.delete<{message: string}>(`${BACKENDURL}/notification/recipient/${id}`)
  }
}
